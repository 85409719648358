import React, { Fragment, useContext, useState } from "react";
import {
  FormDataFeild,
  FormDataFeildTextArea,
} from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import {
  IsLoadingResponse,
  ToastMessage,
  randomStr,
  removeEmptyKeys,
} from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Submit,
  TYPE_FELDS,
  FileType,
  CreateFrameHeader,
  UpdateFrameHeader,
  TITLE,
  HTML,
  POSTS_BACKGROUND,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createFrameHeaderSchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import FrameHeaderContext from "../../../../context/FrameHeaderContext/FrameHeaderContext";
import {
  createFrameHeader,
  putUpdateFrameHeader,
} from "../../../../Apis/frameHeaderApi/FrameHeaderApi";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { ColorPicker } from "primereact/colorpicker";

const FrameHeaderCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setDialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(FrameHeaderContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);

  /**
   *
   * @param {event } e
   * create Frame Header & update Frame Header
   */

  const { mutate: createFrameHeader_, isLoading: createFrameHeaderLoader } =
    useMutation((formData) => createFrameHeader(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const { mutate: updateFrameHeader, isLoading: updateFrameHeaderLoader } =
    useMutation((formData) => putUpdateFrameHeader(formData, orgId), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const handleFrameHeaderCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createFrameHeaderSchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
          type: formData?.type,
        };
        if (formData.colorObj.length > 0) {
          const newColorArray = formData.colorObj.map((ele) => {
            if (ele?.color?.startsWith("#")) {
              return {
                ...ele,
              };
            } else {
              return {
                ...ele,
                color: `#${ele.color}`,
              };
            }
          });
          value.colorObj = newColorArray;
        } else {
          delete value?.colorObj;
        }

        value = removeEmptyKeys(value);
        const { image, ...updatedFormData } = value;
        updateFrameHeader(updatedFormData);
      } else {
        let value = {
          ...formData,
          type: formData?.type,
        };
        value = removeEmptyKeys(value);
        const { image, ...updatedFormData } = value;
        createFrameHeader_(updatedFormData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage, isLoading: imageLoading } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.SYSTEM_IMAGE)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeFrameHeader = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleColorChange = (e, i) => {
    const { name, value } = e.target;
    let old = [...formData.colorObj];
    old[i][name] = value;
    setFormData((prev) => {
      return {
        ...prev,
        colorObj: old,
      };
    });
  };
  const addMoreInput = () => {
    setFormData((prev) => {
      return {
        ...prev,
        colorObj: [
          ...prev.colorObj,
          { title: "", value: "", id: randomStr(2) },
        ],
      };
    });
  };
  const deleteMoreInput = (id) => {
    setFormData((prev) => {
      return {
        ...prev,
        colorObj: prev.colorObj.filter((item) => item.id !== id),
      };
    });
  };
  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateFrameHeader : UpdateFrameHeader}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={
            createFrameHeaderLoader || updateFrameHeaderLoader || imageLoading
          }
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleFrameHeaderCreateAndUpdate(e)}
                >
                  <Row>
                    {/* {formData.frameHtmlOrFrame === FRAME_HEADER_TYPE.FRAME ? (
                      <>
                        <div className="field mb-4 col-12 md:col-12">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              marginBottom: "15px",
                            }}
                          >
                            <div
                              style={{
                                height: "150px",
                                width: "185px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={
                                  formData?.image
                                    ? formData.image
                                    : NOT_FOUND_IMAGE
                                }
                                alt=""
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              // display: "flex",
                              // justifyContent: "center",
                              textAlign: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <p>{"Upload Frame"}</p>
                            {!formData?.image && (
                              <input
                                className="custom-file-input"
                                type="file"
                                onChange={(e) => openImageUploadModal(e)}
                              />
                            )}

                            <Button
                              className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                              style={{ height: "40px" }}
                              type="button"
                              onClick={() => {
                                if (formData.image) {
                                  SweetConfirmation(accept, "image");
                                }
                              }}
                            >
                              <span
                                className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                              ></span>
                              <span
                                className="p-button-label p-c"
                                style={{ fontWeight: "100" }}
                              >
                                {"Choose"}
                              </span>
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )} */}

                    <Col md="12 mb-4 d-flex">
                      <Col md="1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="html"
                          value={POSTS_BACKGROUND.TYPE.HTML}
                          checked={
                            +formData.type === POSTS_BACKGROUND.TYPE.HTML
                          }
                          onChange={handleChangeFrameHeader}
                        />
                        <label className="form-check-label" htmlFor="html">
                          HTML 2
                        </label>
                      </Col>
                      <Col md="1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="frame"
                          value={POSTS_BACKGROUND.TYPE.FRAME}
                          checked={
                            +formData.type === POSTS_BACKGROUND.TYPE.FRAME
                          }
                          onChange={handleChangeFrameHeader}
                        />
                        <label className="form-check-label" htmlFor="frame">
                          Frame
                        </label>
                      </Col>
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={TITLE}
                        name="title"
                        id="title"
                        type={TYPE_FELDS.text}
                        placeholder={TITLE}
                        value={formData.title}
                        handleChange={handleChangeFrameHeader}
                        isShowValidate={errors.title}
                      />
                      {errors.title && (
                        <span className="error-validation">{errors.title}</span>
                      )}
                    </Col>
                    {formData?.colorObj.length > 0 ? (
                      formData?.colorObj.map((ele, i) => {
                        return (
                          <Row>
                            <Col md="5 mb-4">
                              <div className="flex flex-column gap-2 padding">
                                <label
                                  htmlFor="username"
                                  className="font-medium text-900"
                                  style={{ fontSize: "14px" }}
                                >
                                  {"Color Title"}
                                </label>
                                <InputText
                                  name="title"
                                  id="title"
                                  type={TYPE_FELDS.text}
                                  placeholder={TITLE}
                                  value={ele.title}
                                  className={`p-inputtext-sm `}
                                  onChange={(e) => handleColorChange(e, i)}
                                  aria-describedby="username-help"
                                  autoComplete="off"
                                />
                              </div>
                            </Col>
                            <Col md="5 mb-4">
                              <div className="flex flex-column gap-2 padding">
                                <label
                                  htmlFor="username"
                                  className="font-medium text-900"
                                  style={{ fontSize: "14px" }}
                                >
                                  {"Color Name"}
                                </label>

                                <div className="d-flex gap-2 align-items-center">
                                  <ColorPicker
                                    name="color"
                                    value={ele.color}
                                    onChange={(e) => handleColorChange(e, i)}
                                    format="hex"
                                  />
                                  <InputText
                                    name="color"
                                    id="color"
                                    type={TYPE_FELDS.text}
                                    placeholder={"Color"}
                                    value={ele.color}
                                    className={`p-inputtext-sm w-100`}
                                    onChange={(e) => handleColorChange(e, i)}
                                    aria-describedby="username-help"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </Col>
                            {formData.colorObj.length >= 1 && (
                              <Col md="2 mb-4">
                                <Button
                                  className="mt-4"
                                  icon="pi pi-times"
                                  severity="danger"
                                  aria-label="Cancel"
                                  type={TYPE_FELDS.button}
                                  onClick={() => deleteMoreInput(ele.id)}
                                />
                              </Col>
                            )}
                          </Row>
                        );
                      })
                    ) : (
                      <p>Add Color Name and Title</p>
                    )}
                    <Row>
                      {" "}
                      <Col md="2 mb-4">
                        <Button
                          label="+ Add More"
                          severity="info"
                          onClick={() => addMoreInput()}
                          type={TYPE_FELDS.button}
                        />
                      </Col>
                    </Row>

                    {/* <Col md="5 mb-4">
                      <FormDataFeild
                        label={TYPE_}
                        name="type"
                        id="type"
                        placeholder={TYPE_}
                        value={formData.type}
                        handleChange={handleChangeFrameHeader}
                        dropdown={true}
                        filter={false}
                        option={POST_TYPE}
                        isShowValidate={errors.type}
                      />
                      {errors.type && (
                        <span className="error-validation">{errors.type}</span>
                      )}
                    </Col>

                    <Col md="1 mb-4">
                      <div className="flex flex-column gap-2 padding w-100 ">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {"Default"}
                        </label>
                        <InputSwitch
                          checked={formData.isDefault}
                          onChange={(e) => handleChangeFrameHeader(e)}
                          name="isDefault"
                          id="isDefault"
                        />
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Company}
                        name="companyId"
                        id="companyId"
                        placeholder={Company}
                        value={formData.companyId}
                        handleChange={handleChangeFrameHeader}
                        dropdown={true}
                        showClear={true}
                        option={
                          companyDrop?.payload?.data?.length
                            ? companyDrop?.payload?.data.map((elem) => {
                                return {
                                  code: elem.companyId,
                                  name: elem.name,
                                };
                              })
                            : []
                        }
                        isShowValidate={errors.companyId}
                        search={search}
                      />
                      {errors.companyId && (
                        <span className="error-validation">
                          {errors.companyId}
                        </span>
                      )}
                    </Col> */}
                    {/* <Col md="6 mb-4">
                          <FormDataFeild
                            label={`${HTML_TYPE_}`}
                            name="htmlType"
                            id="htmlType"
                            placeholder={`${HTML_TYPE_}`}
                            value={formData.htmlType}
                            handleChange={handleChangeFrameHeader}
                            dropdown={true}
                            filter={false}
                            option={HTML_TYPE}
                            isValid={false}
                          />
                        </Col> */}
                    <Col md="12 mb-4">
                      <FormDataFeildTextArea
                        label={HTML}
                        name="html"
                        id="html"
                        type={TYPE_FELDS.text}
                        placeholder={HTML}
                        value={formData.html}
                        handleChange={handleChangeFrameHeader}
                        isShowValidate={errors.html}
                      />
                      {errors.html && (
                        <span className="error-validation">{errors.html}</span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={
                          createFrameHeaderLoader || updateFrameHeaderLoader
                        }
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default FrameHeaderCreateFormPopup;
