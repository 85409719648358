import axios from "axios";
import {
  CREATE_FRAME_HEADER,
  DELETE_FRAME_HEADER,
  EDIT_FRAME_HEADER,
  ENABLE_DISABLE_FRAME_HEADER,
  GET_FRAME_HEADER,
  GET_FRAME_HEADER_DETAILS,
} from "../../constants/ApiConstant";
import { randomStr, ToastMessage } from "../../utils/Helper";

/**
 * get Frame Header list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const getFrameHeader = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_FRAME_HEADER}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add Frame Header api call
 * @param {form data} formData
 * @returns
 */

export const createFrameHeader = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_FRAME_HEADER}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable Frame Header api call
 * @param {form data as isActive & frameHeaderId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const enableDisableFrameHeader = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_FRAME_HEADER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { frameHeaderId: formData.frameHeaderId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const getDetailsFrameHeader = (
  frameHeaderId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_FRAME_HEADER_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { frameHeaderId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      setFormData({
        title: data?.title ?? null,
        html: data?.html ?? null,
        type: data?.type ?? null,
        colorObj: data?.colorObj.length > 0 ? data?.colorObj : [],
      });
      setdialogs(true);
    });
};

/**
 * update Frame Header api call
 * @param {form data} formData
 * @param {*} frameHeaderId
 * @returns
 */

export const putUpdateFrameHeader = (formData, frameHeaderId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_FRAME_HEADER}`, formData, {
      headers: { Authorization: jwt_token },
      params: { frameHeaderId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete Frame Header api call
 * @param {form data} formData
 * @param {*} frameHeaderId
 * @param {list api call } refetch
 * @returns
 */

export const deleteFrameHeader = (frameHeaderId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_FRAME_HEADER}`, {
      headers: { Authorization: jwt_token },
      params: { frameHeaderId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
