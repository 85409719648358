import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  POST_BACKGROUND,
  POST_BACKGROUND_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import PostBackgroundDataTableContainer from "./PostBackgroundDataTableContainer";
import PostBackgroundCreateFormPopup from "./PostBackgroundCreateFormPopup";
import PostBackgroundContext from "../../../../context/PostBackgroundContext/PostBackgroundContext";
import { getPostBackground } from "../../../../Apis/postBackgroundApi/PostBackgroundApi";

const PostBackground = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //Post Background edit condition state and data object state
    setDialogs,
    setErrors,
    dialog,
    setIsEdit,
    setFormData,
    setFilterDrop,
  } = useContext(PostBackgroundContext);

  const history = useNavigate();

  /**
   * get Post Background List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { catName: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getPostBackground", paramsObj],
    () => getPostBackground(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    }
  );
  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const emptyData = () => {
    setFormData({
      image: "",
      thumbImage: "",
      color: "000000",
      name: null,
    });
    setFilterDrop((prev) => {
      return { ...prev, storeFilter: "" };
    });
  };

  /**
   * Open Popup and Close Popup category
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setDialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setDialogs(condition);
      emptyData();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb
        name={POST_BACKGROUND}
        slace={SLACE}
        name1={POST_BACKGROUND_LIST}
      />
      <PostBackgroundDataTableContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      {dialog && (
        <PostBackgroundCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
          data={data}
        />
      )}
    </>
  );
};

export default PostBackground;
