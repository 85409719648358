import React, { Fragment, useContext, useMemo, useState } from "react";
import {
  FormDataFeild,
  FormDataFeildTextArea,
} from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { DELETE_IMAGE, Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import {
  IsLoadingResponse,
  ToastMessage,
  handleInfiniteScroll,
} from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Email,
  Phone,
  Submit,
  TYPE_FELDS,
  FileType,
  Address,
  NOT_FOUND_IMAGE,
  ComponyName,
  CreateThemeSetting,
  UpdateThemeSetting,
  Name,
  PersonName,
  website,
  Designation,
  FontName,
  DegreeOrSpecialty,
  FooterDegreeOrSpecialty,
  FooterDesignation,
  FooterFontSize,
  HeaderFontSize,
  ShortDescription,
  DROPDOWN_CLASS,
  THEME_SETTING_TYPE,
  POSTS_BACKGROUND,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import CropImagePopup from "../../../CropImagePopup";
import { ColorPicker } from "primereact/colorpicker";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import {
  createThemeSetting,
  putUpdateThemeSetting,
} from "../../../../Apis/themeSettingApi/ThemeSettingApi";
import ThemeSettingContext from "../../../../context/ThemeSettingContext/ThemeSettingContext";
import { get_Company } from "../../../../Apis/companyApi/CompanyApi";
import { getFrameHeader } from "../../../../Apis/frameHeaderApi/FrameHeaderApi";
import { InputText } from "primereact/inputtext";
// import { log } from "fabric/fabric-impl";

const ThemeSettingCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setDialogs,
    formData,
    dialog,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(ThemeSettingContext);
  const { handleOpenandClosePopup, refetch, emptyData, companyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);
  const [companyDrop, setCompanyDrop] = useState({
    data: [],
    totalRecords: "",
    pageNumber: 1,
    nameFilter: "",
  });

  const [frameDrop, setFrameDrop] = useState({
    data: [],
    totalRecords: "",
    pageNumber: 1,
    nameFilter: "",
  });

  const [htmlDrop, setHtmlDrop] = useState({
    data: [],
    totalRecords: "",
    pageNumber: 1,
    nameFilter: "",
    colorObj: [],
  });

  /**
   *
   * @param {event } e
   * create theme setting & update theme setting
   */

  const { mutate: createThemeSEtting, isLoading: createThemeSettingLoader } =
    useMutation((formData) => createThemeSetting(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const { mutate: updateThemeSetting, isLoading: updateThemeSettingLoader } =
    useMutation((formData) => putUpdateThemeSetting(formData, orgId), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const handleThemeSettingCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
          image: formData?.image
            ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          footerImage: formData?.footerImage
            ? formData?.footerImage
                .split(process.env.REACT_APP_FILE_FOLDER)
                .pop()
            : "",
          frameImage: formData?.frameImage
            ? formData?.frameImage
                .split(process.env.REACT_APP_FILE_FOLDER)
                .pop()
            : "",
        };
        if (formData.colorObj.length > 0) {
          const newColorArray = formData.colorObj.map((ele) => {
            if (ele?.color?.startsWith("#")) {
              return {
                ...ele,
              };
            } else {
              return {
                ...ele,
                color: `#${ele.color}`,
              };
            }
          });
          value.colorObj = newColorArray;
        }
        const { logo, type, frameImage, frameHeaderId, ...updatedFormData } =
          value;
        let newFormData = {};

        if (formData.type === THEME_SETTING_TYPE.FRAME) {
          newFormData = {
            type,
            frameImage,
            frameHeaderId,
            companyId: updatedFormData.companyId,
          };
        } else {
          newFormData = {
            type,
            frameHeaderId,
            ...updatedFormData,
          };
        }
        delete newFormData?.frameHeaderRefId;
        updateThemeSetting(newFormData);
      } else {
        let value = {
          ...formData,
          image: formData?.image
            ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          footerImage: formData?.footerImage
            ? formData?.footerImage
                .split(process.env.REACT_APP_FILE_FOLDER)
                .pop()
            : "",
          frameImage: formData?.frameImage
            ? formData?.frameImage
                .split(process.env.REACT_APP_FILE_FOLDER)
                .pop()
            : "",
        };
        if (formData?.colorObj?.length > 0) {
          const newColorArray = formData.colorObj?.map((ele) => {
            if (ele?.color?.startsWith("#")) {
              return {
                ...ele,
              };
            } else {
              return {
                ...ele,
                color: `#${ele.color}`,
              };
            }
          });
          value.colorObj = newColorArray;
        }
        const { logo, type, frameImage, frameHeaderId, ...updatedFormData } =
          value;

        let newFormData = {};

        if (formData.type === THEME_SETTING_TYPE.FRAME) {
          newFormData = {
            type,
            frameImage,
            frameHeaderId,
            companyId: updatedFormData.companyId,
          };
        } else {
          newFormData = {
            type,
            frameHeaderId,
            ...updatedFormData,
          };
        }
        createThemeSEtting(newFormData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage, isLoading: imageLoading } = useMutation(
    (file) => Upload_Image(file, setdialogsModal, null, FileType.SYSTEM_IMAGE),
    {
      onSuccess: ({
        data: {
          status,
          payload: { imageUrl, baseUrl },
        },
      }) => {
        if (status === 200) {
          setFormData((prev) => {
            if (prev.imgType === "image") {
              return {
                ...prev,
                image: baseUrl + imageUrl,
              };
            } else if (prev.imgType === "footerImage") {
              return {
                ...prev,
                footerImage: baseUrl + imageUrl,
              };
            } else if (prev.imgType === "frameImage") {
              return {
                ...prev,
                frameImage: baseUrl + imageUrl,
              };
            }
          });
        }
      },
    }
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeThemeSetting = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    if (name === "frameHeaderId") {
      const newColor = htmlDrop.colorObj.find(
        (ele) => ele.frameHeaderId === value
      );
      setFormData((prev) => {
        if (value === formData.frameHeaderRefId) {
          return {
            ...prev,
            colorObj: formData.demoColor,
          };
        } else {
          return {
            ...prev,
            colorObj: newColor?.colorObj.map((ele) => {
              return { color: ele.color, title: ele.title, id: ele.id };
            }),
          };
        }
      });
    }
    if (name === "companyId") {
      setCompanyDrop((prev) => {
        return {
          ...prev,
          data: prev.data.map((elem) => {
            return {
              ...elem,
              disabled: prev.data?.length > 0 ? elem.value === value : false,
            };
          }),
        };
      });
    }
  };

  console.log("FormData >> ", formData);

  /**
   * delete theme setting image
   */
  const { mutate: themeSettingDelete, isLoading: isLoadingDelete } =
    useMutation((imgData) => DELETE_IMAGE(imgData, refetch));

  const accept = (imgType) => {
    let imgData = {
      fileName: formData[`${imgType}`]
        .split(`${process.env.REACT_APP_FILE_FOLDER}`)
        .pop(),
    };
    setFormData((prev) => {
      return {
        ...prev,
        [`${imgType}`]: "",
      };
    });
    themeSettingDelete(imgData);
  };

  const openImageUploadModal = async (e, imgType) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      setFormData((prev) => {
        return {
          ...prev,
          [imgType]: reader.result,
          imgType,
        };
      });
    };
    await uploadImage(e.target.files[0]);
  };

  let paramObj = useMemo(() => {
    let pramObj = {
      pageNumber: companyDrop.pageNumber,
      search: { isActive: true },
    };
    if (companyDrop.nameFilter) {
      pramObj["search"]["name"] = companyDrop.nameFilter;
    }
    return {
      ...pramObj,
      search: JSON.stringify({ ...pramObj.search }),
    };
  }, [companyDrop.pageNumber, companyDrop.nameFilter]);

  useQuery(["getCompanyDrop", paramObj], () => get_Company(paramObj), {
    keepPreviousData: true,
    retry: 3,
    onSuccess: (response) => {
      if (response.status === 200) {
        setCompanyDrop((prev) => {
          return {
            ...prev,
            data: [
              ...prev.data,
              ...response.payload.data
                .filter(
                  (elem) =>
                    !prev.data.some(
                      (prevElem) => prevElem.value === elem.companyId
                    )
                )
                .map((elem) => {
                  return {
                    name: `${elem.name}`,
                    value: elem.companyId,
                    disabled:
                      companyData?.length > 0
                        ? companyData.some(
                            (dis) => dis.companyId === elem.companyId
                          )
                        : false,
                  };
                }),
            ],
            totalRecords: response.pager.totalRecords,
          };
        });
      }
    },
  });

  let paramFrameDropObj = useMemo(() => {
    let pramObj = {
      pageNumber: frameDrop.pageNumber,
      search: { isActive: true, type: POSTS_BACKGROUND.TYPE.FRAME },
    };
    if (frameDrop.nameFilter) {
      pramObj["search"]["title"] = frameDrop.nameFilter;
    }
    return {
      ...pramObj,
      search: JSON.stringify({ ...pramObj.search }),
    };
  }, [frameDrop.pageNumber, frameDrop.nameFilter]);

  useQuery(
    ["getFrameDrop", paramFrameDropObj],
    () => getFrameHeader(paramFrameDropObj),
    {
      keepPreviousData: true,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setFrameDrop((prev) => {
            return {
              ...prev,
              data: [
                ...prev.data,
                ...response.payload.data
                  .filter(
                    (elem) =>
                      !prev.data.some(
                        (prevElem) => prevElem.value === elem.frameHeaderId
                      )
                  )
                  .map((elem) => {
                    return {
                      name: `${elem.title}`,
                      value: elem.frameHeaderId,
                    };
                  }),
              ],
              totalRecords: response.pager.totalRecords,
            };
          });
        }
      },
    }
  );

  let paramHtmlDropObj = useMemo(() => {
    let pramObj = {
      pageNumber: htmlDrop.pageNumber,
      search: { isActive: true, type: POSTS_BACKGROUND.TYPE.HTML },
    };
    if (htmlDrop.nameFilter) {
      pramObj["search"]["title"] = htmlDrop.nameFilter;
    }
    return {
      ...pramObj,
      search: JSON.stringify({ ...pramObj.search }),
    };
  }, [htmlDrop.pageNumber, htmlDrop.nameFilter]);

  useQuery(
    ["getHtmlDrop", paramHtmlDropObj],
    () => getFrameHeader(paramHtmlDropObj),
    {
      keepPreviousData: true,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setHtmlDrop((prev) => {
            return {
              ...prev,
              data: [
                ...prev.data,
                ...response.payload.data
                  .filter(
                    (elem) =>
                      !prev.data.some(
                        (prevElem) => prevElem.value === elem.frameHeaderId
                      )
                  )
                  .map((elem) => {
                    return {
                      name: `${elem.title}`,
                      value: elem.frameHeaderId,
                    };
                  }),
              ],
              colorObj: [
                ...response.payload.data.map((elem) => {
                  // if (elem.colorObj.length > 0) {
                  return {
                    colorObj: elem.colorObj,
                    frameHeaderId: elem.frameHeaderId,
                  };
                  // } else {
                  //   return false;
                  // }
                }),
                // .filter((ele) => ele !== false),
              ],
              totalRecords: response.pager.totalRecords,
            };
          });
        }
      },
    }
  );

  const handleColorChange = (e, i) => {
    const { name, value } = e.target;
    let old = [...formData?.colorObj];
    old[i][name] = value;
    setFormData((prev) => {
      return {
        ...prev,
        colorObj: old,
      };
    });
  };
  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateThemeSetting : UpdateThemeSetting}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={
            createThemeSettingLoader ||
            updateThemeSettingLoader ||
            isLoadingDelete ||
            imageLoading
          }
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleThemeSettingCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="12 mb-4 d-flex">
                      <Col md="1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="html"
                          value="html"
                          checked={formData.type === THEME_SETTING_TYPE.HTML}
                          onChange={handleChangeThemeSetting}
                        />
                        <label className="form-check-label" htmlFor="html">
                          HTML
                        </label>
                      </Col>
                      <Col md="1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="frame"
                          value="frame"
                          checked={formData.type === THEME_SETTING_TYPE.FRAME}
                          onChange={handleChangeThemeSetting}
                        />
                        <label className="form-check-label" htmlFor="frame">
                          Frame
                        </label>
                      </Col>
                    </Col>
                    {formData.type === THEME_SETTING_TYPE.FRAME ? (
                      <>
                        <div className="field mb-4 col-12 md:col-12">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              marginBottom: "15px",
                            }}
                          >
                            <div
                              style={{
                                height: "150px",
                                width: "185px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={
                                  formData?.frameImage
                                    ? formData.frameImage
                                    : NOT_FOUND_IMAGE
                                }
                                alt=""
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <p>{"Upload Frame Image"}</p>
                            {!formData.frameImage && (
                              <input
                                className="custom-file-input"
                                type="file"
                                onChange={(e) =>
                                  openImageUploadModal(e, "frameImage")
                                }
                              />
                            )}

                            <Button
                              className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                              style={{ height: "40px" }}
                              type="button"
                              onClick={() => {
                                if (formData.frameImage) {
                                  SweetConfirmation(accept, "frameImage");
                                }
                              }}
                            >
                              <span
                                className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                              ></span>
                              <span
                                className="p-button-label p-c"
                                style={{ fontWeight: "100" }}
                              >
                                {"Choose"}
                              </span>
                            </Button>
                          </div>
                        </div>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={"Company"}
                            name="companyId"
                            id="companyId"
                            placeholder={"Company"}
                            value={formData.companyId}
                            handleChange={handleChangeThemeSetting}
                            dropdown={true}
                            option={companyDrop.data}
                            search={(filter) => {
                              setCompanyDrop((prev) => {
                                return {
                                  ...prev,
                                  nameFilter: filter,
                                };
                              });
                            }}
                            handleScroll={() =>
                              handleInfiniteScroll({
                                cls: DROPDOWN_CLASS.SINGLE_DROP,
                                length: companyDrop.data.length,
                                setNextPage: setCompanyDrop,
                                totalR: companyDrop.totalRecords,
                              })
                            }
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={"Frame"}
                            name="frameHeaderId"
                            id="frameHeaderId"
                            placeholder={"Frame"}
                            value={formData.frameHeaderId}
                            handleChange={handleChangeThemeSetting}
                            dropdown={true}
                            option={frameDrop.data}
                            search={(filter) => {
                              setFrameDrop((prev) => {
                                return {
                                  ...prev,
                                  nameFilter: filter,
                                };
                              });
                            }}
                            handleScroll={() =>
                              handleInfiniteScroll({
                                cls: DROPDOWN_CLASS.SINGLE_DROP,
                                length: frameDrop.data.length,
                                setNextPage: setFrameDrop,
                                totalR: frameDrop.totalRecords,
                              })
                            }
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <div className="field mb-4 col-12 md:col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              marginBottom: "15px",
                            }}
                          >
                            <div
                              style={{
                                height: "150px",
                                width: "185px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={
                                  formData?.image
                                    ? formData.image
                                    : NOT_FOUND_IMAGE
                                }
                                alt=""
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <p>{"Upload Image"}</p>
                            {!formData.image && (
                              <input
                                className="custom-file-input"
                                type="file"
                                onChange={(e) =>
                                  openImageUploadModal(e, "image")
                                }
                              />
                            )}

                            <Button
                              className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                              style={{ height: "40px" }}
                              type="button"
                              onClick={() => {
                                if (formData.image) {
                                  SweetConfirmation(accept, "image");
                                }
                              }}
                            >
                              <span
                                className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                              ></span>
                              <span
                                className="p-button-label p-c"
                                style={{ fontWeight: "100" }}
                              >
                                {"Choose"}
                              </span>
                            </Button>
                          </div>
                        </div>
                        <div className="field mb-4 col-6 md:col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              marginBottom: "15px",
                            }}
                          >
                            <div
                              style={{
                                height: "150px",
                                width: "185px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={
                                  formData?.footerImage
                                    ? formData.footerImage
                                    : NOT_FOUND_IMAGE
                                }
                                alt=""
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <p>{"Upload Footer Image"}</p>
                            {!formData.footerImage && (
                              <input
                                className="custom-file-input"
                                type="file"
                                onChange={(e) =>
                                  openImageUploadModal(e, "footerImage")
                                }
                              />
                            )}

                            <Button
                              className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                              style={{ height: "40px" }}
                              type="button"
                              onClick={() => {
                                if (formData.footerImage) {
                                  SweetConfirmation(accept, "footerImage");
                                }
                              }}
                            >
                              <span
                                className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                              ></span>
                              <span
                                className="p-button-label p-c"
                                style={{ fontWeight: "100" }}
                              >
                                {"Choose"}
                              </span>
                            </Button>
                          </div>
                        </div>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={"Company"}
                            name="companyId"
                            id="companyId"
                            placeholder={"Company"}
                            value={formData.companyId}
                            handleChange={handleChangeThemeSetting}
                            dropdown={true}
                            option={companyDrop.data}
                            search={(filter) => {
                              setCompanyDrop((prev) => {
                                return {
                                  ...prev,
                                  nameFilter: filter,
                                };
                              });
                            }}
                            handleScroll={() =>
                              handleInfiniteScroll({
                                cls: DROPDOWN_CLASS.SINGLE_DROP,
                                length: companyDrop.data.length,
                                setNextPage: setCompanyDrop,
                                totalR: companyDrop.totalRecords,
                              })
                            }
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={ComponyName}
                            name="companyName"
                            id="companyName"
                            type={TYPE_FELDS.text}
                            placeholder={ComponyName}
                            value={formData.companyName}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={"HTML"}
                            name="frameHeaderId"
                            id="frameHeaderId"
                            placeholder={"HTML"}
                            value={formData.frameHeaderId}
                            handleChange={handleChangeThemeSetting}
                            dropdown={true}
                            option={htmlDrop.data}
                            search={(filter) => {
                              setHtmlDrop((prev) => {
                                return {
                                  ...prev,
                                  nameFilter: filter,
                                };
                              });
                            }}
                            handleScroll={() =>
                              handleInfiniteScroll({
                                cls: DROPDOWN_CLASS.SINGLE_DROP,
                                length: htmlDrop.data.length,
                                setNextPage: setHtmlDrop,
                                totalR: htmlDrop.totalRecords,
                              })
                            }
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={Name}
                            name="name"
                            id="name"
                            type={TYPE_FELDS.text}
                            placeholder={Name}
                            value={formData.name}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={PersonName}
                            name="personName"
                            id="personName"
                            type={TYPE_FELDS.text}
                            placeholder={PersonName}
                            value={formData.personName}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={Email}
                            name="email"
                            id="email"
                            type={TYPE_FELDS.email}
                            placeholder={Email}
                            value={formData.email}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4" className="d-flex gap-4 ">
                          <div style={{ width: "100%" }}>
                            <FormDataFeild
                              label={`${Phone}`}
                              name="mobileNumber"
                              id="mobileNumber"
                              type={TYPE_FELDS.number}
                              placeholder={`${Phone}`}
                              value={formData.mobileNumber}
                              handleChange={handleChangeThemeSetting}
                              isValid={false}
                            />
                          </div>
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={website}
                            name="webSite"
                            id="webSite"
                            type={TYPE_FELDS.text}
                            placeholder={website}
                            value={formData.webSite}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={Designation}
                            name="designation"
                            id="designation"
                            type={TYPE_FELDS.text}
                            placeholder={Designation}
                            value={formData.designation}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={FontName}
                            name="fontName"
                            id="fontName"
                            type={TYPE_FELDS.text}
                            placeholder={FontName}
                            value={formData.fontName}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        {/* <Col md="3 mb-4" className="flex align-items-end ">
                          <Col md="2">
                            <ColorPicker
                              name="footerBgColor"
                              value={formData.footerBgColor}
                              onChange={(e) => handleChangeThemeSetting(e)}
                              format="hex"
                            />
                          </Col>
                          <Col md="10">
                            <FormDataFeild
                              label={`Footer Bg Color`}
                              name="footerBgColor"
                              id="footerBgColor"
                              type={TYPE_FELDS.text}
                              placeholder={`Footer Bg Color`}
                              value={formData.footerBgColor}
                              handleChange={handleChangeThemeSetting}
                              maxlangth={6}
                              isValid={false}
                            />
                          </Col>
                        </Col>
                        <Col md="3 mb-4" className="flex align-items-end ">
                          <Col md="2">
                            <ColorPicker
                              name="footerFontColor"
                              value={formData.footerFontColor}
                              onChange={(e) => handleChangeThemeSetting(e)}
                              format="hex"
                            />
                          </Col>
                          <Col md="10">
                            <FormDataFeild
                              label={`Footer Font Color`}
                              name="footerFontColor"
                              id="footerFontColor"
                              type={TYPE_FELDS.text}
                              placeholder={`Footer Font Color`}
                              value={formData.footerFontColor}
                              handleChange={handleChangeThemeSetting}
                              maxlangth={6}
                              isValid={false}
                            />
                          </Col>
                        </Col>
                        <Col md="3 mb-4" className="flex align-items-end ">
                          <Col md="2">
                            <ColorPicker
                              name="frameBorderColor"
                              value={formData.frameBorderColor}
                              onChange={(e) => handleChangeThemeSetting(e)}
                              format="hex"
                            />
                          </Col>
                          <Col md="10">
                            <FormDataFeild
                              label={`Footer Border Color`}
                              name="frameBorderColor"
                              id="frameBorderColor"
                              type={TYPE_FELDS.text}
                              placeholder={`Footer Border Color`}
                              value={formData.frameBorderColor}
                              handleChange={handleChangeThemeSetting}
                              maxlangth={6}
                              isValid={false}
                            />
                          </Col>
                        </Col>
                        <Col md="3 mb-4" className="flex align-items-end ">
                          <Col md="2">
                            <ColorPicker
                              name="headerBgColor"
                              value={formData.headerBgColor}
                              onChange={(e) => handleChangeThemeSetting(e)}
                              format="hex"
                            />
                          </Col>
                          <Col md="10">
                            <FormDataFeild
                              label={`Footer Bg Color`}
                              name="headerBgColor"
                              id="headerBgColor"
                              type={TYPE_FELDS.text}
                              placeholder={`Footer Bg Color`}
                              value={formData.headerBgColor}
                              handleChange={handleChangeThemeSetting}
                              maxlangth={6}
                              isValid={false}
                            />
                          </Col>
                        </Col>
                        <Col md="3 mb-4" className="flex align-items-end ">
                          <Col md="2">
                            <ColorPicker
                              name="headerFontColor"
                              value={formData.headerFontColor}
                              onChange={(e) => handleChangeThemeSetting(e)}
                              format="hex"
                            />
                          </Col>
                          <Col md="10">
                            <FormDataFeild
                              label={`Footer Font Color`}
                              name="headerFontColor"
                              id="headerFontColor"
                              type={TYPE_FELDS.text}
                              placeholder={`Footer Font Color`}
                              value={formData.headerFontColor}
                              handleChange={handleChangeThemeSetting}
                              maxlangth={6}
                              isValid={false}
                            />
                          </Col>
                        </Col> */}
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={DegreeOrSpecialty}
                            name="degreeOrSpecialty"
                            id="degreeOrSpecialty"
                            type={TYPE_FELDS.text}
                            placeholder={DegreeOrSpecialty}
                            value={formData.degreeOrSpecialty}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={FooterDegreeOrSpecialty}
                            name="footerDegreeOrSpecialty"
                            id="footerDegreeOrSpecialty"
                            type={TYPE_FELDS.text}
                            placeholder={FooterDegreeOrSpecialty}
                            value={formData.footerDegreeOrSpecialty}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={FooterDesignation}
                            name="footerDesignation"
                            id="footerDesignation"
                            type={TYPE_FELDS.text}
                            placeholder={FooterDesignation}
                            value={formData.footerDesignation}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={FooterFontSize}
                            name="footerFontSize"
                            id="footerFontSize"
                            type={TYPE_FELDS.number}
                            placeholder={FooterFontSize}
                            value={formData.footerFontSize}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={HeaderFontSize}
                            name="headerFontSize"
                            id="headerFontSize"
                            type={TYPE_FELDS.number}
                            placeholder={HeaderFontSize}
                            value={formData.headerFontSize}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeild
                            label={Address}
                            name="address"
                            id="address"
                            type={TYPE_FELDS.text}
                            placeholder={Address}
                            value={formData.address}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Col md="3 mb-4">
                          <FormDataFeildTextArea
                            label={ShortDescription}
                            name="shortDescription"
                            id="shortDescription"
                            type={TYPE_FELDS.text}
                            placeholder={ShortDescription}
                            value={formData.shortDescription}
                            handleChange={handleChangeThemeSetting}
                            isValid={false}
                          />
                        </Col>
                        <Row>
                          {formData.colorObj.length > 0 &&
                            formData.colorObj.map((ele, i) => {
                              return (
                                <Col md="4 mb-4">
                                  <div className="flex flex-column gap-2 padding">
                                    <label
                                      htmlFor="username"
                                      className="font-medium text-900"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {ele.title}
                                    </label>

                                    <div className="d-flex gap-2 align-items-center">
                                      <ColorPicker
                                        name="color"
                                        value={ele.color}
                                        onChange={(e) =>
                                          handleColorChange(e, i)
                                        }
                                        format="hex"
                                      />
                                      <InputText
                                        name="color"
                                        id="color"
                                        type={TYPE_FELDS.text}
                                        placeholder={"Color"}
                                        value={ele.color}
                                        className={`p-inputtext-sm w-100`}
                                        onChange={(e) =>
                                          handleColorChange(e, i)
                                        }
                                        aria-describedby="username-help"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={
                          createThemeSettingLoader || updateThemeSettingLoader
                        }
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default ThemeSettingCreateFormPopup;
