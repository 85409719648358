import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CompanyCreateFormPopup from "./CompanyCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  COMPANY,
  COMPANY_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import CompanyDataTableContainer from "./CompanyDataTableContainer";
import { get_Company } from "../../../../Apis/companyApi/CompanyApi";
import CompanyContext from "../../../../context/CompanyContext/CompanyContext";

const Company = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //company edit condition state and data object state
    setDialogs,
    setErrors,
    dialog,
    setIsEdit,
    setFormData,
    setFilterDrop,
  } = useContext(CompanyContext);

  const history = useNavigate();

  /**
   * get company List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { name: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getCompany", paramsObj],
    () => get_Company(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    }
  );
  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const emptyData = () => {
    setFormData({
      name: null,
      email: null,
      themeColor: "#000000",
      fontColor: "#000000",
      dialCode1: null,
      mobile1: null,
      dialCode2: null,
      mobile2: null,
      city: null,
      state: null,
      country: null,
      pincode: null,
      tagline: null,
      address: null,
      logo: null,
      roleId: null,
      companyId: null,
      orgId: null,
    });
    setFilterDrop((prev) => {
      return { ...prev, storeFilter: "" };
    });
  };

  /**
   * Open Popup and Close Popup Company
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setDialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setDialogs(condition);
      emptyData();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={COMPANY} slace={SLACE} name1={COMPANY_LIST} />
      <CompanyDataTableContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      {dialog && (
        <CompanyCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
        />
      )}
    </>
  );
};

export default Company;
