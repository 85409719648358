import React, { useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useQuery } from "react-query";
import { get_Posts } from "../../../../Apis/postsApi/postsApi";

const PostDataTablePopup = ({ state, dispatch }) => {
  let paramsObj = useMemo(() => {
    let paramObj = {
      showAll: true,
      search: JSON.stringify({ isActive: true }),
    };
    return paramObj;
  });
  let { data } = useQuery(["get_Posts"], () => get_Posts(paramsObj), {
    keepPreviousData: true,
    retry: 3,
  });
  return (
    <Dialog
      header="Flex Scroll"
      visible={state.postDataModal}
      style={{ width: "75vw" }}
      maximizable
      modal
      contentStyle={{ height: "300px" }}
      onHide={() => dispatch({ type: "CLOSE_MODAL" })}
    >
      <DataTable
        value={data ? [...data?.payload?.data] : []}
        scrollable
        scrollHeight="flex"
        tableStyle={{ minWidth: "50rem" }}
        selection={state.selectedPost}
        onSelectionChange={(e) => dispatch({ type: "SELECT_POST", postId: e })}
      >
        <Column selectionMode="single" headerStyle={{ width: "3rem" }} />
        <Column
          field="thumbImage"
          header="Name"
          body={(rowData) => {
            return <img src={rowData.thumbImage} width={60} alt="" />;
          }}
        ></Column>
        <Column field="title" header="Name"></Column>
        <Column field="country.name" header="Country"></Column>
        <Column field="representative.name" header="Representative"></Column>
        <Column field="company" header="Company"></Column>
      </DataTable>
    </Dialog>
  );
};

export default PostDataTablePopup;
