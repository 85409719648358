import axios from "axios";
import {
  CREATE_COMPANY,
  DELETE_COMPANY,
  EDIT_COMPANY,
  ENABLE_DISABLE_COMPANY,
  GET_COMPANY,
  GET_COMPANY_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { optionsDialCode } from "../../constants";

/**
 * get company list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const get_Company = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_COMPANY}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add company api call
 * @param {form data} formData
 * @returns
 */

export const Create_Company = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_COMPANY}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable company api call
 * @param {form data as isActive & companyId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Company = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_COMPANY}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { companyId: formData.companyId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const Get_Details_Company = (companyId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_COMPANY_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { companyId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let dialCode1 = optionsDialCode.find(
        (elem) => elem.code === data.dialCode1
      );
      let dialCode2 = optionsDialCode.find(
        (elem) => elem.code === data.dialCode2
      );
      setFormData({
        name: data?.name ?? null,
        email: data?.email ?? null,
        dialCode1: dialCode1 ?? null,
        mobile1: data?.mobile1 ?? null,
        dialCode2: dialCode2 ?? null,
        mobile2: data?.mobile2 ?? null,
        themeColor: data?.themeColor ?? null,
        fontColor: data?.fontColor ?? null,
        city: data?.city ?? null,
        state: data?.state ?? null,
        country: data?.country ?? null,
        pincode: data?.pincode ?? null,
        tagline: data?.tagline ?? null,
        address: data?.address ?? null,
        logo: data?.logo ?? null,
        image: data?.logo ?? null,
        roleId: data?.roleId ?? null,
        companyId: data?.companyId ?? null,
        orgId: data?.orgId ?? null,
      });
      setdialogs(true);
    });
};

/**
 * update company api call
 * @param {form data} formData
 * @param {*} companyId
 * @returns
 */

export const Put_Update_Company = (formData, companyId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_COMPANY}`, formData, {
      headers: { Authorization: jwt_token },
      params: { companyId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete company api call
 * @param {form data} formData
 * @param {*} companyId
 * @param {list api call } refetch
 * @returns
 */

export const DELETE_Company = (companyId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_COMPANY}`, {
      headers: { Authorization: jwt_token },
      params: { companyId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Get_Details__Company = (paramObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_COMPANY_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: paramObj,
    })
    .then(async (res) => {
      return res.data.payload.data;
    });
};
