import { Role } from "../constants";
import { getCookie } from "../utils/Helper";

export const checkTokenAndRole = () => {
  if (
    (localStorage.getItem("authToken") &&
      +localStorage.getItem("role") === Role.SuperAdmin) ||
    (getCookie("token") && +getCookie("roleId") === Role.SuperAdmin) ||
    (localStorage.getItem("authToken") &&
      +localStorage.getItem("role") === Role.OrgAdmin) ||
    (getCookie("token") && +getCookie("roleId") === Role.OrgAdmin)
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkAndShowSidebar = () => {
  if (
    +localStorage.getItem("role") === Role.SuperAdmin ||
    +getCookie("roleId") === Role.SuperAdmin ||
    +localStorage.getItem("role") === Role.OrgAdmin ||
    +getCookie("roleId") === Role.OrgAdmin
  ) {
    return true;
  } else {
    return false;
  }
};

export const isAccessUser = (roleArr) => {
  let access = +localStorage.getItem("role") || +getCookie("roleId");
  if (roleArr.includes(access)) {
    return true;
  } else {
    return false;
  }
};

export function mergeArrays(newArray, existingArray) {
  // Check if both newArray and existingArray are arrays
  if (
    (!Array.isArray(newArray) || !existingArray.length > 0) &&
    (!Array.isArray(existingArray) || !existingArray.length > 0)
  ) {
    return [];
  }
  // Create a lookup map from existingArray
  const existingMap = new Map(existingArray.map((item) => [item.title, item]));

  // Create an array to store the merged results
  const updatedArray = [];

  // Process newArray items
  newArray.forEach((item) => {
    if (existingMap.has(item.title)) {
      // If the item exists in both arrays, use color from existingArray
      const existingItem = existingMap.get(item.title);
      updatedArray.push({
        ...item,
        color: existingItem.color, // Use color from existingArray
      });
    } else {
      // If the item is only in newArray, add it as is
      updatedArray.push(item);
    }
  });

  return updatedArray;
}
