import React, { Fragment, useContext, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Submit,
  TYPE_FELDS,
  FileType,
  OrgName,
  UserLimit,
  CompanyLimit,
  CreateOrganization,
  UpdateOrganization,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createOrganizationSchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import {
  createOrganization,
  putUpdateOrganization,
} from "../../../../Apis/orgApi/OrgApi";
import OrgContext from "../../../../context/OrgContext/OrgContext";
import { InputSwitch } from "primereact/inputswitch";

const OrganizationCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setdialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(OrgContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);

  /**
   *
   * @param {event } e
   * create organization & update organization
   */

  const {
    mutate: postCreateOrganization,
    isLoading: createOrganizationLoader,
  } = useMutation((formData) => createOrganization(formData), {
    onSuccess: (response) => {
      if (response.status === 200) {
        refetch();
        setdialogs(false);
        emptyData();
        setErrors({});
        ToastMessage(response.status, response.data.message);
      }
    },
  });

  const { mutate: updateOrganization, isLoading: updateOrganizationLoader } =
    useMutation((formData) => putUpdateOrganization(formData, orgId), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const handleOrganizationCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createOrganizationSchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
        };
        updateOrganization(value);
      } else {
        let value = {
          ...formData,
        };
        postCreateOrganization(value);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.USER)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeOrgAdmin = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateOrganization : UpdateOrganization}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={createOrganizationLoader || updateOrganizationLoader}
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleOrganizationCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={OrgName}
                        name="orgName"
                        id="orgName"
                        type={TYPE_FELDS.text}
                        placeholder={OrgName}
                        value={formData.orgName}
                        handleChange={handleChangeOrgAdmin}
                      />
                      {errors.orgName && (
                        <span className="error-validation">
                          {errors.orgName}
                        </span>
                      )}
                    </Col>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={UserLimit}
                        name="userLimit"
                        id="userLimit"
                        type={TYPE_FELDS.number}
                        placeholder={UserLimit}
                        value={formData.userLimit}
                        handleChange={handleChangeOrgAdmin}
                      />
                      {errors.userLimit && (
                        <span className="error-validation">
                          {errors.userLimit}
                        </span>
                      )}
                    </Col>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={CompanyLimit}
                        name="companyLimit"
                        id="companyLimit"
                        type={TYPE_FELDS.number}
                        placeholder={CompanyLimit}
                        value={formData.companyLimit}
                        handleChange={handleChangeOrgAdmin}
                      />
                      {errors.companyLimit && (
                        <span className="error-validation">
                          {errors.companyLimit}
                        </span>
                      )}
                    </Col>
                    <Col md="3 mb-4">
                      <div className="flex flex-column gap-2 padding w-100 ">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {"Premium"}
                        </label>
                        <InputSwitch
                          checked={formData.isPremium}
                          onChange={(e) => handleChangeOrgAdmin(e)}
                          name="isPremium"
                          id="isPremium"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={
                          createOrganizationLoader || updateOrganizationLoader
                        }
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default OrganizationCreateFormPopup;
