import React from "react";
import DOMPurify from "dompurify";

const DynamicHTMLContent = ({ htmlContent, dynamicValues, style }) => {
  // Sanitize the incoming HTML
  const sanitizedHTML = DOMPurify.sanitize(htmlContent);

  // Function to replace placeholders with dynamic values
  const replaceDynamicValues = (html, values) => {
    let modifiedHTML = html;
    Object.keys(values).forEach((key) => {
      const regex = new RegExp(`{{${key}}}`, "g");
      modifiedHTML = modifiedHTML.replace(regex, values[key]);
    });
    return modifiedHTML;
  };

  // Replace placeholders in the HTML with dynamic values
  const finalHTML = replaceDynamicValues(sanitizedHTML, dynamicValues);

  // Use dangerouslySetInnerHTML to render the HTML
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: finalHTML }} />
    </>
  );
};

export default DynamicHTMLContent;
