import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import CompanyContext from "../../../../context/CompanyContext/CompanyContext";
import {
  DELETE_Company,
  Enable_Disable_Company,
  Get_Details_Company,
} from "../../../../Apis/companyApi/CompanyApi";
import Actions from "../../../../layouts/Actions";
import UserContext from "../../../../context/UserContext/UserContext";

const CompanyDataTableContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setDialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(CompanyContext);
  const { orgDetails } = useContext(UserContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get Company Details
   */
  const { mutate: companyDetails, isLoading: isLoadingCompanyDetails } =
    useMutation((companyId) =>
      Get_Details_Company(companyId, setFormData, setDialogs)
    );

  const GetCompanyDetails = (companyId) => {
    companyDetails(companyId);
    setOrgId(companyId);
    setIsEdit(false);
  };

  /**
   * delete Company
   */
  const { mutate: companyDelete, isLoading: isLoadingDelete } = useMutation(
    (companyId) => DELETE_Company(companyId, refetch)
  );

  const DeleteCompany = async (companyId) => {
    SweetConfirmation(companyDelete, companyId);
  };

  /**
   * enable / disable Company
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Company(formData, refetch)
  );

  const handleCompanyStatusChange = (companyId, status) => {
    let formDate = {
      companyId: companyId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.companyId === companyId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 120,
      field: "logo",
      headerName: "Logo",
      renderCell: (params) => (
        // params.row.logo ? params.row.logo : "--"
        <div className="flex justify-content-center ">
          <img
            className="img-thumbnail"
            src={params.row.logo}
            alt=""
            width={100}
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (params.row.email ? params.row.email : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "mobile1",
      headerName: "Mobile 1",
      renderCell: (params) =>
        params.row.mobile1
          ? params.row.dialCode1 + " " + params.row.mobile1
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "mobile2",
      headerName: "Mobile 2",
      renderCell: (params) =>
        params.row.mobile2
          ? params.row.dialCode2 + " " + params.row.mobile2
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "city",
      headerName: "City",
      renderCell: (params) => (params.row.city ? params.row.city : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "state",
      headerName: "State",
      renderCell: (params) => (params.row.state ? params.row.state : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "country",
      headerName: "Country",
      renderCell: (params) => (params.row.country ? params.row.country : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "tagline",
      headerName: "Tagline",
      renderCell: (params) => (params.row.tagline ? params.row.tagline : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleCompanyStatusChange(
                params.row.companyId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <Actions
            id={params.row.companyId}
            getDetails={GetCompanyDetails}
            deleteDetails={DeleteCompany}
            removeDelete={orgDetails?.companyLimit > 1}
          />
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingCompanyDetails || isLoadingDelete}
      />

      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.companyId}
        addButton={orgDetails?.companyLimit > 1}
      />
    </Fragment>
  );
};

export default CompanyDataTableContainer;
