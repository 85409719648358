import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import UserState from "./context/UserContext/UserState";
import LoaderState from "./context/LoaderContext/LoaderState";
import ClockLoader from "./reuseable/loader";
import { ToastContainer } from "react-toastify";
import { Fragment, Suspense, useEffect, useState } from "react";
import MobileViewDiv from "./components/pages/MobileViewDiv/MobileViewDiv";
import CssState from "./context/css_context/CssContext/CssState";
import Loader from "./layouts/loader";
import Progressbar from "./reuseable/Progressbar";
import AllRoutes from "./Routes/AllRoutes";
import CompanyState from "./context/CompanyContext/CompanyState";
import OrgState from "./context/OrgContext/OrgState";
import HashTagState from "./context/HashTagContext/HashTagState";
import ReligionState from "./context/ReligionContext/ReligionState";
import LanguageState from "./context/LanguageContext/LanguageState";
import PostsState from "./context/PostsContext/PostsState";
import CategoryState from "./context/CategoryContext/CategoryState";
import UserSelectedPostState from "./context/UserSelectedPostContext/UserSelectedPostState";
import PostBackgroundState from "./context/PostBackgroundContext/PostBackgroundState";
import FrameHeaderState from "./context/FrameHeaderContext/FrameHeaderState";
import { deleteCookie, getCookie } from "./utils/Helper";
import ThemeSettingState from "./context/ThemeSettingContext/ThemeSettingState";

function App() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [maindivSidebar, setMainDivSidebar] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    if (getCookie("token") || getCookie("roleId") || getCookie("hwId")) {
      localStorage.setItem("authToken", getCookie("token"));
      localStorage.setItem("role", getCookie("roleId"));
      localStorage.setItem("hwId", getCookie("hwId"));
      deleteCookie("token");
      deleteCookie("roleId");
      deleteCookie("hwId");
    }

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Fragment>
      <div className="all-component-wrapper">
        <Router basename="/">
          <CssState>
            <LoaderState>
              <ThemeSettingState>
                <FrameHeaderState>
                  <PostBackgroundState>
                    <UserSelectedPostState>
                      <CategoryState>
                        <PostsState>
                          <LanguageState>
                            <ReligionState>
                              <HashTagState>
                                <OrgState>
                                  <CompanyState>
                                    <UserState>
                                      <ClockLoader />
                                      <ToastContainer />
                                      <Progressbar />
                                      <Suspense fallback={<Loader />}>
                                        <AllRoutes
                                          windowSize={windowSize}
                                          setMainDivSidebar={setMainDivSidebar}
                                          maindivSidebar={maindivSidebar}
                                        />
                                      </Suspense>
                                      <MobileViewDiv />
                                    </UserState>
                                  </CompanyState>
                                </OrgState>
                              </HashTagState>
                            </ReligionState>
                          </LanguageState>
                        </PostsState>
                      </CategoryState>
                    </UserSelectedPostState>
                  </PostBackgroundState>
                </FrameHeaderState>
              </ThemeSettingState>
            </LoaderState>
          </CssState>
        </Router>
      </div>
      {/* )} */}
    </Fragment>
  );
}

export default App;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
