import React, { useState } from "react";
import ThemeSettingContext from "./ThemeSettingContext";
import { THEME_SETTING_TYPE } from "../../constants";
const ThemeSettingState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [filter, setFilter] = useState("");
  const [filterDrop, setFilterDrop] = useState({
    storeFilter: "",
  });

  /**company edit time condition and data object state */
  const [dialog, setDialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [orgId, setOrgId] = useState(null);
  const [formData, setFormData] = useState({
    address: null,
    companyId: null,
    companyName: null,
    designation: null,
    email: null,
    fontName: null,
    footerBgColor: null,
    footerFontColor: null,
    frameBorderColor: null,
    headerBgColor: null,
    headerFontColor: null,
    degreeOrSpecialty: null,
    footerDegreeOrSpecialty: null,
    footerDesignation: null,
    footerFontSize: null,
    footerImage: null,
    headerFontSize: null,
    mobileNumber: null,
    image: null,
    name: null,
    personName: null,
    shortDescription: null,
    webSite: null,
    type: THEME_SETTING_TYPE.HTML,
    frameImage: null,
    frameHeaderId: null,
    colorObj: [],
  });
  const [yes, setYes] = useState(false);
  const [errors, setErrors] = useState({});

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState();
  const [base64, setBase64] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [imageType, setImageType] = useState("");
  return (
    <>
      <ThemeSettingContext.Provider
        value={{
          pageSize,
          setPageSize,
          page,
          setPage,
          record,
          setRecord,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          filterDrop,
          setFilterDrop,
          dialog,
          setDialogs,
          isEdit,
          setIsEdit,
          orgId,
          setOrgId,
          errors,
          setErrors,
          yes,
          setYes,
          formData,
          setFormData,

          crop,
          setCrop,
          completedCrop,
          setCompletedCrop,
          scale,
          setScale,
          rotate,
          setRotate,
          aspect,
          setAspect,
          base64,
          setBase64,
          imageModal,
          setImageModal,
          imageType,
          setImageType,
        }}
      >
        {props.children}
      </ThemeSettingContext.Provider>
    </>
  );
};

export default ThemeSettingState;
