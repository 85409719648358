import axios from "axios";
import {
  CREATE_LANGUAGE,
  DELETE_LANGUAGE,
  EDIT_LANGUAGE,
  ENABLE_DISABLE_LANGUAGE,
  GET_LANGUAGE,
  GET_LANGUAGE_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get Language Master list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const getLanguageMaster = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_LANGUAGE}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add Language Master api call
 * @param {form data} formData
 * @returns
 */

export const createLanguageMaster = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_LANGUAGE}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable Language Master api call
 * @param {form data as isActive & languageId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const enableDisableLanguageMaster = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_LANGUAGE}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { languageId: formData.languageId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const getDetailsLanguageMaster = (
  languageId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_LANGUAGE_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { languageId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      setFormData({
        name: data?.name ?? null,
      });
      setdialogs(true);
    });
};

/**
 * update Language Master api call
 * @param {form data} formData
 * @param {*} languageId
 * @returns
 */

export const putUpdateLanguageMaster = (formData, languageId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_LANGUAGE}`, formData, {
      headers: { Authorization: jwt_token },
      params: { languageId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete Language Master api call
 * @param {form data} formData
 * @param {*} languageId
 * @param {list api call } refetch
 * @returns
 */

export const deleteLanguageMaster = (languageId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_LANGUAGE}`, {
      headers: { Authorization: jwt_token },
      params: { languageId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
