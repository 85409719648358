import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import HashTagContext from "../../../../context/HashTagContext/HashTagContext";
import {
  deleteHashTag,
  enableDisableHashTag,
  getDetailsHashTag,
} from "../../../../Apis/hashTagApi/HashTagApi";
import Actions from "../../../../layouts/Actions";

const HashTagDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(HashTagContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get HashTag Details
   */
  const { mutate: HashTagDetails, isLoading: isLoadingHashTagDetails } =
    useMutation((hashTagId) =>
      getDetailsHashTag(hashTagId, setFormData, setdialogs)
    );

  const GetHastagDetails = (hashTagId) => {
    HashTagDetails(hashTagId);
    setOrgId(hashTagId);
    setIsEdit(false);
  };

  /**
   * delete HashTag
   */
  const { mutate: HashTagDelete, isLoading: isLoadingDelete } = useMutation(
    (hashTagId) => deleteHashTag(hashTagId, refetch)
  );

  const DeleteHastag = async (hashTagId) => {
    SweetConfirmation(HashTagDelete, hashTagId);
  };

  /**
   * enable / disable HashTag
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    enableDisableHashTag(formData, refetch)
  );

  const handleHashTagStatusChange = (hashTagId, status) => {
    let formDate = {
      hashTagId: hashTagId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.hashTagId === hashTagId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "hashTag",
      headerName: "#",
      renderCell: (params) => (params.row.hashTag ? params.row.hashTag : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "isForX",
      headerName: "X",
      renderCell: (params) =>
        params.row.isForX ? (
          <span style={{ color: "green", fontWeight: "600" }}>Active</span>
        ) : (
          <span style={{ color: "red", fontWeight: "600" }}>In Active</span>
        ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleHashTagStatusChange(
                params.row.hashTagId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <Actions
            id={params.row.hashTagId}
            getDetails={GetHastagDetails}
            deleteDetails={DeleteHastag}
          />
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingHashTagDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.hashTagId}
        addButton={true}
      />
    </Fragment>
  );
};

export default HashTagDataTabelContainer;
