import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Role } from "../constants";
import { checkTokenAndRole } from "./helper";

const SecondPrivateRoute = () => {
  return checkTokenAndRole() ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default SecondPrivateRoute;
