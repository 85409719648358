import React, { useState } from "react";
import ReligionContext from "./ReligionContext";

const ReligionState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [filter, setFilter] = useState("");
  const [filterDrop, setFilterDrop] = useState({
    storeFilter: "",
  });

  /**Religion edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [orgId, setOrgId] = useState(null);
  const [formData, setFormData] = useState({
    name: null,
  });
  const [yes, setYes] = useState(false);
  const [errors, setErrors] = useState({});

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState();
  const [base64, setBase64] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [imageType, setImageType] = useState("");

  return (
    <ReligionContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        orgId,
        setOrgId,
        formData,
        setFormData,
        yes,
        setYes,
        errors,
        setErrors,

        crop,
        setCrop,
        completedCrop,
        setCompletedCrop,
        scale,
        setScale,
        rotate,
        setRotate,
        aspect,
        setAspect,
        base64,
        setBase64,
        imageModal,
        setImageModal,
        imageType,
        setImageType,
        filterDrop,
        setFilterDrop,
      }}
    >
      {props.children}
    </ReligionContext.Provider>
  );
};

export default ReligionState;
