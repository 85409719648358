import axios from "axios";
import {
  CREATE_POST_BACKGROUND,
  DELETE_POST_BACKGROUND,
  EDIT_POST_BACKGROUND,
  ENABLE_DISABLE_POST_BACKGROUND,
  GET_POST_BACKGROUND,
  GET_POST_BACKGROUND_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { POST_TYPE } from "../../constants";

/**
 * get Post Background list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const getPostBackground = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_POST_BACKGROUND}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add Post Background api call
 * @param {form data} formData
 * @returns
 */

export const createPostBackground = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}${CREATE_POST_BACKGROUND}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable Post Background api call
 * @param {form data as isActive & posterBackgroundMasterId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const enableDisablePostBackground = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_POST_BACKGROUND}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { posterBackgroundMasterId: formData.posterBackgroundMasterId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const getDetailsPostBackground = (
  posterBackgroundMasterId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_POST_BACKGROUND_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { posterBackgroundMasterId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      const type = POST_TYPE.find((elem) => elem.code === data?.type);
      setFormData({
        name: data?.name ?? null,
        color: data?.color.replace("#", "") ?? "#000000",
        type: type ? type : null,
        images: data?.image ?? null,
        thumbImage: data?.thumbImage ?? null,
      });
      setdialogs(true);
    });
};

/**
 * update Post Background api call
 * @param {form data} formData
 * @param {*} posterBackgroundMasterId
 * @returns
 */

export const putUpdatePostBackground = (formData, posterBackgroundMasterId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_POST_BACKGROUND}`, formData, {
      headers: { Authorization: jwt_token },
      params: { posterBackgroundMasterId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete Post Background api call
 * @param {form data} formData
 * @param {*} posterBackgroundMasterId
 * @param {list api call } refetch
 * @returns
 */

export const deletePostBackground = (posterBackgroundMasterId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_POST_BACKGROUND}`, {
      headers: { Authorization: jwt_token },
      params: { posterBackgroundMasterId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
