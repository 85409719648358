import React, { Fragment, useContext, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { DELETE_IMAGE, Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Name,
  Submit,
  TYPE_FELDS,
  FileType,
  CreatePostBackground,
  UpdatePostBackground,
  NOT_FOUND_IMAGE,
  TYPE_,
  POST_TYPE,
} from "../../../../constants";
import { ColorPicker } from "primereact/colorpicker";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createPostBackgroundSchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import PostBackgroundContext from "../../../../context/PostBackgroundContext/PostBackgroundContext";
import {
  createPostBackground,
  putUpdatePostBackground,
} from "../../../../Apis/postBackgroundApi/PostBackgroundApi";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";

const PostBackgroundCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setDialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(PostBackgroundContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);
  /**
   *
   * @param {event } e
   * create Post Background & update Post Background
   */

  const {
    mutate: createPostBackground_,
    isLoading: createPostBackgroundLoader,
  } = useMutation((formData) => createPostBackground(formData), {
    onSuccess: (response) => {
      if (response.status === 200) {
        refetch();
        setDialogs(false);
        emptyData();
        setErrors({});
        ToastMessage(response.status, response.data.message);
      }
    },
  });

  const {
    mutate: updatePostBackground,
    isLoading: updatePostBackgroundLoader,
  } = useMutation((formData) => putUpdatePostBackground(formData, orgId), {
    onSuccess: (response) => {
      if (response.status === 200) {
        refetch();
        setDialogs(false);
        emptyData();
        setErrors({});
        ToastMessage(response.status, response.data.message);
      }
    },
  });
  const handlePostBackgroundCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createPostBackgroundSchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
          image: formData.images
            ? formData.images.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          thumbImage: formData.thumbImage
            ? formData.thumbImage.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          type: formData.type.code,
          color: `#${formData.color?.replace("#", "")}`,
        };
        delete value.images;
        delete value.imgType;
        updatePostBackground(value);
      } else {
        let value = {
          ...formData,
          image: formData.images
            ? formData.images.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          thumbImage: formData.thumbImage
            ? formData.thumbImage.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          type: formData.type.code,
          color: `#${formData.color?.replace("#", "")}`,
        };
        delete value.images;
        delete value.imgType;
        createPostBackground_(value);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage, isLoading: imageLoading } = useMutation(
    (file) =>
      Upload_Image(file, setdialogsModal, setFormData, FileType.SYSTEM_IMAGE),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          const imagePath = response.data.payload.imageUrl;
          const imageBaseUrl = response.data.payload.baseUrl;
          setFormData((prev) => {
            if (prev.imgType === "images") {
              return {
                ...prev,
                images: imageBaseUrl + imagePath,
              };
            } else if (prev.imgType === "thumbImage") {
              return {
                ...prev,
                thumbImage: imageBaseUrl + imagePath,
              };
            }
          });
        }
      },
    }
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangePostBackground = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete post Background image
   */
  const { mutate: postsDelete } = useMutation((imgData) =>
    DELETE_IMAGE(imgData, refetch)
  );

  const accept = (imgType) => {
    let imgData = {
      fileName: formData[`${imgType}`]
        .split(`${process.env.REACT_APP_FILE_FOLDER}`)
        .pop(),
    };
    setFormData((prev) => {
      return {
        ...prev,
        [`${imgType}`]: "",
      };
    });
    postsDelete(imgData);
  };

  const openImageUploadModal = async (e, imgType) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      setFormData((prev) => {
        return {
          ...prev,
          image: reader.result,
          imgType,
        };
      });
    };
    await uploadImage(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        color: e.target.value,
      };
    });
  };

  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreatePostBackground : UpdatePostBackground}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={
            createPostBackgroundLoader ||
            updatePostBackgroundLoader ||
            imageLoading
          }
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handlePostBackgroundCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="3 mb-4">
                      <div className="field mb-4 ">
                        <center>
                          <h5>{"Image"}</h5>
                        </center>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              height: "150px",
                              width: "185px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                formData?.images
                                  ? formData.images
                                  : NOT_FOUND_IMAGE
                              }
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {!formData.images && (
                            <input
                              className="custom-file-input"
                              type="file"
                              onChange={(e) =>
                                openImageUploadModal(e, "images")
                              }
                            />
                          )}

                          <Button
                            className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                            style={{ height: "40px" }}
                            type="button"
                            onClick={() => {
                              if (formData.images) {
                                SweetConfirmation(accept, "images");
                              }
                            }}
                          >
                            <span
                              className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                            ></span>
                            <span
                              className="p-button-label p-c"
                              style={{ fontWeight: "100" }}
                            >
                              {"Choose"}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col md="3 mb-4">
                      <div className="field mb-4">
                        <center>
                          <h5>{"Thumb Image"}</h5>
                        </center>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              height: "150px",
                              width: "185px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                formData?.thumbImage
                                  ? formData.thumbImage
                                  : NOT_FOUND_IMAGE
                              }
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {!formData.thumbImage && (
                            <input
                              className="custom-file-input"
                              type="file"
                              onChange={(e) =>
                                openImageUploadModal(e, "thumbImage")
                              }
                            />
                          )}

                          <Button
                            className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                            style={{ height: "40px" }}
                            type="button"
                            onClick={() => {
                              if (formData.thumbImage) {
                                SweetConfirmation(accept, "thumbImage");
                              }
                            }}
                          >
                            <span
                              className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                            ></span>
                            <span
                              className="p-button-label p-c"
                              style={{ fontWeight: "100" }}
                            >
                              {"Choose"}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col md="12 mb-4">
                      <FormDataFeild
                        label={Name}
                        name="name"
                        id="name"
                        type={TYPE_FELDS.text}
                        placeholder={Name}
                        value={formData.name}
                        handleChange={handleChangePostBackground}
                        isShowValidate={errors.name}
                      />
                      {errors.name && (
                        <span className="error-validation">{errors.name}</span>
                      )}
                    </Col>

                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={TYPE_}
                        name="type"
                        id="type"
                        placeholder={TYPE_}
                        value={formData.type}
                        handleChange={handleChangePostBackground}
                        dropdown={true}
                        option={POST_TYPE?.map((elem) => {
                          return {
                            code: elem.code,
                            name: elem.name,
                          };
                        })}
                        isShowValidate={errors.type}
                        filter={false}
                      />
                      {errors.type && (
                        <span className="error-validation">{errors.type}</span>
                      )}
                    </Col>
                    <Col md="6 mb-4" className="flex align-items-end ">
                      <Col md="1 mb-4">
                        <ColorPicker
                          value={formData.color}
                          onChange={(e) => handleInputChange(e)}
                          format="hex"
                        />
                      </Col>
                      <Col md="11 mb-4">
                        <FormDataFeild
                          label={`Color`}
                          name="color"
                          id="color"
                          type={TYPE_FELDS.text}
                          placeholder={`Color`}
                          value={formData.color}
                          handleChange={handleInputChange}
                          maxlangth={6}
                          isShowValidate={errors.type}
                        />
                        {errors.type && (
                          <span className="error-validation">
                            {errors.type}
                          </span>
                        )}
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={
                          createPostBackgroundLoader ||
                          updatePostBackgroundLoader
                        }
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default PostBackgroundCreateFormPopup;
