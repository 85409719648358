import React, { useContext } from "react";
import { Logout, randomStr, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  FRAME_HEADER,
  FRAME_HEADER_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import FrameHeaderDataTableContainer from "./FrameHeaderDataTableContainer";
import FrameHeaderCreateFormPopup from "./FrameHeaderCreateFormPopup";
import FrameHeaderContext from "../../../../context/FrameHeaderContext/FrameHeaderContext";
import { getFrameHeader } from "../../../../Apis/frameHeaderApi/FrameHeaderApi";
import { POSTS_BACKGROUND } from "../../../../constants";

const FrameHeader = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //Frame Header edit condition state and data object state
    setDialogs,
    setErrors,
    dialog,
    setIsEdit,
    setFormData,
    setFilterDrop,
  } = useContext(FrameHeaderContext);

  const history = useNavigate();

  /**
   * get Frame Header List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { title: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getFrameHeader", paramsObj],
    () => getFrameHeader(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    }
  );
  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const emptyData = () => {
    setFormData({
      title: null,
      html: null,
      type: POSTS_BACKGROUND.TYPE.HTML,
      colorObj: [{ title: "", color: "", id: randomStr(2) }],
    });
    setFilterDrop((prev) => {
      return { ...prev, storeFilter: "" };
    });
  };

  /**
   * Open Popup and Close Popup category
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setDialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setDialogs(condition);
      emptyData();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={FRAME_HEADER} slace={SLACE} name1={FRAME_HEADER_LIST} />
      <FrameHeaderDataTableContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      {dialog && (
        <FrameHeaderCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
          data={data}
        />
      )}
    </>
  );
};

export default FrameHeader;
