import axios from "axios";
import {
  CREATE_RELIGION,
  DELETE_RELIGION,
  EDIT_RELIGION,
  ENABLE_DISABLE_RELIGION,
  GET_RELIGION,
  GET_RELIGION_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get Religion Master list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const getReligionMaster = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_RELIGION}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add Religion Master api call
 * @param {form data} formData
 * @returns
 */

export const createReligionMaster = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_RELIGION}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable Religion Master api call
 * @param {form data as isActive & religionId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const enableDisableReligionMaster = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_RELIGION}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { religionId: formData.religionId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const getDetailsReligionMaster = (
  religionId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_RELIGION_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { religionId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      setFormData({
        name: data?.name ?? null,
      });
      setdialogs(true);
    });
};

/**
 * update Religion Master api call
 * @param {form data} formData
 * @param {*} religionId
 * @returns
 */

export const putUpdateReligionMaster = (formData, religionId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_RELIGION}`, formData, {
      headers: { Authorization: jwt_token },
      params: { religionId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete Religion Master api call
 * @param {form data} formData
 * @param {*} religionId
 * @param {list api call } refetch
 * @returns
 */

export const deleteReligionMaster = (religionId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_RELIGION}`, {
      headers: { Authorization: jwt_token },
      params: { religionId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
