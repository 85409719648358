import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import OrganizationDataTabelContainer from "./OrganizationDataTabelContainer";
import OrganizationCreateFormPopup from "./OrganizationCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  ORG,
  ORG_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { getOrganization } from "../../../../Apis/orgApi/OrgApi";
import OrgContext from "../../../../context/OrgContext/OrgContext";

const Organization = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //organization edit condition state and data object state
    setdialogs,
    setErrors,
    dialog,
    setIsEdit,
    setFormData,
    setFilterDrop,
  } = useContext(OrgContext);

  const history = useNavigate();

  /**
   * get organization List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { name: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getOrg", paramsObj],
    () => getOrganization(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const emptyData = () => {
    setFormData({
      orgName: null,
      companyLimit: null,
      userLimit: null,
      isPremium: false,
      hwId: "paramji",
      qrOrgId: 771,
    });
    setFilterDrop((prev) => {
      return { ...prev, storeFilter: "" };
    });
  };

  /**
   * Open Popup and Close Popup User
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setdialogs(condition);
      emptyData();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={ORG} slace={SLACE} name1={ORG_LIST} />
      <OrganizationDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      {dialog && (
        <OrganizationCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
        />
      )}
    </>
  );
};

export default Organization;
