import React, { Fragment, useContext, useMemo, useState } from "react";
import {
  FormDataFeild,
  FormDataFeildTextArea,
} from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { DELETE_IMAGE, Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Submit,
  TYPE_FELDS,
  FileType,
  TITLE,
  Language,
  Religion,
  POST_TYPE,
  TYPE_,
  CreatePost,
  UpdatePost,
  NOT_FOUND_IMAGE,
  IMAGE_TYPE,
  IMAGE_TYPE_,
  Description,
  XDescription,
  POST_DATE,
  Category,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createPostsSchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import PostsContext from "../../../../context/PostsContext/PostsContext";
import {
  Create_Posts,
  Put_Update_Posts,
} from "../../../../Apis/postsApi/postsApi";
import { getLanguageMaster } from "../../../../Apis/LanguageMasterApi/LanguageMasterApi";
import { getReligionMaster } from "../../../../Apis/ReligionMasterApi/ReligionMasterApi";
import { InputSwitch } from "primereact/inputswitch";
import { get_Category } from "../../../../Apis/categoryApi/categoryApi";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";

const PostsCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setDialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(PostsContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);
  /**
   *
   * @param {event } e
   * create Company & update Posts
   */

  const { mutate: createPosts, isLoading: createCompanyLoader } = useMutation(
    (formData) => Create_Posts(formData),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );
  const { mutate: updatePosts, isLoading: updateCompanyLoader } = useMutation(
    (formData) => Put_Update_Posts(formData, orgId),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const handlePostCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createPostsSchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
          image: formData.images
            ? formData.images.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          thumbImage: formData.thumbImage
            ? formData.thumbImage.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          defaultImage: formData.defaultImage
            ? formData.defaultImage
                .split(process.env.REACT_APP_FILE_FOLDER)
                .pop()
            : "",
          templateImage: formData.templateImage
            ? formData.templateImage
                .split(process.env.REACT_APP_FILE_FOLDER)
                .pop()
            : "",
          categoryIds: formData.categoryIds.map((ele) => {
            return {
              catId: ele.code,
            };
          }),
          languageId: formData?.languageId?.code,
          religionId: formData?.religionId?.code,
          type: formData?.type?.code,
          imageType: formData?.imageType?.code,
        };
        delete value.images;
        delete value.imgType;
        updatePosts(value);
      } else {
        let value = {
          ...formData,
          image: formData.images
            ? formData.images.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          thumbImage: formData.thumbImage
            ? formData.thumbImage.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
          defaultImage: formData.defaultImage
            ? formData.defaultImage
                .split(process.env.REACT_APP_FILE_FOLDER)
                .pop()
            : "",
          templateImage: formData.templateImage
            ? formData.templateImage
                .split(process.env.REACT_APP_FILE_FOLDER)
                .pop()
            : "",
          categoryIds: formData.categoryIds.map((ele) => {
            return {
              catId: ele.code,
            };
          }),
          languageId: formData?.languageId?.code,
          religionId: formData?.religionId?.code,
          type: formData?.type?.code,
          imageType: formData?.imageType?.code,
          postId: formData?.postId?.code,
        };
        delete value.images;
        delete value.imgType;
        createPosts(value);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage, isLoading: imageLoading } = useMutation(
    (file) =>
      Upload_Image(file, setdialogsModal, setFormData, FileType.SYSTEM_IMAGE),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          const imagePath = response.data.payload.imageUrl;
          const imageBaseUrl = response.data.payload.baseUrl;
          setFormData((prev) => {
            if (prev.imgType === "images") {
              return {
                ...prev,
                images: imageBaseUrl + imagePath,
              };
            } else if (prev.imgType === "thumbImage") {
              return {
                ...prev,
                thumbImage: imageBaseUrl + imagePath,
              };
            } else if (prev.imgType === "templateImage") {
              return {
                ...prev,
                templateImage: imageBaseUrl + imagePath,
              };
            } else if (prev.imgType === "defaultImage") {
              return {
                ...prev,
                defaultImage: imageBaseUrl + imagePath,
              };
            }
          });
        }
      },
    }
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangePosts = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const search = (e) => {};

  /**
   * delete Posts
   */
  const { mutate: postsDelete, isLoading: isLoadingDelete } = useMutation(
    (imgData) => DELETE_IMAGE(imgData, refetch)
  );

  const accept = (imgType) => {
    let imgData = {
      fileName: formData[`${imgType}`]
        .split(`${process.env.REACT_APP_FILE_FOLDER}`)
        .pop(),
    };
    setFormData((prev) => {
      return {
        ...prev,
        [`${imgType}`]: "",
      };
    });
    postsDelete(imgData);
  };

  const openImageUploadModal = async (e, imgType) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      setFormData((prev) => {
        return {
          ...prev,
          image: reader.result,
          imgType,
        };
      });
    };
    await uploadImage(e.target.files[0]);
  };

  let paramObj = useMemo(() => {
    return {
      showAll: true,
      search: JSON.stringify({ isActive: true }),
    };
  }, []);

  let { data: categoryDrop } = useQuery(
    ["getCategoryDrop", paramObj],
    () => get_Category(paramObj),
    {
      keepPreviousData: true,
      retry: 3,
    }
  );
  let { data: languageDrop } = useQuery(
    ["getLanguageDrop", paramObj],
    () => getLanguageMaster(paramObj),
    {
      keepPreviousData: true,
      retry: 3,
    }
  );
  let { data: ReligionDrop } = useQuery(
    ["getReligionDrop", paramObj],
    () => getReligionMaster(paramObj),
    {
      keepPreviousData: true,
      retry: 3,
    }
  );

  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreatePost : UpdatePost}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={
            createCompanyLoader ||
            updateCompanyLoader ||
            isLoadingDelete ||
            imageLoading
          }
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handlePostCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="6 mb-4">
                      <div className="field mb-4">
                        <center>
                          <h5>{"Image"}</h5>
                        </center>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              height: "150px",
                              width: "185px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                formData?.images
                                  ? formData?.images
                                  : NOT_FOUND_IMAGE
                              }
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {!formData.images && (
                            <input
                              className="custom-file-input"
                              type="file"
                              onChange={(e) =>
                                openImageUploadModal(e, "images")
                              }
                            />
                          )}
                          <Button
                            className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                            style={{ height: "40px" }}
                            type="button"
                            onClick={() => {
                              if (formData.images) {
                                SweetConfirmation(accept, "images");
                              }
                            }}
                          >
                            <span
                              className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                            ></span>
                            <span
                              className="p-button-label p-c"
                              style={{ fontWeight: "100" }}
                            >
                              {"Choose"}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <div className="field mb-4">
                        <center>
                          <h5>{"Thumb Image (300px X 300px)"}</h5>
                        </center>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              height: "150px",
                              width: "185px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                formData?.thumbImage
                                  ? formData.thumbImage
                                  : NOT_FOUND_IMAGE
                              }
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {!formData.thumbImage && (
                            <input
                              className="custom-file-input"
                              type="file"
                              onChange={(e) =>
                                openImageUploadModal(e, "thumbImage")
                              }
                            />
                          )}

                          <Button
                            className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                            style={{ height: "40px" }}
                            type="button"
                            onClick={() => {
                              if (formData.thumbImage) {
                                SweetConfirmation(accept, "thumbImage");
                              }
                            }}
                          >
                            <span
                              className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                            ></span>
                            <span
                              className="p-button-label p-c"
                              style={{ fontWeight: "100" }}
                            >
                              {"Choose"}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Col>
                    {/* <Col md="3 mb-4">
                      <div className="field mb-4 ">
                        <center>
                          <h5>{"Template Image"}</h5>
                        </center>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              height: "150px",
                              width: "185px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                formData?.templateImage
                                  ? formData.templateImage
                                  : NOT_FOUND_IMAGE
                              }
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {!formData.templateImage && (
                            <input
                              className="custom-file-input"
                              type="file"
                              onChange={(e) =>
                                openImageUploadModal(e, "templateImage")
                              }
                            />
                          )}

                          <Button
                            className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                            style={{ height: "40px" }}
                            type="button"
                            onClick={() => {
                              if (formData.templateImage) {
                                // confirmation({
                                //   accept,
                                //   imgType: "templateImage",
                                // });
                                SweetConfirmation(accept, "templateImage");
                              }
                            }}
                          >
                            <span
                              className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                            ></span>
                            <span
                              className="p-button-label p-c"
                              style={{ fontWeight: "100" }}
                            >
                              {"Choose"}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col md="3 mb-4">
                      <div className="field mb-4 ">
                        <center>
                          <h5>{"Default Image"}</h5>
                        </center>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              height: "150px",
                              width: "185px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                formData?.defaultImage
                                  ? formData.defaultImage
                                  : NOT_FOUND_IMAGE
                              }
                              alt=""
                              style={{
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {!formData.defaultImage && (
                            <input
                              className="custom-file-input"
                              type="file"
                              onChange={(e) =>
                                openImageUploadModal(e, "defaultImage")
                              }
                            />
                          )}

                          <Button
                            className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                            style={{ height: "40px" }}
                            type="button"
                            onClick={() => {
                              if (formData.defaultImage) {
                                SweetConfirmation(accept, "defaultImage");
                              }
                            }}
                          >
                            <span
                              className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                            ></span>
                            <span
                              className="p-button-label p-c"
                              style={{ fontWeight: "100" }}
                            >
                              {"Choose"}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </Col> */}
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={TITLE}
                        name="title"
                        id="title"
                        type={TYPE_FELDS.text}
                        placeholder={TITLE}
                        value={formData.title}
                        handleChange={handleChangePosts}
                        isShowValidate={errors.title}
                      />
                      {errors.title && (
                        <span className="error-validation">{errors.title}</span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Category}
                        name="categoryIds"
                        id="categoryIds"
                        placeholder={Category}
                        value={formData.categoryIds}
                        handleChange={handleChangePosts}
                        dropdown={true}
                        multiOption={
                          categoryDrop?.payload?.data?.length
                            ? categoryDrop?.payload?.data.map((elem) => {
                                return {
                                  code: elem.catId,
                                  name: elem.catName,
                                };
                              })
                            : []
                        }
                        search={search}
                        multiDropdown={true}
                      />
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Language}
                        name="languageId"
                        id="languageId"
                        placeholder={Language}
                        value={formData.languageId}
                        handleChange={handleChangePosts}
                        dropdown={true}
                        option={
                          languageDrop?.payload?.data?.length
                            ? languageDrop?.payload?.data.map((elem) => {
                                return {
                                  code: elem.languageId,
                                  name: elem.name,
                                };
                              })
                            : []
                        }
                        isShowValidate={errors.languageId}
                        search={search}
                      />
                      {errors.languageId && (
                        <span className="error-validation">
                          {errors.languageId}
                        </span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Religion}
                        name="religionId"
                        id="religionId"
                        placeholder={Religion}
                        value={formData.religionId}
                        handleChange={handleChangePosts}
                        dropdown={true}
                        option={
                          ReligionDrop?.payload?.data?.length
                            ? ReligionDrop?.payload?.data.map((elem) => {
                                return {
                                  code: elem.religionId,
                                  name: elem.name,
                                };
                              })
                            : []
                        }
                        isShowValidate={errors.religionId}
                        search={search}
                      />
                      {errors.religionId && (
                        <span className="error-validation">
                          {errors.religionId}
                        </span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={TYPE_}
                        name="type"
                        id="type"
                        placeholder={TYPE_}
                        value={formData.type}
                        handleChange={handleChangePosts}
                        dropdown={true}
                        option={POST_TYPE?.map((elem) => {
                          return {
                            code: elem.code,
                            name: elem.name,
                          };
                        })}
                        isShowValidate={errors.type}
                        filter={false}
                      />
                      {errors.type && (
                        <span className="error-validation">{errors.type}</span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={IMAGE_TYPE_}
                        name="imageType"
                        id="imageType"
                        placeholder={IMAGE_TYPE_}
                        value={formData.imageType}
                        handleChange={handleChangePosts}
                        dropdown={true}
                        option={IMAGE_TYPE?.map((elem) => {
                          return {
                            code: elem.code,
                            name: elem.name,
                          };
                        })}
                        // search={search}
                        isShowValidate={errors.imageType}
                        filter={false}
                      />
                      {errors.imageType && (
                        <span className="error-validation">
                          {errors.imageType}
                        </span>
                      )}
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={`${POST_DATE}`}
                        name="postDate"
                        id="postDate"
                        type={TYPE_FELDS.date}
                        placeholder={`${POST_DATE}`}
                        value={formData?.postDate?.split("T")[0]}
                        handleChange={handleChangePosts}
                        isValid={false}
                      />
                    </Col>
                    <Col md="2 mb-4" className="flex">
                      <div className="flex flex-column gap-2 padding w-100 ">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {"Premium"}
                        </label>
                        <InputSwitch
                          checked={formData.isPremium}
                          onChange={(e) => handleChangePosts(e)}
                          name="isPremium"
                          id="isPremium"
                        />
                      </div>
                      <div className="flex flex-column gap-2 padding w-100 ">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {"SameDay"}
                        </label>
                        <InputSwitch
                          checked={formData.isSameDay}
                          onChange={(e) => handleChangePosts(e)}
                          name="isSameDay"
                          id="isSameDay"
                        />
                      </div>
                    </Col>

                    <Col md="12 mb-4">
                      <FormDataFeild
                        label={`${XDescription}`}
                        name="xDescription"
                        id="xDescription"
                        type={TYPE_FELDS.text}
                        placeholder={`${XDescription}`}
                        value={formData.xDescription}
                        handleChange={handleChangePosts}
                        isValid={false}
                      />
                    </Col>
                    <Col md="12 mb-4">
                      <FormDataFeildTextArea
                        label={Description}
                        name="description"
                        id="description"
                        type={TYPE_FELDS.text}
                        placeholder={Description}
                        value={formData.description}
                        handleChange={handleChangePosts}
                        isValid={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={createCompanyLoader || updateCompanyLoader}
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default PostsCreateFormPopup;
