import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import Actions from "../../../../layouts/Actions";
import {
  deleteThemeSetting,
  enableDisableThemeSetting,
  getDetailsThemeSetting,
} from "../../../../Apis/themeSettingApi/ThemeSettingApi";
import ThemeSettingContext from "../../../../context/ThemeSettingContext/ThemeSettingContext";

const ThemeSettingDataTableContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setDialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(ThemeSettingContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get theme setting Details
   */
  const {
    mutate: themeSettingDetails,
    isLoading: isLoadingThemeSettingDetails,
  } = useMutation((themeSettingsId) =>
    getDetailsThemeSetting(themeSettingsId, setFormData, setDialogs)
  );

  const GetCompanyDetails = (themeSettingsId) => {
    themeSettingDetails(themeSettingsId);
    setOrgId(themeSettingsId);
    setIsEdit(false);
  };

  /**
   * delete theme setting
   */
  const { mutate: themeSettingDelete, isLoading: isLoadingDelete } =
    useMutation((themeSettingsId) =>
      deleteThemeSetting(themeSettingsId, refetch)
    );

  const DeleteThemeSetting = async (themeSettingsId) => {
    SweetConfirmation(themeSettingDelete, themeSettingsId);
  };

  /**
   * enable / disable theme setting
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    enableDisableThemeSetting(formData, refetch)
  );

  const handleThemeSettingStatusChange = (themeSettingsId, status) => {
    let formDate = {
      themeSettingsId: themeSettingsId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.themeSettingsId === themeSettingsId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 120,
      field: "frameImage",
      headerName: "FrameImage",
      renderCell: (params) =>
        params.row.frameImage ? (
          <div className="flex justify-content-center ">
            <img
              className="img-thumbnail"
              src={params.row.frameImage}
              alt=""
              width={50}
            />
          </div>
        ) : (
          "--"
        ),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "type",
      headerName: "Type",
      renderCell: (params) => (params.row.type ? params.row.type : "--"),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "image",
      headerName: "Image",
      renderCell: (params) =>
        params.row.image ? (
          <div className="flex justify-content-center ">
            <img
              className="img-thumbnail"
              src={params.row.image}
              alt=""
              width={100}
            />
          </div>
        ) : (
          "--"
        ),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "footerImage",
      headerName: "Footer Image",
      renderCell: (params) =>
        params.row.footerImage ? (
          <div className="flex justify-content-center ">
            <img
              className="img-thumbnail"
              src={params.row.footerImage}
              alt=""
              width={100}
            />
          </div>
        ) : (
          "--"
        ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "companyName",
      headerName: "Company Name",
      renderCell: (params) =>
        params.row.companyName ? params.row.companyName : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (params.row.email ? params.row.email : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "personName",
      headerName: "Person Name",
      renderCell: (params) =>
        params.row.personName ? params.row.personName : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "mobileNumber",
      headerName: "Mobile",
      renderCell: (params) =>
        params.row.mobileNumber ? params.row.mobileNumber : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "webSite",
      headerName: "WebSite",
      renderCell: (params) => (params.row.webSite ? params.row.webSite : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "address",
      headerName: "Address",
      renderCell: (params) => (params.row.address ? params.row.address : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "degreeOrSpecialty",
      headerName: "Specialty",
      renderCell: (params) =>
        params.row.degreeOrSpecialty ? params.row.degreeOrSpecialty : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "designation",
      headerName: "Designation",
      renderCell: (params) =>
        params.row.designation ? params.row.designation : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "fontName",
      headerName: "FontName",
      renderCell: (params) =>
        params.row.fontName ? params.row.fontName : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "footerBgColor",
      headerName: "Footer BgColor",
      renderCell: (params) =>
        params.row.footerBgColor ? params.row.footerBgColor : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "footerDegreeOrSpecialty",
      headerName: "Footer Specialty",
      renderCell: (params) =>
        params.row.footerDegreeOrSpecialty
          ? params.row.footerDegreeOrSpecialty
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "footerDesignation",
      headerName: "Footer Designation",
      renderCell: (params) =>
        params.row.footerDesignation ? params.row.footerDesignation : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "footerFontColor",
      headerName: "Footer Font Color",
      renderCell: (params) =>
        params.row.footerFontColor ? params.row.footerFontColor : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "footerFontSize",
      headerName: "Footer Font Size",
      renderCell: (params) =>
        params.row.footerFontSize ? params.row.footerFontSize : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "frameBorderColor",
      headerName: "Footer BorderColor",
      renderCell: (params) =>
        params.row.frameBorderColor ? params.row.frameBorderColor : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "headerBgColor",
      headerName: "Footer Bg Color",
      renderCell: (params) =>
        params.row.headerBgColor ? params.row.headerBgColor : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "headerFontColor",
      headerName: "Footer Font Color",
      renderCell: (params) =>
        params.row.headerFontColor ? params.row.headerFontColor : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "headerFontSize",
      headerName: "Footer Font Size",
      renderCell: (params) =>
        params.row.headerFontSize ? params.row.headerFontSize : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleThemeSettingStatusChange(
                params.row.themeSettingsId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <Actions
            id={params.row.themeSettingsId}
            getDetails={GetCompanyDetails}
            deleteDetails={DeleteThemeSetting}
          />
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingThemeSettingDetails || isLoadingDelete}
      />

      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.themeSettingsId}
        addButton={true}
      />
    </Fragment>
  );
};

export default ThemeSettingDataTableContainer;
