import axios from "axios";
import {
  CREATE_THEME_SETTING,
  DELETE_THEME_SETTING,
  EDIT_THEME_SETTING,
  ENABLE_DISABLE_THEME_SETTING,
  GET_THEME_SETTING,
  GET_THEME_SETTING_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { getFrameHeader } from "../frameHeaderApi/FrameHeaderApi";
import { POSTS_BACKGROUND } from "../../constants";
import { mergeArrays } from "../../Routes/helper";

/**
 * get theme setting list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const getThemeSetting = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_THEME_SETTING}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add theme setting api call
 * @param {form data} formData
 * @returns
 */

export const createThemeSetting = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_THEME_SETTING}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable theme setting api call
 * @param {form data as isActive & themeSettingsId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const enableDisableThemeSetting = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_THEME_SETTING}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { themeSettingsId: formData.themeSettingsId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const getDetailsThemeSetting = async (
  themeSettingsId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_THEME_SETTING_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { themeSettingsId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      const params = {
        search: { isActive: true, type: POSTS_BACKGROUND.TYPE.HTML },
      };
      const datas = await getFrameHeader(params);
      // Step 1: Get frameHeaderIds from both arrays
      const Array1 = datas.payload.data.find(
        (item) => item.frameHeaderId === data.frameHeaderId
      );
      const resultColor = mergeArrays(Array1?.colorObj, data?.colorObj);
      setFormData({
        address: data?.address ?? null,
        companyId: data?.companyId ?? null,
        companyName: data?.companyName ?? null,
        degreeOrSpecialty: data?.degreeOrSpecialty ?? null,
        designation: data?.designation ?? null,
        email: data?.email ?? null,
        fontName: data?.fontName ?? null,
        footerBgColor: data?.footerBgColor ?? null,
        footerDegreeOrSpecialty: data?.footerDegreeOrSpecialty ?? null,
        footerDesignation: data?.footerDesignation ?? null,
        footerFontColor: data?.footerFontColor ?? null,
        footerFontSize: data?.footerFontSize ?? null,
        footerImage: data?.footerImage ?? null,
        frameBorderColor: data?.frameBorderColor ?? null,
        headerBgColor: data?.headerBgColor ?? null,
        headerFontColor: data?.headerFontColor ?? null,
        headerFontSize: data?.headerFontSize ?? null,
        mobileNumber: data?.mobileNumber ?? null,
        image: data?.image ?? null,
        name: data?.name ?? null,
        personName: data?.personName ?? null,
        shortDescription: data?.shortDescription ?? null,
        webSite: data?.webSite ?? null,
        type: data?.type ?? null,
        frameImage: data?.frameImage ?? null,
        frameHeaderId: data?.frameHeaderId ?? null,
        frameHeaderRefId: data?.frameHeaderId ?? null,
        colorObj: resultColor.length > 0 ? resultColor : data?.colorObj,
        demoColor: resultColor.length > 0 ? resultColor : data?.colorObj,
      });
      setdialogs(true);
    });
};

/**
 * update theme setting api call
 * @param {form data} formData
 * @param {*} themeSettingsId
 * @returns
 */

export const putUpdateThemeSetting = (formData, themeSettingsId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_THEME_SETTING}`, formData, {
      headers: { Authorization: jwt_token },
      params: { themeSettingsId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete theme setting api call
 * @param {form data} formData
 * @param {*} themeSettingsId
 * @param {list api call } refetch
 * @returns
 */

export const deleteThemeSetting = (themeSettingsId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_THEME_SETTING}`, {
      headers: { Authorization: jwt_token },
      params: { themeSettingsId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
