import * as Yup from "yup";

export const createUserSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    dialCode: Yup.object().required("Dial Code is required"),
    mobile: Yup.number().required("Mobile is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .matches(/\.com$/, "Email must end with .com")
      .required("Email is required"),
    companyId: Yup.object().required("Company is required"),
    roleId: Yup.object().required("Role Id is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
};
export const createCompanySchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .matches(/\.com$/, "Email must end with .com")
      .required("Email is required"),
    dialCode1: Yup.object().required("Dial Code 1 is required"),
    mobile1: Yup.number().required("Mobile 1 is required"),
    pincode: Yup.number().required("Pincode is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
  });
};
export const createPostsSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),
    languageId: Yup.object().required("Language is required"),
    religionId: Yup.object().required("Religion is required"),
    type: Yup.object().required("Type Code 1 is required"),
    imageType: Yup.object().required("Image Type Code 1 is required"),
  });
};
export const createCategorySchema = () => {
  return Yup.object().shape({
    catName: Yup.string().required("CatName is required"),
    dateTitle: Yup.string().required("DateTitle is required"),
  });
};

export const createUserPasswordSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    dialCode: Yup.object().required("Dial Code is required"),
    mobile: Yup.number().required("Mobile is required"),
    email: Yup.string().email().required("Email is required"),
    companyId: Yup.object().required("Company is required"),
    roleId: Yup.object().required("Role Id is required"),
  });
};

export const createOrganizationSchema = () => {
  return Yup.object().shape({
    orgName: Yup.string().required("Org Name is required"),
    companyLimit: Yup.string().required("Company Limit is required"),
    userLimit: Yup.string().required("User Limit is required"),
  });
};

export const createHashTagSchema = () => {
  return Yup.object().shape({
    hashTag: Yup.string().required("Has Tag is required"),
    companyId: Yup.object().required("Company is required"),
  });
};

export const createReligionSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
};

export const createSelectedPostSchema = () => {
  return Yup.object().shape({
    postId: Yup.object().required("Post is required"),
    companyId: Yup.object().required("Company is required"),
  });
};

export const createPostBackgroundSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    images: Yup.string().required("Image is required"),
    type: Yup.object().required("Type is required"),
  });
};

export const createFrameHeaderSchema = () => {
  let myObj = {
    title: Yup.string().required("Title is required"),
    html: Yup.string().required("HTML is required"),
  };
  return Yup.object().shape(myObj);
};
