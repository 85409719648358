import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import UserSelectedPostDataTabelContainer from "./UserSelectedPostDataTabelContainer";
import UserSelectedPostCreateFormPopup from "./UserSelectedPostCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  SELECTED,
  SELECTED_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import UserSelectedPostContext from "../../../../context/UserSelectedPostContext/UserSelectedPostContext";
import { getSelectedPost } from "../../../../Apis/userSelectedPostApi/userSelectedPostApi";

const UserSelectedPoster = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //selected post edit condition state and data object state
    setdialogs,
    setErrors,
    dialog,
    setIsEdit,
    setFormData,
    setFilterDrop,
  } = useContext(UserSelectedPostContext);

  const history = useNavigate();

  /**
   * get selected post List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { name: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getSelectedPost", paramsObj],
    () => getSelectedPost(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const emptyData = () => {
    setFormData({
      companyId: null,
      postId: null,
    });
    setFilterDrop((prev) => {
      return { ...prev, postFilter: "", companyFilter: "" };
    });
  };

  /**
   * Open Popup and Close Popup User
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setdialogs(condition);
      emptyData();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={SELECTED} slace={SLACE} name1={SELECTED_LIST} />
      <UserSelectedPostDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      {dialog && (
        <UserSelectedPostCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
        />
      )}
    </>
  );
};

export default UserSelectedPoster;
