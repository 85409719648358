import React, { Fragment, useContext, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Submit,
  TYPE_FELDS,
  FileType,
  UpdateReligion,
  CreateReligion,
  Name,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createReligionSchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import {
  createReligionMaster,
  putUpdateReligionMaster,
} from "../../../../Apis/ReligionMasterApi/ReligionMasterApi";
import ReligionContext from "../../../../context/ReligionContext/ReligionContext";

const ReligionCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setdialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(ReligionContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);

  /**
   *
   * @param {event } e
   * create Religion & update Religion
   */

  const { mutate: createReligionMaster_, isLoading: createReligionLoader } =
    useMutation((formData) => createReligionMaster(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const { mutate: updateReligion, isLoading: updateReligionLoader } =
    useMutation((formData) => putUpdateReligionMaster(formData, orgId), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const handleReligionCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createReligionSchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
        };
        updateReligion(value);
      } else {
        let value = {
          ...formData,
        };
        createReligionMaster_(value);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.USER)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeReligion = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateReligion : UpdateReligion}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={createReligionLoader || updateReligionLoader}
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleReligionCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="12 mb-4">
                      <FormDataFeild
                        label={Name}
                        name="name"
                        id="name"
                        type={TYPE_FELDS.text}
                        placeholder={Name}
                        value={formData.name}
                        handleChange={handleChangeReligion}
                        isShowValidate={errors.name}
                      />
                      {errors.name && (
                        <span className="error-validation">{errors.name}</span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={createReligionLoader || updateReligionLoader}
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default ReligionCreateFormPopup;
