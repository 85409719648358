import { Button as MuiButton } from "@mui/material";
import { Button, Button as Primebutton } from "primereact/button";

import React from "react";

const Buttons = (props) => {
  const { click, name } = props;
  return (
    <div className="d-flex">
      <button
        className="btn btn-outline-success"
        // type="submit"
        onClick={() => click()}
      >
        {name}
      </button>
    </div>
  );
};

export default Buttons;

export const MuiButtons = (props) => {
  const { click, name } = props;
  return (
    <MuiButton
      variant="contained"
      onClick={() => click()}
      fullWidth
      size="large"
      style={{ marginBottom: "25px" }}
    >
      {name}
    </MuiButton>
  );
};
export const PrimeButtons = (props) => {
  const { click, name, type } = props;
  return (
    <Primebutton
      onClick={() => click()}
      type={type}
      // className="p-button p-component w-full md:w-25rem"
      style={{ height: "44px" }}
    >
      <span className="p-button-label p-c" style={{ fontSize: "15px" }}>
        {name}
      </span>
    </Primebutton>
  );
};

export const SubmitTypeButton = (props) => {
  const { name } = props;
  return (
    <Primebutton type="submit" value="submit" style={{ height: "44px" }}>
      <span className="p-button-label p-c" style={{ fontSize: "15px" }}>
        {name}
      </span>
    </Primebutton>
  );
};

export const CreateButtonOutline = (props) => {
  const { click, name, variant, icon, isExport } = props;
  return (
    <Primebutton
      onClick={() => click(true)}
      className={`p-button p-component p-button-outlined ${
        isExport ? "w-75" : "w-100"
      } sm:w-auto flex-order-0 sm:flex-order-1`}
      outlined
      aria-label="Filter"
      severity={variant}
    >
      <span
        className={`p-button-icon p-c p-button-icon-left pi ${icon}`}
      ></span>
      <span className="p-button-label p-c" style={{ fontWeight: "100" }}>
        {name}
      </span>
    </Primebutton>
  );
};

export const PhotoAndUploadeBtn = (props) => {
  const { onClick, icon, color } = props;
  return (
    <Button
      className={`p-button ${color} p-component  w-full sm:w-auto flex-order-0 sm:flex-order-1`}
      style={{ height: "40px" }}
      onClick={() => onClick()}
    >
      <span
        className={`p-button-icon p-c p-button-icon-left pi ${icon}`}
        style={{ margin: "0" }}
      ></span>
    </Button>
  );
};

export const CancelBtn = (props) => {
  const { label, icon, handleClose } = props;
  return (
    <Button
      className="p-button-danger"
      label={label}
      icon={`pi ${icon}`}
      onClick={() => handleClose()}
    />
  );
};

export const BackButton = ({ history, path }) => {
  return (
    <Button
      label="Back"
      severity="danger"
      text
      icon="pi pi-arrow-left"
      style={{ marginBottom: "5px" }}
      onClick={() => history(path)}
    />
  );
};
