import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { POSTS_BACKGROUND, TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import Actions from "../../../../layouts/Actions";
import FrameHeaderContext from "../../../../context/FrameHeaderContext/FrameHeaderContext";
import {
  deleteFrameHeader,
  enableDisableFrameHeader,
  getDetailsFrameHeader,
} from "../../../../Apis/frameHeaderApi/FrameHeaderApi";

const FrameHeaderDataTableContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setDialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(FrameHeaderContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get Frame Header Details
   */
  const {
    mutate: categoryFrameHeader,
    isLoading: isLoadingFrameHeaderDetails,
  } = useMutation((frameHeaderId) =>
    getDetailsFrameHeader(frameHeaderId, setFormData, setDialogs)
  );

  const GetFrameHeaderDetails = (frameHeaderId) => {
    categoryFrameHeader(frameHeaderId);
    setOrgId(frameHeaderId);
    setIsEdit(false);
  };

  /**
   * delete Frame Header
   */
  const { mutate: postFrameHeaderDelete, isLoading: isLoadingDelete } =
    useMutation((frameHeaderId) => deleteFrameHeader(frameHeaderId, refetch));

  const DeleteFrameHeader = async (frameHeaderId) => {
    SweetConfirmation(postFrameHeaderDelete, frameHeaderId);
  };

  /**
   * enable / disable Frame Header
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    enableDisableFrameHeader(formData, refetch)
  );

  const handleCompanyStatusChange = (frameHeaderId, status) => {
    let formDate = {
      frameHeaderId: frameHeaderId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.frameHeaderId === frameHeaderId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "title",
      headerName: "Title",
      renderCell: (params) => (params.row.title ? params.row.title : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "type",
      headerName: "Type",
      renderCell: (params) =>
        params.row.type ? POSTS_BACKGROUND.TYPE_[params.row.type] : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleCompanyStatusChange(
                params.row.frameHeaderId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <Actions
            id={params.row.frameHeaderId}
            getDetails={GetFrameHeaderDetails}
            deleteDetails={DeleteFrameHeader}
          />
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingFrameHeaderDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.frameHeaderId}
        addButton={true}
      />
    </Fragment>
  );
};

export default FrameHeaderDataTableContainer;
