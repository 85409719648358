import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../assets/css/Navbar.css";
import UserContext from "../context/UserContext/UserContext";
import CssContext from "../context/css_context/CssContext/CssContext";
import Footer from "../layouts/footer";
import Loader from "../layouts/loader";
import { REDIRECT_PATH } from "../Routes";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import {
  DASHBOARD_ITEM,
  DASHBOARD_TITLE,
  USER_ITEM,
  USER_TITLE,
  ORG_TITLE,
  ORG_ITEM,
  COMPANY_ITEM,
  COMPANY_TITLE,
  HAS_TAG_TITLE,
  HAS_TAG_ITEM,
  RELIGION_TITLE,
  RELIGION_ITEM,
  LANGUAGE_TITLE,
  LANGUAGE_ITEM,
  POSTS_TITLE,
  POSTS_ITEM,
  CATEGORY_TITLE,
  CATEGORY_ITEM,
  SELECTED_ITEM,
  POST_BACKGROUND_ITEM,
  FRAME_HEADER_TITLE,
  FRAME_HEADER_ITEM,
  THEME_SETTING_TITLE,
  THEME_SETTING_ITEM,
} from "../reuseable/Breadcrumb/BreadcrumbConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  dopdownOpen,
  dropdownClose,
} from "../redux/slices/headerDropdown/headerDropdown";
import { GetRole, getCookie } from "../utils/Helper";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ToolTipShowOrNot from "../layouts/ToolTipShowOrNot";
import { isAccessUser } from "../Routes/helper";
import { Role } from "../constants";
import { useQuery } from "react-query";
import { getDetailsOrganization } from "../Apis/orgApi/OrgApi";
import { jwtDecode } from "jwt-decode";

const Navbar = (props) => {
  const { windowSize, setMainDivSidebar, maindivSidebar } = props;
  const history = useNavigate();
  const { yes, setYes, setOrgDetails } = useContext(UserContext);
  const decoded = localStorage.getItem("authToken")
    ? jwtDecode(localStorage.getItem("authToken").replace("Bearer ", ""))
    : "";

  /** redux */
  const headerDropdown = useSelector((state) => state.header_dropdown);
  const dispatch = useDispatch();
  const {
    setMobileSidebar,
    setMobileSidebarOpenAndClose,
    mobileSidebarOpenAndClose,
    darkMode,
    setDarkMode,
  } = useContext(CssContext);
  const [isLoading, setIsLoading] = useState(true);
  const [SidebarMenu, setSidebarMenu] = useState([]);

  useEffect(() => {
    setSidebarMenu([
      {
        title: DASHBOARD_TITLE,
        isShow: true,
        items: [
          {
            name: DASHBOARD_ITEM,
            iconClassName: "pi pi-th-large",
            to: REDIRECT_PATH.DASHBOARD,
            isMenuActive: false,
            isShow: true,
          },
        ],
      },
      {
        title: USER_TITLE,
        isShow: true,
        items: [
          {
            name: USER_ITEM,
            iconClassName: "pi pi-user-plus",
            to: REDIRECT_PATH.USER,
            isMenuActive: false,
            isShow: true,
          },
        ],
      },
      {
        title: COMPANY_TITLE,
        isShow: true,
        items: [
          {
            name: COMPANY_ITEM,
            iconClassName: "pi pi-credit-card",
            to: REDIRECT_PATH.COMPANY,
            isMenuActive: false,
            isShow: true,
          },
        ],
      },
      {
        title: ORG_TITLE,
        isShow: isAccessUser([Role.SuperAdmin]),
        items: [
          {
            name: ORG_ITEM,
            iconClassName: "pi pi-users",
            to: REDIRECT_PATH.ORGANIZATION,
            isMenuActive: false,
            isShow: isAccessUser([Role.SuperAdmin]),
          },
        ],
      },
      {
        title: HAS_TAG_TITLE,
        isShow: isAccessUser([Role.SuperAdmin]),
        items: [
          {
            name: HAS_TAG_ITEM,
            iconClassName: "pi pi-hashtag",
            to: REDIRECT_PATH.HAS_TAG,
            isMenuActive: false,
            isShow: isAccessUser([Role.SuperAdmin]),
          },
        ],
      },
      {
        title: RELIGION_TITLE,
        isShow: isAccessUser([Role.SuperAdmin]),
        items: [
          {
            name: RELIGION_ITEM,
            iconClassName: "pi pi-book",
            to: REDIRECT_PATH.RELIGION,
            isMenuActive: false,
            isShow: isAccessUser([Role.SuperAdmin]),
          },
        ],
      },
      {
        title: POSTS_TITLE,
        isShow: isAccessUser([Role.SuperAdmin]),
        items: [
          {
            name: POSTS_TITLE,
            iconClassName: "pi pi-images",
            isMenuActive: false,
            isShow: isAccessUser([Role.SuperAdmin]),
            submenu: [
              {
                name: POSTS_ITEM,
                to: REDIRECT_PATH.POSTS,
                iconClassName: "pi pi-images",
                isSubMenuActive: false,
                isShow: isAccessUser([Role.SuperAdmin]),
              },
              {
                name: SELECTED_ITEM,
                iconClassName: "pi pi-check-circle",
                to: REDIRECT_PATH.SELECTED_POSTS,
                isSubMenuActive: false,
                isShow: isAccessUser([Role.SuperAdmin]),
              },
              {
                name: POST_BACKGROUND_ITEM,
                iconClassName: "pi pi-palette",
                to: REDIRECT_PATH.POSTS_BACKGROUND,
                isSubMenuActive: false,
                isShow: isAccessUser([Role.SuperAdmin]),
              },
              {
                name: "Make Post",
                iconClassName: "pi pi-image",
                to: REDIRECT_PATH.MACK_POST,
                isSubMenuActive: false,
                isShow: isAccessUser([Role.SuperAdmin]),
              },
            ],
          },
        ],
      },
      {
        title: LANGUAGE_TITLE,
        isShow: isAccessUser([Role.SuperAdmin]),
        items: [
          {
            name: LANGUAGE_ITEM,
            iconClassName: "pi pi-language",
            to: REDIRECT_PATH.LANGUAGE,
            isShow: isAccessUser([Role.SuperAdmin]),
          },
        ],
      },
      {
        title: CATEGORY_TITLE,
        isShow: isAccessUser([Role.SuperAdmin]),
        items: [
          {
            name: CATEGORY_ITEM,
            iconClassName: "pi pi-table",
            to: REDIRECT_PATH.CATEGORY,
            isShow: isAccessUser([Role.SuperAdmin]),
          },
        ],
      },
      {
        title: FRAME_HEADER_TITLE,
        isShow: true,
        items: [
          {
            name: FRAME_HEADER_ITEM,
            iconClassName: "pi pi-image",
            to: REDIRECT_PATH.FRAME_HEADER,
            isShow: true,
          },
        ],
      },
      {
        title: THEME_SETTING_TITLE,
        isShow: true,
        items: [
          {
            name: THEME_SETTING_ITEM,
            iconClassName: "pi pi-cog",
            to: REDIRECT_PATH.THEME_SETTING,
            isShow: true,
          },
        ],
      },
    ]);
  }, [+localStorage.getItem("role"), +getCookie("roleId")]);

  const LogoutToWebsite = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("role");
    localStorage.removeItem("orgId");
    history(REDIRECT_PATH.AUTH);
    dispatch(dropdownClose());
  };

  useEffect(() => {
    setMainDivSidebar(yes);
    // eslint-disable-next-line
  }, [yes]);

  const openAndCloseResponsiveSidebarShow = async (value) => {
    if (value === true) {
      setMobileSidebarOpenAndClose(value);
      await setMobileSidebar(value);
    } else if (value === false) {
      setMobileSidebarOpenAndClose(value);
      await setMobileSidebar(value);
    }
  };

  const handleClick = (i, index, submenuLength) => {
    SidebarMenu.map((manu, mmi) => {
      // main manu index
      return manu.items.map((elem, smi) => {
        // sub manu index
        if (i === mmi && smi === index && elem.isMenuActive === false) {
          elem.isMenuActive = true;
        } else {
          elem.isMenuActive = false;
        }
        return elem;
      });
    });
    setSidebarMenu(SidebarMenu);

    if (!submenuLength) {
      setMobileSidebar(false);
      setMobileSidebarOpenAndClose(false);
    }
  };

  useQuery(
    ["getDetailsOrganization"],
    () => getDetailsOrganization(decoded?.orgId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
        setOrgDetails(response);
      },
    }
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  isLoading
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = null);
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div
        id={
          mobileSidebarOpenAndClose === true
            ? "mobile-View-sidebar-open"
            : mobileSidebarOpenAndClose === false &&
              windowSize.innerWidth <= 991.98
            ? "mobile-View-sidebar-close"
            : yes === true && yes !== undefined
            ? "mySidenav"
            : yes === false && yes !== undefined
            ? "mySidenav-static"
            : ""
        }
        className={`sidenav ${darkMode ? "dark-mode" : ""}`}
      >
        <div className="sidebar-header">
          {mobileSidebarOpenAndClose === true ? (
            <div onClick={() => openAndCloseResponsiveSidebarShow(false)}>
              <i
                className="pi pi-times
            "
              ></i>
            </div>
          ) : (
            <>
              <div
                onClick={() => setYes(!yes)}
                className={`${yes ? "sidebar-toggle-btn" : "fill-sidebar-btn"}`}
              >
                <i className="pi pi-th-large"></i>
              </div>
            </>
          )}
        </div>
        <ul>
          {SidebarMenu.map((elem, i) => {
            return (
              <div key={i}>
                {elem.isShow ? (
                  <>
                    <li
                      style={{
                        padding: "15px 25px",
                        fontSize: "13px",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        color: "#6366f1",
                      }}
                      key={i}
                      className={`${
                        windowSize.innerWidth <= 991.98 ? "" : "show-title"
                      } ${darkMode ? "dark-side-title" : ""}`}
                    >
                      {elem.title}
                    </li>
                    {elem.items?.map((item, index) => {
                      return item?.isShow ? (
                        <ToolTipShowOrNot
                          BootstrapTooltip={BootstrapTooltip}
                          item={item}
                          key={index}
                          content={
                            <li key={index}>
                              <NavLink
                                to={item.to}
                                onClick={() => handleClick(i, index)}
                              >
                                <div
                                  className={
                                    darkMode
                                      ? `${
                                          item.isMenuActive
                                            ? "active-dark dark-static-href"
                                            : "dark-static-href"
                                        }`
                                      : `${
                                          item.isMenuActive
                                            ? "active-min static-href"
                                            : "static-href"
                                        }`
                                  }
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <i
                                      className={`${item.iconClassName} ${
                                        darkMode ? "dark-side-title" : ""
                                      }`}
                                    ></i>
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        width: "115px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                      className={`${
                                        windowSize.innerWidth <= 991.98
                                          ? ""
                                          : "show-title"
                                      } ${darkMode ? "dark-side-menu" : ""} `}
                                    >
                                      {item.name}
                                    </span>
                                  </div>
                                  {item.submenu?.length ? (
                                    <i
                                      className="pi pi-chevron-down"
                                      style={{
                                        paddingRight: "10px",
                                        color: "black",
                                      }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </NavLink>
                              {item.submenu &&
                                item.submenu.map((subElem, i) => {
                                  return subElem?.isShow ? (
                                    <ul
                                      key={i}
                                      className={`
                            ${
                              item.isMenuActive === true
                                ? "submenu-open"
                                : "submenu-close"
                            }`}
                                    >
                                      <BootstrapTooltip
                                        title={subElem.name}
                                        TransitionComponent={Zoom}
                                      >
                                        <li
                                          style={{
                                            width: "205px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          <NavLink
                                            className={(state) =>
                                              darkMode
                                                ? state.isActive
                                                  ? "dark-active-sub"
                                                  : ""
                                                : state.isActive
                                                ? "active-sub"
                                                : ""
                                            }
                                            to={subElem.to}
                                          >
                                            <span>
                                              <i
                                                className={
                                                  subElem.iconClassName
                                                }
                                              ></i>
                                            </span>
                                            {subElem.name}
                                          </NavLink>
                                        </li>
                                      </BootstrapTooltip>
                                    </ul>
                                  ) : (
                                    ""
                                  );
                                })}
                            </li>
                          }
                        />
                      ) : (
                        ""
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </ul>
      </div>
      <div className="layout-topbar-right">
        <ul className={`${darkMode ? "dark-nav" : ""}`}>
          <li onClick={() => openAndCloseResponsiveSidebarShow(true)}>
            <i className="pi pi-bars"></i>{" "}
          </li>
          <li>
            <div style={{ display: "flex" }}>
              <div
                className="moon-light-btn"
                onClick={() => setDarkMode(!darkMode)}
              >
                <i
                  className="pi pi-moon"
                  style={{
                    fontSize: "20px",
                    color: darkMode ? "rgb(255 255 255 / 60%)" : "#565656",
                  }}
                ></i>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => dispatch(dopdownOpen())}
                  className="d-flex gap-3 "
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      objectFit: "contain",
                      maxWidth: "35px",
                      height: "35px",
                      width: "100%",
                      display: "inline-block",
                      verticalAlign: "middle",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <img
                      src="https://bytebiz.fra1.digitaloceanspaces.com/dev-e-comm/user/10141692425516774.jpg"
                      alt=""
                      style={{
                        objectPosition: "50% 50%",
                        // objectFit: "cover",
                        opacity: "1",
                        transition: "opacity 0.28s ease-in",
                        width: "100%",
                        height: "100%",
                        borderRadius: "100%",
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column ">
                    <span
                      style={{
                        fontWeight: "700",
                        color: "#515C66",
                        fontSize: "12px",
                      }}
                    >
                      {localStorage.getItem("loginName") || "admin"}
                    </span>
                    <small
                      style={{
                        color: "#515C66",
                        fontSize: "10px",
                      }}
                    >
                      {GetRole({ role: localStorage.getItem("role") }) ||
                        "admin"}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={headerDropdown ? "overlay-dropdown modal-in-drop" : ""}
              onClick={() => dispatch(dropdownClose())}
            ></div>
            <ul
              className={`${
                headerDropdown ? "inDropdown" : "inDropdown-close"
              }`}
            >
              {/* <li>Profile</li>
              <li>Message</li>
              <li>Setting</li> */}
              <li
                style={{ marginBottom: "3px" }}
                onClick={() => LogoutToWebsite()}
              >
                Logout
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Footer maindivSidebar={maindivSidebar} windowSize={windowSize} />
    </>
  );
};

export default Navbar;
