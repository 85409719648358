import React, { Fragment, useContext, useMemo, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { DELETE_IMAGE, Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import {
  IsLoadingResponse,
  ToastMessage,
  isEmpty,
} from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Name,
  Submit,
  TYPE_FELDS,
  FileType,
  CreateCate,
  UpdateCate,
  NOT_FOUND_IMAGE,
  ParentCategory,
  Post,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createCategorySchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import CategoryContext from "../../../../context/CategoryContext/CategoryContext";
import { Category } from "../../../../constants/index";
import {
  Create_Category,
  Put_Update_Category,
} from "../../../../Apis/categoryApi/categoryApi";
import { InputSwitch } from "primereact/inputswitch";
import { get_Posts } from "../../../../Apis/postsApi/postsApi";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";

const CategoryCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setDialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(CategoryContext);
  const { handleOpenandClosePopup, refetch, emptyData, data } = props;
  const [dialogModal, setdialogsModal] = useState(false);
  /**
   *
   * @param {event } e
   * create category & update category
   */

  const { mutate: createCategory, isLoading: createCategoryLoader } =
    useMutation((formData) => Create_Category(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const { mutate: updateCategory, isLoading: updateCategoryLoader } =
    useMutation((formData) => Put_Update_Category(formData, orgId), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });
  const handleCompanyCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createCategorySchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = isEmpty({
          ...formData,
          catImage: formData?.image
            ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : null,
          pCatId: formData?.pCatId?.code ?? null,
          catId: formData?.catId?.code ?? null,
          postIds: formData.postIds?.length
            ? formData.postIds.map((ele) => {
                return {
                  postId: ele.code,
                };
              })
            : null,
        });
        const { image, ...updatedFormData } = value;
        updateCategory(updatedFormData);
      } else {
        let value = isEmpty({
          ...formData,
          catImage: formData.image
            ? formData.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : null,
          pCatId: formData?.pCatId?.code ?? null,
          postIds: formData.postIds?.length
            ? formData.postIds.map((ele) => {
                return {
                  postId: ele.code,
                };
              })
            : null,
        });
        const { image, ...updatedFormData } = value;
        createCategory(updatedFormData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage, isLoading: imageLoading } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.SYSTEM_IMAGE)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeCategory = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const search = (e) => {};

  /**
   * delete category image
   */
  const { mutate: postsDelete, isLoading: isLoadingDelete } = useMutation(
    (imgData) => DELETE_IMAGE(imgData, refetch)
  );

  const accept = (imgType) => {
    let imgData = {
      fileName: formData[`${imgType}`]
        .split(`${process.env.REACT_APP_FILE_FOLDER}`)
        .pop(),
    };
    setFormData((prev) => {
      return {
        ...prev,
        [`${imgType}`]: "",
      };
    });
    postsDelete(imgData);
  };

  const openImageUploadModal = async (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      setFormData((prev) => {
        return {
          ...prev,
          image: reader.result,
        };
      });
    };
    await uploadImage(e.target.files[0]);
  };

  let paramObj = useMemo(() => {
    return {
      showAll: true,
      search: JSON.stringify({ isActive: true }),
    };
  }, []);

  let { data: PostsDrop } = useQuery(
    ["getPostsDrop", paramObj],
    () => get_Posts(paramObj),
    {
      keepPreviousData: true,
      retry: 3,
    }
  );
  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateCate : UpdateCate}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={
            createCategoryLoader ||
            updateCategoryLoader ||
            isLoadingDelete ||
            imageLoading
          }
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleCompanyCreateAndUpdate(e)}
                >
                  <Row>
                    <div className="field mb-4 col-12 md:col-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          marginBottom: "15px",
                        }}
                      >
                        <div
                          style={{
                            height: "150px",
                            width: "185px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              formData?.image ? formData.image : NOT_FOUND_IMAGE
                            }
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {!formData?.image && (
                          <input
                            className="custom-file-input"
                            type="file"
                            onChange={(e) => openImageUploadModal(e)}
                          />
                        )}

                        <Button
                          className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                          style={{ height: "40px" }}
                          type="button"
                          onClick={() => {
                            if (formData.image) {
                              SweetConfirmation(accept, "image");
                            }
                          }}
                        >
                          <span
                            className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                          ></span>
                          <span
                            className="p-button-label p-c"
                            style={{ fontWeight: "100" }}
                          >
                            {"Choose"}
                          </span>
                        </Button>
                      </div>
                    </div>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={`${Category} ${Name}`}
                        name="catName"
                        id="catName"
                        type={TYPE_FELDS.text}
                        placeholder={`${Category} ${Name}`}
                        value={formData.catName}
                        handleChange={handleChangeCategory}
                        isShowValidate={errors.catName}
                      />
                      {errors.catName && (
                        <span className="error-validation">
                          {errors.catName}
                        </span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={`Select ${Post}`}
                        name="postIds"
                        id="postIds"
                        placeholder={`Select ${Post}`}
                        value={formData.postIds}
                        handleChange={handleChangeCategory}
                        dropdown={true}
                        multiOption={
                          PostsDrop?.payload?.data?.length
                            ? PostsDrop?.payload?.data.map((elem) => {
                                return {
                                  code: elem.postId,
                                  name: elem.title,
                                };
                              })
                            : []
                        }
                        search={search}
                        multiDropdown={true}
                        isValid={false}
                      />
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={`Date`}
                        name="dateTitle"
                        id="dateTitle"
                        type={TYPE_FELDS.date}
                        placeholder={`dateTitle`}
                        value={formData.dateTitle}
                        handleChange={handleChangeCategory}
                        isShowValidate={errors.dateTitle}
                      />
                      {errors.dateTitle && (
                        <span className="error-validation">
                          {errors.dateTitle}
                        </span>
                      )}
                    </Col>
                    {data.payload.data.length > 0 && (
                      <Col md="6 mb-4">
                        <FormDataFeild
                          label={`${ParentCategory}`}
                          name="pCatId"
                          id="pCatId"
                          placeholder={`${ParentCategory}`}
                          value={formData.pCatId}
                          handleChange={handleChangeCategory}
                          dropdown={true}
                          option={
                            data?.payload?.data?.length
                              ? data?.payload?.data.map((elem) => {
                                  return {
                                    code: elem.catId,
                                    name: elem.catName,
                                  };
                                })
                              : []
                          }
                          search={search}
                          isValid={false}
                        />
                      </Col>
                    )}

                    <Col md="1 mb-4">
                      <div className="flex flex-column gap-2 padding w-100 ">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {"Same Day"}
                        </label>
                        <InputSwitch
                          checked={formData.isSameDay}
                          onChange={(e) => handleChangeCategory(e)}
                          name="isSameDay"
                          id="isSameDay"
                        />
                      </div>
                    </Col>
                    <Col md="1 mb-4">
                      <div className="flex flex-column gap-2 padding w-100 ">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {"Yearly"}
                        </label>
                        <InputSwitch
                          checked={formData.isYearly}
                          onChange={(e) => handleChangeCategory(e)}
                          name="isYearly"
                          id="isYearly"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={createCategoryLoader || updateCategoryLoader}
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default CategoryCreateFormPopup;
