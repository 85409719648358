import axios from "axios";
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  ENABLE_DISABLE_CATEGORY,
  GET_CATEGORY,
  GET_CATEGORY_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { get_Posts } from "../postsApi/postsApi";

/**
 * get company list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const get_Category = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_CATEGORY}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add company api call
 * @param {form data} formData
 * @returns
 */

export const Create_Category = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_CATEGORY}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable company api call
 * @param {form data as isActive & catId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Category = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_CATEGORY}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { catId: formData.catId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const Get_Details_Category = (catId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_CATEGORY_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { catId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let paramObj = {
        showAll: true,
      };
      const { payload } = await get_Category(paramObj);
      let parentCat = payload.data.find((elem) => elem.catId === data.pCatId);

      const { payload: getPostData } = await get_Posts(paramObj);
      let postDataIds = data?.postsCatMaps
        .map((ele) => {
          const matchedElement = getPostData?.data.find(
            (firstElem) => firstElem.postId === ele.postId
          );
          if (matchedElement) {
            return {
              code: matchedElement.postId,
              name: matchedElement.title,
            };
          }
        })
        .filter(Boolean);

      setFormData({
        pCatId: data?.pCatId
          ? { code: parentCat.catId, name: parentCat.catName }
          : null,
        catName: data?.catName ?? null,
        catImage: data?.catImage ?? null,
        image: data?.catImage ?? null,
        isYearly: data?.isYearly ?? null,
        dateTitle: data?.dateTitle ?? null,
        isSameDay: data?.isSameDay ?? null,
        postIds: postDataIds ? postDataIds : [],
      });
      setdialogs(true);
    });
};

/**
 * update company api call
 * @param {form data} formData
 * @param {*} catId
 * @returns
 */

export const Put_Update_Category = (formData, catId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_CATEGORY}`, formData, {
      headers: { Authorization: jwt_token },
      params: { catId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete company api call
 * @param {form data} formData
 * @param {*} catId
 * @param {list api call } refetch
 * @returns
 */

export const DELETE_Company = (catId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_CATEGORY}`, {
      headers: { Authorization: jwt_token },
      params: { catId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Get_Details__Company = (paramObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_CATEGORY_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: paramObj,
    })
    .then(async (res) => {
      return res.data.payload.data;
    });
};
