import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  COMPANY,
  COMPANY_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import CategoryContext from "../../../../context/CategoryContext/CategoryContext";
import { get_Category } from "../../../../Apis/categoryApi/categoryApi";
import CategoryDataTableContainer from "./CategoryDataTableContainer";
import CategoryCreateFormPopup from "./CategoryCreateFormPopup";

const Category = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //company edit condition state and data object state
    setDialogs,
    setErrors,
    dialog,
    setIsEdit,
    setFormData,
    setFilterDrop,
  } = useContext(CategoryContext);

  const history = useNavigate();

  /**
   * get category List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { catName: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["get_Category", paramsObj],
    () => get_Category(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    }
  );
  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const emptyData = () => {
    setFormData({
      pCatId: null,
      catName: null,
      thumbImage: null,
      catImage: null,
      isYearly: null,
      dateTitle: null,
      isSameDay: null,
      isActive: null,
    });
    setFilterDrop((prev) => {
      return { ...prev, storeFilter: "" };
    });
  };

  /**
   * Open Popup and Close Popup category
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setDialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setDialogs(condition);
      emptyData();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={COMPANY} slace={SLACE} name1={COMPANY_LIST} />
      <CategoryDataTableContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      {dialog && (
        <CategoryCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
          data={data}
        />
      )}
    </>
  );
};

export default Category;
