import axios from "axios";
import {
  CREATE_HASH_TAG,
  DELETE_HASH_TAG,
  EDIT_HASH_TAG,
  ENABLE_DISABLE_HASH_TAG,
  GET_HASH_TAG,
  GET_HASH_TAG_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { get_Company } from "../companyApi/CompanyApi";

/**
 * get Hash Tag list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const getHashTag = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_HASH_TAG}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add Hash Tag api call
 * @param {form data} formData
 * @returns
 */

export const createHashTag = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_HASH_TAG}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable Hash Tag api call
 * @param {form data as isActive & hashTagId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const enableDisableHashTag = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_HASH_TAG}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { hashTagId: formData.hashTagId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const getDetailsHashTag = (hashTagId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_HASH_TAG_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { hashTagId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let paramObj = {
        showAll: true,
      };
      const { payload } = await get_Company(paramObj);
      let company = payload.data.find(
        (elem) => elem.companyId === data.companyId
      );
      setFormData({
        hashTag: data?.hashTag ?? null,
        isForX: data?.isForX ?? false,
        companyId: { code: company?.companyId, name: company?.name } ?? null,
      });
      setdialogs(true);
    });
};

/**
 * update Hash Tag api call
 * @param {form data} formData
 * @param {*} hashTagId
 * @returns
 */

export const putUpdateHashTag = (formData, hashTagId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_HASH_TAG}`, formData, {
      headers: { Authorization: jwt_token },
      params: { hashTagId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete Hash Tag api call
 * @param {form data} formData
 * @param {*} hashTagId
 * @param {list api call } refetch
 * @returns
 */

export const deleteHashTag = (hashTagId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_HASH_TAG}`, {
      headers: { Authorization: jwt_token },
      params: { hashTagId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
