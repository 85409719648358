import axios from "axios";
import {
  CREATE_ORG,
  DELETE_ORG,
  EDIT_ORG,
  ENABLE_DISABLE_ORG,
  GET_ORG,
  GET_ORG_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get organization list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const getOrganization = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ORG}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add organization api call
 * @param {form data} formData
 * @returns
 */

export const createOrganization = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_ORG}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable organization api call
 * @param {form data as isActive & orgId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const enableDisableOrganization = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_ORG}`, formData, {
      headers: { Authorization: jwt_token },
      params: { orgId: formData.orgId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const getDetailsOrganization = (
  orgId,
  setFormData = null,
  setdialogs = null
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ORG_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { orgId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      if (setFormData !== null) {
        setFormData({
          orgName: data?.orgName ?? null,
          companyLimit: data?.companyLimit ?? null,
          userLimit: data.userLimit ?? null,
          isPremium: data.isPremium ?? false,
          hwId: data?.hwId ?? null,
          qrOrgId: data?.qrOrgId ?? null,
        });
      }
      if (setdialogs !== null) {
        setdialogs(true);
      }
      return res.data.payload.data;
    });
};

/**
 * update organization api call
 * @param {form data} formData
 * @param {*} orgId
 * @returns
 */

export const putUpdateOrganization = (formData, orgId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_ORG}`, formData, {
      headers: { Authorization: jwt_token },
      params: { orgId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete organization api call
 * @param {form data} formData
 * @param {*} orgId
 * @param {list api call } refetch
 * @returns
 */

export const deleteOrganization = (orgId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_ORG}`, {
      headers: { Authorization: jwt_token },
      params: { orgId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
