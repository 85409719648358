import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import CategoryContext from "../../../../context/CategoryContext/CategoryContext";
import {
  Enable_Disable_Category,
  Get_Details_Category,
  DELETE_Company,
} from "../../../../Apis/categoryApi/categoryApi";
import Actions from "../../../../layouts/Actions";

const CategoryDataTableContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setDialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(CategoryContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get category Details
   */
  const { mutate: categoryDetails, isLoading: isLoadingCategoryDetails } =
    useMutation((catId) =>
      Get_Details_Category(catId, setFormData, setDialogs)
    );

  const GetCategoryDetails = (catId) => {
    categoryDetails(catId);
    setOrgId(catId);
    setIsEdit(false);
  };

  /**
   * delete category
   */
  const { mutate: categoryDelete, isLoading: isLoadingDelete } = useMutation(
    (catId) => DELETE_Company(catId, refetch)
  );

  const DeleteCategory = async (catId) => {
    SweetConfirmation(categoryDelete, catId);
  };

  /**
   * enable / disable category
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Category(formData, refetch)
  );

  const handleCompanyStatusChange = (catId, status) => {
    let formDate = {
      catId: catId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.catId === catId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 120,
      field: "catImage",
      headerName: "Image",
      renderCell: (params) => (
        // params.row.logo ? params.row.logo : "--"
        <div className="flex justify-content-center ">
          <img
            className="img-thumbnail"
            src={params.row.catImage}
            alt=""
            width={100}
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "catName",
      headerName: "Category Name",
      renderCell: (params) => (params.row.catName ? params.row.catName : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "dateTitle",
      headerName: "Date",
      renderCell: (params) =>
        params.row.dateTitle ? params.row.dateTitle : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "isSameDay",
      headerName: "Same Day",
      renderCell: (params) =>
        params.row.isSameDay ? (
          <span style={{ color: "green", fontWeight: "600" }}>Yes</span>
        ) : (
          <span style={{ color: "red", fontWeight: "600" }}>No</span>
        ),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "isYearly",
      headerName: "Yearly",
      renderCell: (params) =>
        params.row.isYearly ? (
          <span style={{ color: "green", fontWeight: "600" }}>Yes</span>
        ) : (
          <span style={{ color: "red", fontWeight: "600" }}>No</span>
        ),
    },

    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleCompanyStatusChange(params.row.catId, params.row.isActive)
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <Actions
            id={params.row.catId}
            getDetails={GetCategoryDetails}
            deleteDetails={DeleteCategory}
          />
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingCategoryDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.catId}
        addButton={true}
      />
    </Fragment>
  );
};

export default CategoryDataTableContainer;
