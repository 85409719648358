import React, { Fragment, useContext, useMemo, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Submit,
  TYPE_FELDS,
  FileType,
  Company,
  hasTag,
  CreateHasTag,
  UpdateHasTag,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createHashTagSchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import { get_Company } from "../../../../Apis/companyApi/CompanyApi";
import HashTagContext from "../../../../context/HashTagContext/HashTagContext";
import {
  createHashTag,
  putUpdateHashTag,
} from "../../../../Apis/hashTagApi/HashTagApi";
import { InputSwitch } from "primereact/inputswitch";

const HashTagCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setdialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(HashTagContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);

  /**
   *
   * @param {event } e
   * create HashTag & update HashTag
   */

  const { mutate: createHashTag_, isLoading: createHashTagLoader } =
    useMutation((formData) => createHashTag(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const { mutate: updateHashTag, isLoading: updateHashTagLoader } = useMutation(
    (formData) => putUpdateHashTag(formData, orgId),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const handleHashTagCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createHashTagSchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
          companyId: formData?.companyId?.code,
        };
        updateHashTag(value);
      } else {
        let value = {
          ...formData,
          companyId: formData?.companyId?.code,
        };
        createHashTag_(value);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.USER)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeHashTag = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const search = (e) => {};

  let paramObj = useMemo(() => {
    return {
      showAll: true,
      search: JSON.stringify({ isActive: true }),
    };
  }, []);

  let { data: companyDrop } = useQuery(
    ["getCompanyDrop", paramObj],
    () => get_Company(paramObj),
    {
      keepPreviousData: true,
      retry: 3,
    }
  );

  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateHasTag : UpdateHasTag}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={createHashTagLoader || updateHashTagLoader}
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleHashTagCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="5 mb-4">
                      <FormDataFeild
                        label={hasTag}
                        name="hashTag"
                        id="hashTag"
                        type={TYPE_FELDS.text}
                        placeholder={hasTag}
                        value={formData.hashTag}
                        handleChange={handleChangeHashTag}
                        isShowValidate={errors.hashTag}
                      />
                      {errors.hashTag && (
                        <span className="error-validation">
                          {errors.hashTag}
                        </span>
                      )}
                    </Col>
                    <Col md="5 mb-4">
                      <FormDataFeild
                        label={Company}
                        name="companyId"
                        id="companyId"
                        placeholder={Company}
                        value={formData.companyId}
                        handleChange={handleChangeHashTag}
                        dropdown={true}
                        option={
                          companyDrop?.payload?.data?.length
                            ? companyDrop?.payload?.data.map((elem) => {
                                return {
                                  code: elem.companyId,
                                  name: elem.name,
                                };
                              })
                            : []
                        }
                        isShowValidate={errors.companyId}
                        search={search}
                      />
                      {errors.companyId && (
                        <span className="error-validation">
                          {errors.companyId}
                        </span>
                      )}
                    </Col>
                    <Col md="2 mb-4">
                      <div className="flex flex-column gap-2 padding w-100 ">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {"For X"}
                        </label>
                        <InputSwitch
                          checked={formData.isForX}
                          onChange={(e) => handleChangeHashTag(e)}
                          name="isForX"
                          id="isForX"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={createHashTagLoader || updateHashTagLoader}
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default HashTagCreateFormPopup;
