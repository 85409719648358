import ForgotPassword from "../Auth/ForgotPassword";
import Login from "../Auth/Login";
import ResetPassword from "../Auth/ResetPassword";
import ErrorPage from "../components/ErrorPage";
import CreatePost from "../components/pages/Super_Admin/Canva/CreatePost";
import Category from "../components/pages/Super_Admin/Category/Category";
import Company from "../components/pages/Super_Admin/Company/Company";
import Dashboard from "../components/pages/Super_Admin/Dashboard/Dashboard";
import FrameHeader from "../components/pages/Super_Admin/FrameHeader/FrameHeader";
import HashTag from "../components/pages/Super_Admin/HashTag/HashTag";
import Language from "../components/pages/Super_Admin/Language/Language";
import Organization from "../components/pages/Super_Admin/Organization/Organization";
import PostBackground from "../components/pages/Super_Admin/PostBackground/PostBackground";
import Posts from "../components/pages/Super_Admin/Posts/Posts";
import Religion from "../components/pages/Super_Admin/Religion/Religion";
import ThemeSetting from "../components/pages/Super_Admin/ThemeSetting/ThemeSetting";
import User from "../components/pages/Super_Admin/User/User";
import UserSelectedPoster from "../components/pages/Super_Admin/UserSelectedPost/UserSelectedPost";
import { Role } from "../constants";
import HighOrder from "./HighOrder";

export const REDIRECT_PATH = {
  DASHBOARD: "/",
  USER: "/user",
  COMPANY: "/company",
  ORGANIZATION: "/Organization",
  HAS_TAG: "/hastag",
  RELIGION: "/religion",
  LANGUAGE: "/language",
  CATEGORY: "/category",
  POSTS: "/posts",
  SELECTED_POSTS: "/selected-posts",
  POSTS_BACKGROUND: "/post-background",
  FRAME_HEADER: "/frame-header",
  MACK_POST: "/mack-post",
  THEME_SETTING: "/theme-setting",
  AUTH: "/auth",
  FORGOT_PASSWORD: "/auth/forgotpassword",
  RESET_PASSWORD: "/auth/resetpassword",
};

export const SuperAdminAccess = [
  {
    path: REDIRECT_PATH.DASHBOARD,
    component: <Dashboard />,
  },
  {
    path: REDIRECT_PATH.USER,
    component: <User />,
  },
  {
    path: REDIRECT_PATH.COMPANY,
    component: <Company />,
  },
  {
    path: REDIRECT_PATH.ORGANIZATION,
    component: (
      <HighOrder
        Compo={<Organization />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.HAS_TAG,
    component: (
      <HighOrder
        Compo={<HashTag />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.RELIGION,
    component: (
      <HighOrder
        Compo={<Religion />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.LANGUAGE,
    component: (
      <HighOrder
        Compo={<Language />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.POSTS,
    component: (
      <HighOrder
        Compo={<Posts />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.CATEGORY,
    component: (
      <HighOrder
        Compo={<Category />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.SELECTED_POSTS,
    component: (
      <HighOrder
        Compo={<UserSelectedPoster />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.POSTS_BACKGROUND,
    component: (
      <HighOrder
        Compo={<PostBackground />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  { path: REDIRECT_PATH.FRAME_HEADER, component: <FrameHeader /> },
  { path: REDIRECT_PATH.MACK_POST, component: <CreatePost /> },
  { path: REDIRECT_PATH.THEME_SETTING, component: <ThemeSetting /> },
];

export const StoreAdminAccess = [
  {
    path: REDIRECT_PATH.DASHBOARD,
    component: <Dashboard />,
  },
  {
    path: REDIRECT_PATH.USER,
    component: <User />,
  },
  {
    path: REDIRECT_PATH.COMPANY,
    component: <Company />,
  },
  {
    path: REDIRECT_PATH.ORGANIZATION,
    component: (
      <HighOrder
        Compo={<Organization />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.HAS_TAG,
    component: (
      <HighOrder
        Compo={<HashTag />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.RELIGION,
    component: (
      <HighOrder
        Compo={<Religion />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.LANGUAGE,
    component: (
      <HighOrder
        Compo={<Language />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.POSTS,
    component: (
      <HighOrder
        Compo={<Posts />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.CATEGORY,
    component: (
      <HighOrder
        Compo={<Category />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.SELECTED_POSTS,
    component: (
      <HighOrder
        Compo={<UserSelectedPoster />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  {
    path: REDIRECT_PATH.POSTS_BACKGROUND,
    component: (
      <HighOrder
        Compo={<PostBackground />}
        access={[Role.SuperAdmin]}
        navigate={REDIRECT_PATH.DASHBOARD}
      />
    ),
  },
  { path: REDIRECT_PATH.FRAME_HEADER, component: <FrameHeader /> },
  { path: REDIRECT_PATH.MACK_POST, component: <CreatePost /> },
  { path: REDIRECT_PATH.THEME_SETTING, component: <ThemeSetting /> },
];

export const PublicAccess = [
  {
    path: REDIRECT_PATH.AUTH,
    component: <Login />,
  },
  {
    path: REDIRECT_PATH.FORGOT_PASSWORD,
    component: <ForgotPassword />,
  },
  {
    path: REDIRECT_PATH.RESET_PASSWORD,
    component: <ResetPassword />,
  },
  {
    path: "*",
    component: <ErrorPage />,
  },
];
