import React from "react";
import { SplitButton } from "primereact/splitbutton";

const Actions = ({ id, getDetails, deleteDetails, removeDelete }) => {
  const items = [
    {
      label: "Update",
      icon: "pi pi-refresh",
      command: () => {
        getDetails(id);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        deleteDetails(id);
      },
    },
  ];

  if (removeDelete === false) {
    delete items[1];
  }

  return (
    <SplitButton
      model={items}
      className="custom-splitbutton small-tooltip"
      icon="pi pi-bars"
      text
      tooltip="Actions"
      tooltipOptions={{
        position: "bottom",
      }}
    />
  );
};

export default Actions;
