import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Download_Post, get_Posts } from "../../../../Apis/postsApi/postsApi";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  DASHBOARD,
  HOME,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { get_Company } from "../../../../Apis/companyApi/CompanyApi";
import {
  IsLoadingResponse,
  ToastMessage,
  handleInfiniteScroll,
} from "../../../../utils/Helper";
import { DROPDOWN_CLASS } from "../../../../constants";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Col, Row } from "reactstrap";
import moment from "moment";

const Dashboard = () => {
  const [companyDrop, setCompanyDrop] = useState({
    data: [],
    totalRecords: "",
    pageNumber: 1,
    nameFilter: "",
  });
  const [companyId, setCompanyId] = useState("");
  const [postData, setPostData] = useState([]);

  let paramObj = useMemo(() => {
    let pramObj = {
      pageNumber: companyDrop.pageNumber,
      search: { isActive: true },
    };
    if (companyDrop.nameFilter) {
      pramObj["search"]["name"] = companyDrop.nameFilter;
    }
    return {
      ...pramObj,
      search: JSON.stringify({ ...pramObj.search }),
    };
  }, [companyDrop.pageNumber, companyDrop.nameFilter]);

  useQuery(["getCompanyDrop", paramObj], () => get_Company(paramObj), {
    keepPreviousData: true,
    retry: 3,
    onSuccess: (response) => {
      if (response.status === 200) {
        setCompanyId(response.payload.data[0]?.companyId);
        setCompanyDrop((prev) => {
          return {
            ...prev,
            data: [
              ...prev.data,
              ...response.payload.data
                .filter(
                  (elem) =>
                    !prev.data.some(
                      (prevElem) => prevElem.value === elem.companyId
                    )
                )
                .map((elem) => {
                  return {
                    name: `${elem.name}`,
                    value: elem.companyId,
                  };
                }),
            ],
            totalRecords: response.pager.totalRecords,
          };
        });
      }
    },
  });
  let paramsObj = useMemo(() => {
    let params = {
      isDashboard: true,
      search: JSON.stringify({ isActive: true }),
    };
    if (companyId) {
      params["search"] = JSON.stringify({
        ...JSON.parse(params.search),
        companyId,
      });
    }
    return params;
  }, [companyId]);

  useQuery(["get_Posts_dashboard", paramsObj], () => get_Posts(paramsObj), {
    keepPreviousData: true,
    refetchOnReconnect: false,
    retry: 3,
    onSuccess: (data) => {
      if (data.status === 200) {
        setPostData(
          data?.payload?.data?.map((elem) => {
            return {
              id: elem.postId,
              ...elem,
            };
          })
        );
      }
    },
  });

  const headerTemplate = (data) => {
    return (
      <div className="flex align-items-center gap-2">
        <span className="font-bold">
          {moment(data.postDate).format("DD MMM, YY")}
        </span>
      </div>
    );
  };

  /**
   *
   * @param {event } e
   * create organization & update organization
   */

  const toDataURL = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to data URL:", error);
      throw error;
    }
  };

  const { mutate: postDownload, isLoading: downloadPostLoader } = useMutation(
    (formData) => Download_Post(formData),
    {
      onSuccess: async ({ data }, formData) => {
        if (data.status === 200) {
          const dataUrl = await toDataURL(data.payload.data);
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = `${formData.title}.png`; // Set the desired filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setCompanyId(formData.companyId);
          ToastMessage(data.status, "Post Image Downloaded Successfully!");
        }
      },
    }
  );

  const handleDownloadPost = ({ postId, title }) => {
    let formData = {
      companyId,
      postId,
      title,
    };
    postDownload(formData);
  };

  return (
    <>
      <Breadcrumb name={HOME} slace={SLACE} name1={DASHBOARD} />
      <IsLoadingResponse isLoading={downloadPostLoader} />
      <div className="tabel-contant">
        <div className={`tabelContent`}>
          <div className="p-4">
            <Row>
              <Col md={"3 mb-3"}>
                <FormDataFeild
                  label={""}
                  name="companyId"
                  id="companyId"
                  placeholder={"Company"}
                  value={companyId}
                  handleChange={(e) => setCompanyId(e.target.value)}
                  dropdown={true}
                  option={companyDrop.data}
                  search={(filter) => {
                    setCompanyDrop((prev) => {
                      return {
                        ...prev,
                        nameFilter: filter,
                      };
                    });
                  }}
                  handleScroll={() =>
                    handleInfiniteScroll({
                      cls: DROPDOWN_CLASS.SINGLE_DROP,
                      length: companyDrop.data.length,
                      setNextPage: setCompanyDrop,
                      totalR: companyDrop.totalRecords,
                    })
                  }
                  isValid={false}
                />
              </Col>
            </Row>
            {postData?.length > 0 ? (
              <DataTable
                value={postData}
                rowGroupMode="subheader"
                groupRowsBy="postDate"
                sortMode="single"
                sortField="postDate"
                sortOrder={1}
                scrollable
                scrollHeight="400px"
                rowGroupHeaderTemplate={headerTemplate}
              >
                <Column
                  field="thumbImage"
                  header="Image"
                  body={(data) => (
                    <Image
                      src={data.thumbImage}
                      alt="Image"
                      width={60}
                      preview
                    />
                  )}
                ></Column>
                <Column field="title" header="Title"></Column>
                <Column
                  field="actions"
                  header="Actions"
                  body={(data) => (
                    <i
                      className="pi pi-download"
                      onClick={() =>
                        handleDownloadPost({
                          postId: data.postId,
                          title: data.title,
                        })
                      }
                    ></i>
                  )}
                ></Column>
              </DataTable>
            ) : (
              <div className="d-flex justify-content-center ">
                <img src="/not-found.webp" width={"20%"} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
