/**Post create all reducers */

export const initialPostCreateState = {
  postDataModal: false,
  selectedPost: null,
};

export const CreatePostReducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return { ...state, postDataModal: true };
    case "CLOSE_MODAL":
      return { ...initialPostCreateState };
    case "SELECT_POST":
      return {
        ...state,
        selectedPost: action.postId.value,
        postDataModal: false,
      };
    case "CLEAR_SELECTED_POST":
      return {
        ...state,
        selectedPost: null,
        postDataModal: false,
      };
    default:
      return state;
      break;
  }
};
