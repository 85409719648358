import { confirmDialog } from "primereact/confirmdialog";

const SweetConfirmation = (deleteApi, id) => {
  confirmDialog({
    message: "Do you want to delete this record?",
    header: "Delete Confirmation",
    icon: "pi pi-info-circle",
    defaultFocus: "reject",
    acceptClassName: "p-button-danger confirm-dialog-accept",
    rejectClassName: "confirm-dialog-accept p-button-text",
    accept: () => {
      deleteApi(id);
    },
    reject: () => {},
    closable: false,
    resizable: false,
    draggable: false,
  });
};

export default SweetConfirmation;
