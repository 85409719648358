import React, { useRef, useEffect, useState, useReducer, useMemo } from "react";
import { fabric } from "fabric";
import html2canvas from "html2canvas";
import {
  POSTS_TITLE,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  CreatePostReducer,
  initialPostCreateState,
} from "../../../../hooks/Reducer";
import PostDataTablePopup from "./PostDataTablePopup";
import { InputText } from "primereact/inputtext";
import { useQuery } from "react-query";
import { getFrameHeader } from "../../../../Apis/frameHeaderApi/FrameHeaderApi";
import Slider from "react-slick";
import DynamicHTMLContent from "./DynamicHTMLContent";
import { ChromePicker } from "react-color";
import { Tooltip } from "primereact/tooltip";
import { addCdn } from "../../../../utils/Helper";

const CreatePost = () => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const fabricCanvasRef = useRef(null);
  const [state, dispatch] = useReducer(
    CreatePostReducer,
    initialPostCreateState
  );
  const [images, setImages] = useState([]);
  const [selectedColor, setSelectedColor] = useState({
    a: "1",
    b: "0",
    g: "0",
    r: "0",
  }); // Default color
  const [openColor, setOpenColor] = useState(false);

  // Handle color change
  const handleColorChange = (color) => {
    setSelectedColor(color.rgb);
  };

  // State to manage canvas dimensions
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 516,
    height: 516,
  });

  let paramsObj = useMemo(() => {
    let params = {
      showAll: true,
      search: JSON.stringify({ isActive: true }),
    };
    return params;
  }, []);
  let { data: frameHeaderList } = useQuery(
    ["get_Frame", paramsObj],
    () => getFrameHeader(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current, {
      width: canvasDimensions.width,
      height: canvasDimensions.height,
      backgroundColor: "white",
    });
    fabricCanvasRef.current = canvas;

    const setBackgroundImage = (url, canvas, removeBackground = false) => {
      // Use CORS Anywhere to prepend the image URL
      // const corsAnywhereUrl = "https://cors-anywhere.herokuapp.com/";
      // const proxiedUrl = corsAnywhereUrl + url;
      if (removeBackground) {
        canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
      } else {
        const newUrl = addCdn(url);
        fabric.Image.fromURL(
          // newUrl,
          url,
          (img) => {
            // Calculate the scale for the image
            const imgAspectRatio = img.width / img.height;
            const canvasAspectRatio = canvas.width / canvas.height;
            let scale;
            if (canvasAspectRatio >= imgAspectRatio) {
              scale = canvas.height / img.height;
            } else {
              scale = canvas.width / img.width;
            }
            img.scaleX = scale;
            img.scaleY = scale;
            img.set({
              left: canvas.width / 2,
              top: canvas.height / 2,
              originX: "center",
              originY: "center",
            });

            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
              scaleX: img.scaleX,
              scaleY: img.scaleY,
              originX: "center",
              originY: "center",
              crossOrigin: "anonymous",
            });
          },
          { crossOrigin: "anonymous" } // This ensures CORS headers are respected
        );
      }
    };

    // Replace with actual condition to check for a selected image
    if (state?.selectedPost?.image) {
      setBackgroundImage(state.selectedPost.image, canvas);
    } else {
      setBackgroundImage(state?.selectedPost?.image, canvas, true);
    }

    if (images?.length) {
      // Foreground images
      images.forEach((elem) => {
        fabric.Image.fromURL(
          elem.url,
          (img) => {
            // img.scaleToWidth(canvas.width / 4); // Example scale
            // img.scaleToHeight(canvas.height / 4); // Example scale
            img.set({
              left: elem.left,
              top: elem.top,
              scaleX: elem.width / img.width,
              scaleY: elem.height / img.height,
              originX: "left", // Depending on your design, might adjust these
              originY: "top",
              angle: elem.angle || 0,
            }); // Set position or default to (50, 50)
            img.imageId = elem.id;
            canvas.add(img);
            img.on("modified", function () {
              const updatedImages = images.map((image) => {
                if (image.id === img.imageId) {
                  // Match the ID
                  return {
                    ...image,
                    left: img.left,
                    top: img.top,
                    width: img.getScaledWidth(),
                    height: img.getScaledHeight(),
                    angle: img.angle,
                  }; // Update position
                }
                return image;
              });
              setImages(updatedImages); // Update the state with new positions
            });
          },
          null,
          { crossOrigin: "anonymous" }
        );
      });
    }

    return () => {
      canvas.dispose();
    };
  }, [state, canvasDimensions.width, canvasDimensions.height, images]);

  const handleSave = () => {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      fabricCanvas.discardActiveObject();
      fabricCanvas.requestRenderAll(); // Ensure canvas is updated before capture

      setTimeout(() => {
        html2canvas(containerRef.current, { useCORS: true }).then((canvas) => {
          const downloadCanvas = document.createElement("canvas");
          downloadCanvas.width = canvasDimensions.width;
          downloadCanvas.height = canvasDimensions.height;
          const ctx = downloadCanvas.getContext("2d");

          ctx.drawImage(
            canvas,
            0,
            0,
            canvasDimensions.width,
            canvasDimensions.height
          );

          // Create a data URL for the canvas
          const dataUrl = downloadCanvas.toDataURL("image/png");

          // Create a link for downloading
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "complete-canvas-image.png"; // Set the desired filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }, 500);
    }
  };

  const FIXED_SIZE = 300; // Fixed size for the largest dimension

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          let displayWidth, displayHeight;

          // Determine display dimensions based on orientation
          if (img.width > img.height) {
            // Horizontal image
            displayWidth = FIXED_SIZE;
            displayHeight = Math.round((img.height / img.width) * FIXED_SIZE);
          } else if (img.width < img.height) {
            // Vertical image
            displayHeight = FIXED_SIZE;
            displayWidth = Math.round((img.width / img.height) * FIXED_SIZE);
          } else {
            // Square image
            displayWidth = FIXED_SIZE;
            displayHeight = FIXED_SIZE;
          }

          // Create new image object with actual dimensions and scaled display dimensions
          const newImage = {
            id: Date.now(),
            url: e.target.result,
            left: 50, // Default position
            top: 50, // Default position
            width: displayWidth,
            height: displayHeight,
          };
          setImages((prev) => [...prev, newImage]);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteSelectedObject = () => {
    const canvas = fabricCanvasRef.current;
    if (!canvas) return;

    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      const selectedImageUrl = activeObject.getSrc();
      const selectedIndex = images.findIndex(
        (image) => image.url === selectedImageUrl
      );

      if (selectedIndex !== -1) {
        // Remove the image from the images array
        setImages((prev) => {
          return prev.filter((elem, i) => i !== selectedIndex);
        });
      }

      canvas.remove(activeObject);
      canvas.requestRenderAll(); // Refresh the canvas to reflect the removal
    }
  };

  const startContent = (
    <React.Fragment>
      <Button
        icon="pi pi-images"
        className="mr-2"
        onClick={() => dispatch({ type: "OPEN_MODAL" })}
        tooltip="Select Image"
      />
      <div className="position-relative ">
        <Button icon="pi pi-plus" className="mr-2" />
        <InputText
          type="file"
          onChange={handleImageUpload}
          accept="image/*"
          tooltip="Add Image"
          className="position-absolute opacity-0 "
          style={{ left: "0%", width: "48px", height: "43px" }}
        />
      </div>
      <div style={{ position: "relative" }} className="mr-2">
        <Tooltip target=".color-show" />
        <div
          style={{
            height: "42px",
            width: "48px",
            background: `rgba(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b}, ${selectedColor.a})`,
            borderRadius: "6px",
            border: "1px solid #d1d5db",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
          data-pr-tooltip="Color Picker"
          className="color-show"
          onClick={() => setOpenColor((prev) => !prev)}
        >
          <i className="pi pi-palette"></i>
        </div>
        {openColor && (
          <div style={{ position: "absolute", top: "115%", zIndex: 1000 }}>
            <ChromePicker
              color={selectedColor}
              onChange={handleColorChange}
              disableAlpha={false} // Enable alpha channel (transparency)
            />
          </div>
        )}
      </div>
      <Button
        icon="pi pi-trash"
        className="mr-2"
        onClick={handleDeleteSelectedObject}
        tooltip="Delete Selected Image"
        severity="danger"
      />
      <Button
        icon="pi pi-eraser"
        className="mr-2"
        onClick={() => dispatch({ type: "CLEAR_SELECTED_POST" })}
        tooltip="Delete Background Image"
        severity="danger"
      />
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <Button
        icon="pi pi-cloud-download"
        className="mr-2"
        label="Download"
        onClick={() => handleSave()}
      />
    </React.Fragment>
  );

  return (
    <>
      <Breadcrumb name={POSTS_TITLE} slace={SLACE} name1={"Make Post"} />
      {openColor && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            background: "#ffffff00",
            top: 0,
            left: 0,
            zIndex: 999,
          }}
          onClick={() => setOpenColor(false)}
        ></div>
      )}
      <div className="tabel-contant">
        <div className={`tabelContent`}>
          <Toolbar start={startContent} end={endContent} />
          <div
            className="container p-0 "
            ref={containerRef}
            style={{
              width: `${canvasDimensions.width + 4}px`,
              maxWidth: "100%",
              position: "relative",
              border: `2px solid rgba(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b}, ${selectedColor.a})`,
            }}
          >
            <canvas
              ref={canvasRef}
              style={{ width: "100%", height: "100%" }}
            ></canvas>

            {/* <div
              style={{ position: "absolute", bottom: "0", width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: frameHeaderList?.payload?.data?.length
                  ? frameHeaderList?.payload?.data[0]?.html
                  : "",
              }}
            /> */}
            <div
              style={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                zIndex: 99,
              }}
            >
              <Slider {...settings}>
                {frameHeaderList?.payload?.data?.map((elem, i) => {
                  const dynamicValues = {
                    phone: `+91 8140504405 (${i + 1})`,
                    email: `johndoe@example.com (${i + 1})`,
                    website: `https://multicodesolution.com (${i + 1})`,
                    background: `rgba(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b}, ${selectedColor.a})`,
                  };
                  return (
                    <DynamicHTMLContent
                      dynamicValues={dynamicValues}
                      htmlContent={elem?.html}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <PostDataTablePopup state={state} dispatch={dispatch} />
    </>
  );
};

export default CreatePost;
