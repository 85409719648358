import React from "react";
import Zoom from "@mui/material/Zoom";

const ToolTipShowOrNot = ({ content, BootstrapTooltip, item }) => {
  return item.submenu?.length ? (
    content
  ) : (
    <BootstrapTooltip title={item.name} TransitionComponent={Zoom}>
      {content}
    </BootstrapTooltip>
  );
};

export default ToolTipShowOrNot;
