import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import UserContext from "../../../../context/UserContext/UserContext";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import {
  DELETE_USER,
  Enable_Disable_User,
  Get_Details_User,
} from "../../../../Apis/userApi/UserApi";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import Actions from "../../../../layouts/Actions";

const UserDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    dialog,
    setdialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
    orgDetails,
  } = useContext(UserContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get user Details
   */
  const { mutate: userDetails, isLoading: isLoadingUserDetails } = useMutation(
    (userId) => Get_Details_User(userId, setFormData, setdialogs)
  );

  const GetUserDetails = (userId) => {
    userDetails(userId);
    setOrgId(userId);
    setIsEdit(false);
  };

  /**
   * delete user
   */
  const { mutate: userDelete, isLoading: isLoadingDelete } = useMutation(
    (userId) => DELETE_USER(userId, refetch)
  );

  const DeleteUser = async (userId) => {
    SweetConfirmation(userDelete, userId);
  };

  /**
   * enable / disable user
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_User(formData, refetch)
  );

  const handleUserStatusChange = (userId, status) => {
    let formDate = {
      userId: userId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.userId === userId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (params.row.email ? params.row.email : "--"),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "mobile",
      headerName: "Mobile",
      renderCell: (params) =>
        params.row.mobile ? params.row.dialCode + params.row.mobile : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleUserStatusChange(params.row.userId, params.row.isActive)
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Actions
              id={params.row.userId}
              getDetails={GetUserDetails}
              deleteDetails={DeleteUser}
              removeDelete={orgDetails?.userLimit > 1}
            />
          </>
        );
      },
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse isLoading={isLoadingUserDetails || isLoadingDelete} />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.userId}
        addButton={orgDetails?.userLimit > 1}
      />
    </Fragment>
  );
};

export default UserDataTabelContainer;
