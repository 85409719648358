import React, { useContext, useMemo } from "react";
import { get_User } from "../../../../Apis/userApi/UserApi";
import UserContext from "../../../../context/UserContext/UserContext";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import UserDataTabelContainer from "./UserDataTabelContainer";
import UserCreateFormPopup from "./UserCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  SLACE,
  USER,
  USER_LIST,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";

const User = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //user edit condition state and data object state
    formData,
    setdialogs,
    setErrors,
    dialog,
    setIsEdit,
    setFormData,
    setFilterDrop,
  } = useContext(UserContext);

  const history = useNavigate();

  /**
   * get user List
   */

  let paramsObj = useMemo(() => {
    let newParamObj = {
      pageNumber: page,
      recordsPerPage: pageSize,
      sortOrder,
      sortBy,
    };
    if (filter.length) {
      newParamObj["search"] = { name: filter };
    }
    return newParamObj;
  }, [page, pageSize, sortOrder, sortBy, filter]);

  let { data, refetch, isLoading, error } = useQuery(
    ["getUser", paramsObj],
    () => get_User(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const emptyData = () => {
    setFormData({
      name: null,
      mobile: null,
      dialCode: null,
      roleId: null,
      pageId: 1,
      orgId: 1,
      companyId: null,
      email: null,
      password: null,
      confirmPassword: null,
    });
    setFilterDrop((prev) => {
      return { ...prev, storeFilter: "" };
    });
  };

  /**
   * Open Popup and Close Popup User
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setdialogs(condition);
      emptyData();
      setErrors({});
    }
  };

  return (
    <>
      <Breadcrumb name={USER} slace={SLACE} name1={USER_LIST} />
      <UserDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      {dialog && (
        <UserCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
        />
      )}
    </>
  );
};

export default User;
