import React, { Fragment, useContext, useMemo, useState } from "react";
import { FormDataFeild, PasswordFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import {
  Create_User,
  Put_Update_User,
  Upload_Image,
} from "../../../../Apis/userApi/UserApi";
import UserContext from "../../../../context/UserContext/UserContext";
import { Dialog } from "primereact/dialog";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Email,
  Name,
  Phone,
  Submit,
  PasswordLabel,
  ConfirmPassword,
  TYPE_FELDS,
  TypeNumber,
  CreateUser,
  Roles,
  RoleDrop,
  DialCode,
  optionsDialCode,
  FileType,
  Company,
  UpdateUser,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import {
  createUserPasswordSchema,
  createUserSchema,
} from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import { get_Company } from "../../../../Apis/companyApi/CompanyApi";

const UserCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setdialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(UserContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);
  /**
   *
   * @param {event } e
   * create User & update User
   */

  const { mutate: createUser, isLoading: createUserLoader } = useMutation(
    (formData) => Create_User(formData),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const { mutate: updateUser, isLoading: updateUserLoader } = useMutation(
    (formData) => Put_Update_User(formData, orgId),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const handleUserCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = isEdit ? createUserSchema() : createUserPasswordSchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
          dialCode: formData?.dialCode?.code,
          roleId: formData?.roleId?.code,
          companyId: formData?.companyId?.code,
        };
        delete value.password;
        delete value.confirmPassword;
        updateUser(value);
      } else {
        let value = {
          ...formData,
          dialCode: formData?.dialCode?.code,
          roleId: formData?.roleId?.code,
          companyId: formData?.companyId?.code,
        };
        createUser(value);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.USER)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeOrgAdmin = (e) => {
    const { name, value } = e.target;
    const newFormValues = { ...formData, [name]: value };
    setFormData(newFormValues);
    let schema = isEdit ? createUserSchema() : createUserPasswordSchema();
    schema
      .validateAt(name, newFormValues)
      .then(() => {
        setErrors((currentErrors) => ({ ...currentErrors, [name]: "" }));
      })
      .catch((err) => {
        setErrors((currentErrors) => ({
          ...currentErrors,
          [name]: err.message,
        }));
      });
  };

  const search = (e) => {};

  let paramObj = useMemo(() => {
    return {
      showAll: true,
      search: JSON.stringify({ isActive: true }),
    };
  }, []);

  let { data: companyDrop } = useQuery(
    ["getCompanyDrop", paramObj],
    () => get_Company(paramObj),
    {
      keepPreviousData: true,
      retry: 3,
    }
  );

  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateUser : UpdateUser}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse isLoading={createUserLoader || updateUserLoader} />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleUserCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Name}
                        name="name"
                        id="name"
                        type={TYPE_FELDS.text}
                        placeholder={Name}
                        value={formData.name}
                        handleChange={handleChangeOrgAdmin}
                        isShowValidate={errors.name}
                      />
                      {errors.name && (
                        <span className="error-validation">{errors.name}</span>
                      )}
                    </Col>
                    <Col md="6 mb-4" className="d-flex gap-4 ">
                      <div>
                        <FormDataFeild
                          label={DialCode}
                          name="dialCode"
                          id="dialCode"
                          placeholder={DialCode}
                          value={formData.dialCode}
                          handleChange={handleChangeOrgAdmin}
                          dropdown={true}
                          option={optionsDialCode}
                          search={search}
                          isShowValidate={errors.dialCode}
                        />
                        {errors.dialCode && (
                          <span className="error-validation">
                            {errors.dialCode}
                          </span>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <FormDataFeild
                          label={Phone}
                          name="mobile"
                          id="mobile"
                          type={TYPE_FELDS.number}
                          placeholder={Phone}
                          value={formData.mobile}
                          handleChange={handleChangeOrgAdmin}
                          maxlangth={TypeNumber.PHONE}
                          isShowValidate={errors.mobile}
                        />
                        {errors.mobile && (
                          <span className="error-validation">
                            {errors.mobile}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={Email}
                        name="email"
                        id="email"
                        type={TYPE_FELDS.email}
                        placeholder={Email}
                        value={formData.email}
                        handleChange={handleChangeOrgAdmin}
                        isShowValidate={errors.email}
                      />
                      {errors.email && (
                        <span className="error-validation">{errors.email}</span>
                      )}
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={Company}
                        name="companyId"
                        id="companyId"
                        placeholder={Company}
                        value={formData.companyId}
                        handleChange={handleChangeOrgAdmin}
                        dropdown={true}
                        option={
                          companyDrop?.payload?.data?.length
                            ? companyDrop?.payload?.data.map((elem) => {
                                return {
                                  code: elem.companyId,
                                  name: elem.name,
                                };
                              })
                            : []
                        }
                        search={search}
                        isShowValidate={errors.companyId}
                      />
                      {errors.companyId && (
                        <span className="error-validation">
                          {errors.companyId}
                        </span>
                      )}
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={Roles}
                        name="roleId"
                        id="roleId"
                        placeholder={Roles}
                        value={formData.roleId}
                        handleChange={handleChangeOrgAdmin}
                        dropdown={true}
                        option={RoleDrop}
                        filter={false}
                        isShowValidate={errors.roleId}
                      />
                      {errors.roleId && (
                        <span className="error-validation">
                          {errors.roleId}
                        </span>
                      )}
                    </Col>
                  </Row>
                  {isEdit && (
                    <Row>
                      <Col md="6 mb-4">
                        <PasswordFeild
                          label={PasswordLabel}
                          name="password"
                          id="password"
                          placeholder={PasswordLabel}
                          value={formData.password}
                          handleChange={handleChangeOrgAdmin}
                          isShowValidate={errors.password}
                        />
                        {errors.password && (
                          <span className="error-validation">
                            {errors.password}
                          </span>
                        )}
                      </Col>
                      <Col md="6 mb-4">
                        <PasswordFeild
                          label={ConfirmPassword}
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder={ConfirmPassword}
                          value={formData.confirmPassword}
                          handleChange={handleChangeOrgAdmin}
                          isShowValidate={errors.confirmPassword}
                        />
                        {errors.confirmPassword && (
                          <span className="error-validation">
                            {errors.confirmPassword}
                          </span>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={createUserLoader || updateUserLoader}
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default UserCreateFormPopup;
