import React, { Fragment, useContext, useState } from "react";
import {
  FormDataFeild,
  FormDataFeildTextArea,
} from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { DELETE_IMAGE, Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Email,
  Phone,
  Submit,
  TYPE_FELDS,
  TypeNumber,
  DialCode,
  optionsDialCode,
  FileType,
  City,
  State,
  Country,
  Pin_Code,
  Address,
  TagLine,
  NOT_FOUND_IMAGE,
  CreateCompany,
  UpdateCompany,
  ComponyName,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createCompanySchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import {
  Create_Company,
  Put_Update_Company,
} from "../../../../Apis/companyApi/CompanyApi";
import CompanyContext from "../../../../context/CompanyContext/CompanyContext";
import { ColorPicker } from "primereact/colorpicker";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";

const CompanyCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setDialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(CompanyContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);

  /**
   *
   * @param {event } e
   * create Company & update Company
   */

  const { mutate: createCompany, isLoading: createCompanyLoader } = useMutation(
    (formData) => Create_Company(formData),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const { mutate: updateCompany, isLoading: updateCompanyLoader } = useMutation(
    (formData) => Put_Update_Company(formData, orgId),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setDialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const handleCompanyCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createCompanySchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
          dialCode1: formData?.dialCode1?.code,
          dialCode2: formData?.dialCode2?.code,
          roleId: formData?.roleId?.code,
          companyId: formData?.companyId?.code,
          logo: formData?.image
            ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
        };
        const { image, ...updatedFormData } = value;
        updateCompany(updatedFormData);
      } else {
        let value = {
          ...formData,
          dialCode1: formData?.dialCode1?.code,
          dialCode2: formData?.dialCode2?.code,
          roleId: formData?.roleId?.code,
          companyId: formData?.companyId?.code,
          logo: formData?.image
            ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : "",
        };
        const { image, ...updatedFormData } = value;
        createCompany(updatedFormData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage, isLoading: imageLoading } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.SYSTEM_IMAGE)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const search = (e) => {};

  /**
   * delete company image
   */
  const { mutate: postsDelete, isLoading: isLoadingDelete } = useMutation(
    (imgData) => DELETE_IMAGE(imgData, refetch)
  );

  const accept = (imgType) => {
    let imgData = {
      fileName: formData[`${imgType}`]
        .split(`${process.env.REACT_APP_FILE_FOLDER}`)
        .pop(),
    };
    setFormData((prev) => {
      return {
        ...prev,
        [`${imgType}`]: "",
      };
    });
    postsDelete(imgData);
  };

  const openImageUploadModal = async (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      setFormData((prev) => {
        return {
          ...prev,
          image: reader.result,
        };
      });
    };
    await uploadImage(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateCompany : UpdateCompany}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={
            createCompanyLoader ||
            updateCompanyLoader ||
            isLoadingDelete ||
            imageLoading
          }
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleCompanyCreateAndUpdate(e)}
                >
                  <Row>
                    <div className="field mb-4 col-12 md:col-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          marginBottom: "15px",
                        }}
                      >
                        <div
                          style={{
                            height: "150px",
                            width: "185px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              formData?.image ? formData.image : NOT_FOUND_IMAGE
                            }
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          // display: "flex",
                          // justifyContent: "center",
                          // flexWrap: "wrap",
                        }}
                      >
                        <p>{"Upload Business Logo"}</p>
                        {!formData.image && (
                          <input
                            className="custom-file-input"
                            type="file"
                            onChange={(e) => openImageUploadModal(e)}
                          />
                        )}

                        <Button
                          className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                          style={{ height: "40px" }}
                          type="button"
                          onClick={() => {
                            if (formData.image) {
                              SweetConfirmation(accept, "image");
                            }
                          }}
                        >
                          <span
                            className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                          ></span>
                          <span
                            className="p-button-label p-c"
                            style={{ fontWeight: "100" }}
                          >
                            {"Choose"}
                          </span>
                        </Button>
                      </div>
                    </div>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={ComponyName}
                        name="name"
                        id="name"
                        type={TYPE_FELDS.text}
                        placeholder={ComponyName}
                        value={formData.name}
                        handleChange={handleChangeCompany}
                      />
                      {errors.name && (
                        <span className="error-validation">{errors.name}</span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Email}
                        name="email"
                        id="email"
                        type={TYPE_FELDS.email}
                        placeholder={Email}
                        value={formData.email}
                        handleChange={handleChangeCompany}
                      />
                      {errors.email && (
                        <span className="error-validation">{errors.email}</span>
                      )}
                    </Col>
                    <Col md="12 mb-4">
                      <FormDataFeild
                        label={TagLine}
                        name="tagline"
                        id="tagline"
                        type={TYPE_FELDS.text}
                        placeholder={TagLine}
                        value={formData.tagline}
                        handleChange={handleChangeCompany}
                        isValid={false}
                      />
                    </Col>
                    <Col md="6 mb-4" className="d-flex gap-4 ">
                      <div>
                        <FormDataFeild
                          label={DialCode}
                          name="dialCode1"
                          id="dialCode1"
                          placeholder={DialCode}
                          value={formData.dialCode1}
                          handleChange={handleChangeCompany}
                          dropdown={true}
                          option={optionsDialCode}
                          search={search}
                        />
                        {errors.dialCode1 && (
                          <span className="error-validation">
                            {errors.dialCode1}
                          </span>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <FormDataFeild
                          label={`${Phone} 1`}
                          name="mobile1"
                          id="mobile1"
                          type={TYPE_FELDS.number}
                          placeholder={`${Phone} 1`}
                          value={formData.mobile1}
                          handleChange={handleChangeCompany}
                          maxlangth={TypeNumber.PHONE}
                        />
                        {errors.mobile1 && (
                          <span className="error-validation">
                            {errors.mobile1}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md="6 mb-4" className="d-flex gap-4 ">
                      <div>
                        <FormDataFeild
                          label={DialCode}
                          name="dialCode2"
                          id="dialCode2"
                          placeholder={DialCode}
                          value={formData.dialCode2}
                          handleChange={handleChangeCompany}
                          dropdown={true}
                          option={optionsDialCode}
                          search={search}
                          isValid={false}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <FormDataFeild
                          label={Phone + " " + 2}
                          name="mobile2"
                          id="mobile2"
                          type={TYPE_FELDS.number}
                          placeholder={Phone}
                          value={formData.mobile2}
                          handleChange={handleChangeCompany}
                          maxlangth={TypeNumber.PHONE}
                          isValid={false}
                        />
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Pin_Code}
                        name="pincode"
                        id="pincode"
                        type={TYPE_FELDS.number}
                        placeholder={Pin_Code}
                        value={formData.pincode}
                        handleChange={handleChangeCompany}
                      />
                      {errors.pincode && (
                        <span className="error-validation">
                          {errors.pincode}
                        </span>
                      )}
                    </Col>
                    <Col md="3 mb-4" className="flex align-items-end ">
                      <Col md="2">
                        <ColorPicker
                          name="themeColor"
                          value={formData.themeColor}
                          onChange={(e) => handleInputChange(e)}
                          format="hex"
                        />
                      </Col>
                      <Col md="10">
                        <FormDataFeild
                          label={`Theme Color`}
                          name="themeColor"
                          id="themeColor"
                          type={TYPE_FELDS.text}
                          placeholder={`Theme Color`}
                          value={formData.themeColor}
                          handleChange={handleInputChange}
                          maxlangth={6}
                          isValid={false}
                        />
                      </Col>
                    </Col>
                    <Col md="3 mb-4" className="flex align-items-end ">
                      <Col md="2">
                        <ColorPicker
                          name="fontColor"
                          value={formData.fontColor}
                          onChange={(e) => handleInputChange(e)}
                          format="hex"
                        />
                      </Col>
                      <Col md="10">
                        <FormDataFeild
                          label={`Font Color`}
                          name="fontColor"
                          id="fontColor"
                          type={TYPE_FELDS.text}
                          placeholder={`Font Color`}
                          value={formData.fontColor}
                          handleChange={handleInputChange}
                          maxlangth={6}
                          isValid={false}
                        />
                      </Col>
                    </Col>
                    <Col md="12 mb-4">
                      <FormDataFeildTextArea
                        label={Address}
                        name="address"
                        id="address"
                        type={TYPE_FELDS.text}
                        placeholder={Address}
                        value={formData.address}
                        handleChange={handleChangeCompany}
                      />
                      {errors.address && (
                        <span className="error-validation">
                          {errors.address}
                        </span>
                      )}
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={City}
                        name="city"
                        id="city"
                        type={TYPE_FELDS.text}
                        placeholder={City}
                        value={formData.city}
                        handleChange={handleChangeCompany}
                      />
                      {errors.city && (
                        <span className="error-validation">{errors.city}</span>
                      )}
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={State}
                        name="state"
                        id="state"
                        type={TYPE_FELDS.text}
                        placeholder={State}
                        value={formData.state}
                        handleChange={handleChangeCompany}
                      />
                      {errors.state && (
                        <span className="error-validation">{errors.state}</span>
                      )}
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={Country}
                        name="country"
                        id="country"
                        type={TYPE_FELDS.text}
                        placeholder={Country}
                        value={formData.country}
                        handleChange={handleChangeCompany}
                      />
                      {errors.country && (
                        <span className="error-validation">
                          {errors.country}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={createCompanyLoader || updateCompanyLoader}
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default CompanyCreateFormPopup;
