import axios from "axios";
import {
  CREATE_SELECTED_POST,
  DELETE_SELECTED_POST,
  EDIT_SELECTED_POST,
  ENABLE_DISABLE_SELECTED_POST,
  GET_SELECTED_POST,
  GET_SELECTED_POST_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get selected post list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const getSelectedPost = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_SELECTED_POST}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add selected post api call
 * @param {form data} formData
 * @returns
 */

export const createSelectedPost = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_SELECTED_POST}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable selected post api call
 * @param {form data as isActive & userSelectedPostId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const enableDisableSelectedPost = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_SELECTED_POST}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { userSelectedPostId: formData.userSelectedPostId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const getDetailsSelectedPost = (
  userSelectedPostId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_SELECTED_POST_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { userSelectedPostId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      setFormData({
        companyId:
          { code: data.companyId, name: data.companyDetail.name } ?? null,
        postId: { code: data.postId, name: data.postDetail.title } ?? null,
      });
      setdialogs(true);
    });
};

/**
 * update selected post api call
 * @param {form data} formData
 * @param {*} userSelectedPostId
 * @returns
 */

export const putUpdateSelectedPost = (formData, userSelectedPostId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_SELECTED_POST}`, formData, {
      headers: { Authorization: jwt_token },
      params: { userSelectedPostId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete selected post api call
 * @param {form data} formData
 * @param {*} userSelectedPostId
 * @param {list api call } refetch
 * @returns
 */

export const deleteSelectedPost = (userSelectedPostId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_SELECTED_POST}`, {
      headers: { Authorization: jwt_token },
      params: { userSelectedPostId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
