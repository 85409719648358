import React, { useContext, useMemo } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ThemeSettingCreateFormPopup from "./ThemeSettingCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  COMPANY,
  COMPANY_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import ThemeSettingDataTableContainer from "./ThemeSettingDataTableContainer";
import { getThemeSetting } from "../../../../Apis/themeSettingApi/ThemeSettingApi";
import ThemeSettingContext from "../../../../context/ThemeSettingContext/ThemeSettingContext";
import { THEME_SETTING_TYPE } from "../../../../constants";

const ThemeSetting = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //theme setting edit condition state and data object state
    setDialogs,
    setErrors,
    dialog,
    setIsEdit,
    setFormData,
    setFilterDrop,
  } = useContext(ThemeSettingContext);

  const history = useNavigate();

  /**
   * get theme setting List
   */

  let paramsObj = useMemo(() => {
    let paramObj = {
      pageNumber: page,
      recordsPerPage: pageSize,
      sortOrder,
      sortBy,
    };
    if (filter.length) {
      paramObj["search"] = { name: filter };
    }
    return paramObj;
  }, [page, pageSize, sortOrder, sortBy, filter]);

  let { data, refetch, isLoading, error } = useQuery(
    ["getThemeSetting", paramsObj],
    () => getThemeSetting(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    }
  );
  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const emptyData = () => {
    setFormData({
      address: null,
      companyId: null,
      companyName: null,
      designation: null,
      email: null,
      fontName: null,
      footerBgColor: null,
      footerFontColor: null,
      frameBorderColor: null,
      headerBgColor: null,
      headerFontColor: null,
      degreeOrSpecialty: null,
      footerDegreeOrSpecialty: null,
      footerDesignation: null,
      footerFontSize: null,
      footerImage: null,
      headerFontSize: null,
      mobileNumber: null,
      image: null,
      name: null,
      personName: null,
      shortDescription: null,
      webSite: null,
      type: THEME_SETTING_TYPE.HTML,
      frameImage: null,
      frameHeaderId: null,
      colorObj: [],
    });
    setFilterDrop((prev) => {
      return { ...prev, storeFilter: "" };
    });
  };

  /**
   * Open Popup and Close Popup theme setting
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setDialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setDialogs(condition);
      emptyData();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={COMPANY} slace={SLACE} name1={COMPANY_LIST} />
      <ThemeSettingDataTableContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      {dialog && (
        <ThemeSettingCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
          companyData={data.payload.data}
        />
      )}
    </>
  );
};

export default ThemeSetting;
