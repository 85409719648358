import axios from "axios";
import {
  CREATE_POST,
  DELETE_POST,
  DOWNLOAD_POST,
  EDIT_POST,
  ENABLE_DISABLE_POST,
  GET_POST,
  GET_POST_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { IMAGE_TYPE, POST_TYPE } from "../../constants";
import { get_Category } from "../categoryApi/categoryApi";

/**
 * get posts list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const get_Posts = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_POST}`, {
      headers: {
        Authorization: jwt_token,
      },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add posts api call
 * @param {form data} formData
 * @returns
 */

export const Create_Posts = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_POST}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable posts api call
 * @param {form data as isActive & postId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Posts = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_POST}`, formData, {
      headers: { Authorization: jwt_token },
      params: { postId: formData.postId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const Get_Details_Posts = (postId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_POST_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { postId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let paramObj = {
        showAll: true,
      };
      const { payload } = await get_Category(paramObj);

      let catIds = data?.postsCatMaps
        .map((ele) => {
          const matchedElement = payload?.data.find(
            (firstElem) => firstElem.catId === ele.catId
          );

          if (matchedElement) {
            return {
              code: matchedElement.catId,
              name: matchedElement.catName,
            };
          }
        })
        .filter(Boolean);

      setFormData({
        defaultImage: data?.defaultImage ?? null,
        description: data?.description ?? null,
        images: data?.image ?? null,
        isPremium: data?.isPremium ?? null,
        isSameDay: data?.isSameDay ?? null,
        languageId:
          { code: data?.languageId, name: data?.languageDetail?.name } ?? null,
        postDate: data?.postDate ?? null,
        postId: data?.postId ?? null,
        religionId:
          { code: data?.religionId, name: data?.religionDetail?.name } ?? null,
        templateImage: data?.templateImage ?? null,
        thumbImage: data?.thumbImage ?? null,
        title: data?.title ?? null,
        imageType: data?.imageType
          ? IMAGE_TYPE.map((elem, ind) => {
              return {
                code: elem.code,
                name: elem.name,
              };
            }).find((ele) => ele.code === +data?.imageType)
          : null,
        type: data?.type
          ? POST_TYPE.map((elem, ind) => {
              return {
                code: elem.code,
                name: elem.name,
              };
            }).find((ele) => ele.code === +data?.type)
          : null,
        categoryIds: catIds ?? [],
        xDescription: data?.xDescription ?? null,
      });
      setdialogs(true);
    });
};

/**
 * update posts api call
 * @param {form data} formData
 * @param {*} postId
 * @returns
 */

export const Put_Update_Posts = (formData, postId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_POST}`, formData, {
      headers: { Authorization: jwt_token },
      params: { postId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete posts api call
 * @param {form data} formData
 * @param {*} postId
 * @param {list api call } refetch
 * @returns
 */

export const DELETE_Posts = (postId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_POST}`, {
      headers: { Authorization: jwt_token },
      params: { postId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Get_Details__Posts = (paramObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_POST_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: paramObj,
    })
    .then(async (res) => {
      return res.data.payload.data;
    });
};

/**
 * download post
 * @param {form data} formData
 * @returns
 */
export const Download_Post = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios.post(`${process.env.REACT_APP_API_URL}${DOWNLOAD_POST}`, "", {
    headers: { Authorization: jwt_token },
    params: formData,
  });
};
