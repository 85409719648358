export const SLACE = "/";
export const GUEST_HOUSE = "Guest House";
export const GUEST_HOUSE_MASTER = "Guest House";
export const DASHBOARD = "Dashboard";
export const HOME = "Home";
export const E_COMMERCE = "E-commerce";
export const USER = "User";
export const SELECTED = "Selected Post";
export const ORG = "Organization";
export const ORG_LIST = "Organization List";
export const HAS_TAG = "Hastag";
export const HAS_TAG_LIST = "Hastag List";
export const RELIGION = "Religion";
export const RELIGION_LIST = "Religion List";
export const LANGUAGE = "Language";
export const LANGUAGE_LIST = "Language List";
export const COMPANY = "Company";
export const POST_BACKGROUND = "Post Background";
export const POST_BACKGROUND_LIST = "Post Background List";
export const FRAME_HEADER = "Frame Header";
export const FRAME_HEADER_LIST = "Frame Header List";
export const USER_LIST = "User List";
export const SELECTED_LIST = "Selected Post List";
export const COMPANY_LIST = "Company List";

/**
 * sidebar title and items name
 */

//title
export const DASHBOARD_TITLE = "DASHBOARD";
export const USER_TITLE = "User";
export const ORG_TITLE = "Organization";
export const HAS_TAG_TITLE = "Hastag";
export const RELIGION_TITLE = "Religion";
export const LANGUAGE_TITLE = "Language";
export const COMPANY_TITLE = "Company";
export const POSTS_TITLE = "Posts";
export const CATEGORY_TITLE = "Category";
export const FRAME_HEADER_TITLE = "Frame Header";
export const THEME_SETTING_TITLE = "Theme Setting";

//items
export const DASHBOARD_ITEM = "Dashboard";
export const USER_ITEM = "User List";
export const ORG_ITEM = "Organization List";
export const HAS_TAG_ITEM = "Hastag List";
export const COMPANY_ITEM = "Company List";
export const RELIGION_ITEM = "Religion List";
export const LANGUAGE_ITEM = "Language List";
export const POSTS_ITEM = "Posts List";
export const SELECTED_ITEM = "Selected Posts List";
export const POST_BACKGROUND_ITEM = "Post Background List";
export const CATEGORY_ITEM = "Category List";
export const FRAME_HEADER_ITEM = "Frame Header List";
export const THEME_SETTING_ITEM = "Theme Setting List";
