import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import {
  deleteOrganization,
  enableDisableOrganization,
  getDetailsOrganization,
} from "../../../../Apis/orgApi/OrgApi";
import OrgContext from "../../../../context/OrgContext/OrgContext";
import Actions from "../../../../layouts/Actions";

const OrganizationDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(OrgContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get Organization Details
   */
  const {
    mutate: organizationDetails,
    isLoading: isLoadingOrganizationDetails,
  } = useMutation((orgId) =>
    getDetailsOrganization(orgId, setFormData, setdialogs)
  );

  const GetOrgDetails = (orgId) => {
    organizationDetails(orgId);
    setOrgId(orgId);
    setIsEdit(false);
  };

  /**
   * delete Organization
   */
  const { mutate: organizationDelete, isLoading: isLoadingDelete } =
    useMutation((orgId) => deleteOrganization(orgId, refetch));

  const DeleteOrg = async (orgId) => {
    SweetConfirmation(organizationDelete, orgId);
  };

  /**
   * enable / disable Organization
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    enableDisableOrganization(formData, refetch)
  );

  const handleOrganizationStatusChange = (orgId, status) => {
    let formDate = {
      orgId: orgId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.orgId === orgId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "orgName",
      headerName: "Org Name",
      renderCell: (params) => (params.row.orgName ? params.row.orgName : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "companyLimit",
      headerName: "Company Limit",
      renderCell: (params) =>
        params.row.companyLimit ? params.row.companyLimit : "--",
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "userLimit",
      headerName: "User Limit",
      renderCell: (params) =>
        params.row.userLimit ? params.row.userLimit : "--",
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "isPremium",
      headerName: "Premium",
      renderCell: (params) =>
        params.row.isPremium ? (
          <span style={{ color: "green", fontWeight: "600" }}>Active</span>
        ) : (
          <span style={{ color: "red", fontWeight: "600" }}>In Active</span>
        ),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "expiredAt",
      headerName: "Expire Date",
      renderCell: (params) =>
        params.row.expiredAt
          ? moment(params.row.expiredAt).format("DD MMM, YYYY")
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleOrganizationStatusChange(
                params.row.orgId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <Actions
            id={params.row.orgId}
            getDetails={GetOrgDetails}
            deleteDetails={DeleteOrg}
          />
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingOrganizationDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.orgId}
        addButton={true}
      />
    </Fragment>
  );
};

export default OrganizationDataTabelContainer;
