import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import UserSelectedPostContext from "../../../../context/UserSelectedPostContext/UserSelectedPostContext";
import {
  deleteSelectedPost,
  enableDisableSelectedPost,
  getDetailsSelectedPost,
} from "../../../../Apis/userSelectedPostApi/userSelectedPostApi";
import Actions from "../../../../layouts/Actions";

const UserSelectedPostDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(UserSelectedPostContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get selected post Details
   */
  const {
    mutate: selectedPostDetails,
    isLoading: isLoadingSelectedPostDetails,
  } = useMutation((userSelectedPostId) =>
    getDetailsSelectedPost(userSelectedPostId, setFormData, setdialogs)
  );

  const GetSelectedPostDetails = (userSelectedPostId) => {
    selectedPostDetails(userSelectedPostId);
    setOrgId(userSelectedPostId);
    setIsEdit(false);
  };

  /**
   * delete selected post
   */
  const { mutate: selectedPostDelete, isLoading: isLoadingDelete } =
    useMutation((userSelectedPostId) =>
      deleteSelectedPost(userSelectedPostId, refetch)
    );

  const DeleteSelectedPost = async (userSelectedPostId) => {
    SweetConfirmation(selectedPostDelete, userSelectedPostId);
  };

  /**
   * enable / disable selected post
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    enableDisableSelectedPost(formData, refetch)
  );

  const handleSelectedPostStatusChange = (userSelectedPostId, status) => {
    let formDate = {
      userSelectedPostId: userSelectedPostId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.userSelectedPostId === userSelectedPostId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Company Name",
      renderCell: (params) =>
        params.row.companyDetail ? params.row.companyDetail?.name : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "title",
      headerName: "Post Title",
      renderCell: (params) =>
        params.row.postDetail ? params.row.postDetail?.title : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleSelectedPostStatusChange(
                params.row.userSelectedPostId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <Actions
            id={params.row.userSelectedPostId}
            getDetails={GetSelectedPostDetails}
            deleteDetails={DeleteSelectedPost}
          />
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingSelectedPostDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.userSelectedPostId}
        addButton={true}
      />
    </Fragment>
  );
};

export default UserSelectedPostDataTabelContainer;
