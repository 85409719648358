export const SERVICES = {
  E_COM_PUBLIC: "/byte-auto-post-maker/public",
  E_COM_ADMIN: "/byte-auto-post-maker",
  E_COM: "/byte-auto-post-maker",
};

export const V1 = "/api/v1";

/**Auth And Public*/
export const LOGIN = `${SERVICES.E_COM_PUBLIC}${V1}/login`;
export const FILE = `${SERVICES.E_COM_ADMIN}${V1}/file`;

/**Common End Points */
export const CRETE = "/create";
export const ENABLE_DISABLE = "/enableDisable";
export const DETAILS = "/detail";
export const CHECK_SLUG = "/checkSlugUrl";

/**End points */
export const DASHBOARD = "/dashboard";
export const USER = "/user";
export const COMPANY = "/company";
export const ORG = "/organization";
export const HASH_TAG = "/hashTag";
export const RELIGION = "/religionMaster";
export const LANGUAGE = "/languageMaster";
export const POSTS = "/posts";
export const CATEGORY = "/categoryMaster";
export const SELECTED_POST = "/selectedPost";
export const POST_BACKGROUND = "/posterBackgroundMaster";
export const FRAME_HEADER = "/frameHeader";
export const THEME_SETTING = "/themeSetting";
export const DOWNLOAD = "/download";

/**Dashboard */

export const GET_ADMIN_DASHBOARD = `${SERVICES.E_COM_ADMIN}${V1}${DASHBOARD}`;

/**User */

export const GET_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;
export const CREATE_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;
export const ENABLE_DISABLE_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}${ENABLE_DISABLE}`;
export const GET_ADMIN_USER_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${USER}${DETAILS}`;
export const EDIT_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;
export const DELETE_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;

/**Company */

export const GET_COMPANY = `${SERVICES.E_COM_ADMIN}${V1}${COMPANY}`;
export const CREATE_COMPANY = `${SERVICES.E_COM_ADMIN}${V1}${COMPANY}`;
export const ENABLE_DISABLE_COMPANY = `${SERVICES.E_COM_ADMIN}${V1}${COMPANY}${ENABLE_DISABLE}`;
export const GET_COMPANY_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${COMPANY}${DETAILS}`;
export const EDIT_COMPANY = `${SERVICES.E_COM_ADMIN}${V1}${COMPANY}`;
export const DELETE_COMPANY = `${SERVICES.E_COM_ADMIN}${V1}${COMPANY}`;

/**Organization */

export const GET_ORG = `${SERVICES.E_COM_ADMIN}${V1}${ORG}`;
export const CREATE_ORG = `${SERVICES.E_COM_ADMIN}${V1}${ORG}`;
export const ENABLE_DISABLE_ORG = `${SERVICES.E_COM_ADMIN}${V1}${ORG}${ENABLE_DISABLE}`;
export const GET_ORG_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${ORG}${DETAILS}`;
export const EDIT_ORG = `${SERVICES.E_COM_ADMIN}${V1}${ORG}`;
export const DELETE_ORG = `${SERVICES.E_COM_ADMIN}${V1}${ORG}`;

/**Hash Tag */

export const GET_HASH_TAG = `${SERVICES.E_COM_ADMIN}${V1}${HASH_TAG}`;
export const CREATE_HASH_TAG = `${SERVICES.E_COM_ADMIN}${V1}${HASH_TAG}`;
export const ENABLE_DISABLE_HASH_TAG = `${SERVICES.E_COM_ADMIN}${V1}${HASH_TAG}${ENABLE_DISABLE}`;
export const GET_HASH_TAG_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${HASH_TAG}${DETAILS}`;
export const EDIT_HASH_TAG = `${SERVICES.E_COM_ADMIN}${V1}${HASH_TAG}`;
export const DELETE_HASH_TAG = `${SERVICES.E_COM_ADMIN}${V1}${HASH_TAG}`;

/**Religion Master */

export const GET_RELIGION = `${SERVICES.E_COM_ADMIN}${V1}${RELIGION}`;
export const CREATE_RELIGION = `${SERVICES.E_COM_ADMIN}${V1}${RELIGION}`;
export const ENABLE_DISABLE_RELIGION = `${SERVICES.E_COM_ADMIN}${V1}${RELIGION}${ENABLE_DISABLE}`;
export const GET_RELIGION_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${RELIGION}${DETAILS}`;
export const EDIT_RELIGION = `${SERVICES.E_COM_ADMIN}${V1}${RELIGION}`;
export const DELETE_RELIGION = `${SERVICES.E_COM_ADMIN}${V1}${RELIGION}`;

/**Language Master */

export const GET_LANGUAGE = `${SERVICES.E_COM_ADMIN}${V1}${LANGUAGE}`;
export const CREATE_LANGUAGE = `${SERVICES.E_COM_ADMIN}${V1}${LANGUAGE}`;
export const ENABLE_DISABLE_LANGUAGE = `${SERVICES.E_COM_ADMIN}${V1}${LANGUAGE}${ENABLE_DISABLE}`;
export const GET_LANGUAGE_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${LANGUAGE}${DETAILS}`;
export const EDIT_LANGUAGE = `${SERVICES.E_COM_ADMIN}${V1}${LANGUAGE}`;
export const DELETE_LANGUAGE = `${SERVICES.E_COM_ADMIN}${V1}${LANGUAGE}`;

/** Posts */

export const GET_POST = `${SERVICES.E_COM_ADMIN}${V1}${POSTS}`;
export const CREATE_POST = `${SERVICES.E_COM_ADMIN}${V1}${POSTS}`;
export const ENABLE_DISABLE_POST = `${SERVICES.E_COM_ADMIN}${V1}${POSTS}${ENABLE_DISABLE}`;
export const GET_POST_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${POSTS}${DETAILS}`;
export const EDIT_POST = `${SERVICES.E_COM_ADMIN}${V1}${POSTS}`;
export const DELETE_POST = `${SERVICES.E_COM_ADMIN}${V1}${POSTS}`;
export const DOWNLOAD_POST = `${SERVICES.E_COM_ADMIN}${V1}${POSTS}${DOWNLOAD}`;

/** category */

export const GET_CATEGORY = `${SERVICES.E_COM_ADMIN}${V1}${CATEGORY}`;
export const CREATE_CATEGORY = `${SERVICES.E_COM_ADMIN}${V1}${CATEGORY}`;
export const ENABLE_DISABLE_CATEGORY = `${SERVICES.E_COM_ADMIN}${V1}${CATEGORY}${ENABLE_DISABLE}`;
export const GET_CATEGORY_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${CATEGORY}${DETAILS}`;
export const EDIT_CATEGORY = `${SERVICES.E_COM_ADMIN}${V1}${CATEGORY}`;
export const DELETE_CATEGORY = `${SERVICES.E_COM_ADMIN}${V1}${CATEGORY}`;

/**User selected post */

export const GET_SELECTED_POST = `${SERVICES.E_COM_ADMIN}${V1}${USER}${SELECTED_POST}`;
export const CREATE_SELECTED_POST = `${SERVICES.E_COM_ADMIN}${V1}${USER}${SELECTED_POST}`;
export const ENABLE_DISABLE_SELECTED_POST = `${SERVICES.E_COM_ADMIN}${V1}${USER}${SELECTED_POST}${ENABLE_DISABLE}`;
export const GET_SELECTED_POST_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${USER}${SELECTED_POST}${DETAILS}`;
export const EDIT_SELECTED_POST = `${SERVICES.E_COM_ADMIN}${V1}${USER}${SELECTED_POST}`;
export const DELETE_SELECTED_POST = `${SERVICES.E_COM_ADMIN}${V1}${USER}${SELECTED_POST}`;

/** category */

export const GET_POST_BACKGROUND = `${SERVICES.E_COM_ADMIN}${V1}${POST_BACKGROUND}`;
export const CREATE_POST_BACKGROUND = `${SERVICES.E_COM_ADMIN}${V1}${POST_BACKGROUND}`;
export const ENABLE_DISABLE_POST_BACKGROUND = `${SERVICES.E_COM_ADMIN}${V1}${POST_BACKGROUND}${ENABLE_DISABLE}`;
export const GET_POST_BACKGROUND_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${POST_BACKGROUND}${DETAILS}`;
export const EDIT_POST_BACKGROUND = `${SERVICES.E_COM_ADMIN}${V1}${POST_BACKGROUND}`;
export const DELETE_POST_BACKGROUND = `${SERVICES.E_COM_ADMIN}${V1}${POST_BACKGROUND}`;

/** frame header */

export const GET_FRAME_HEADER = `${SERVICES.E_COM_ADMIN}${V1}${FRAME_HEADER}`;
export const CREATE_FRAME_HEADER = `${SERVICES.E_COM_ADMIN}${V1}${FRAME_HEADER}`;
export const ENABLE_DISABLE_FRAME_HEADER = `${SERVICES.E_COM_ADMIN}${V1}${FRAME_HEADER}${ENABLE_DISABLE}`;
export const GET_FRAME_HEADER_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${FRAME_HEADER}${DETAILS}`;
export const EDIT_FRAME_HEADER = `${SERVICES.E_COM_ADMIN}${V1}${FRAME_HEADER}`;
export const DELETE_FRAME_HEADER = `${SERVICES.E_COM_ADMIN}${V1}${FRAME_HEADER}`;

/**theme setting */

export const GET_THEME_SETTING = `${SERVICES.E_COM_ADMIN}${V1}${THEME_SETTING}`;
export const CREATE_THEME_SETTING = `${SERVICES.E_COM_ADMIN}${V1}${THEME_SETTING}`;
export const ENABLE_DISABLE_THEME_SETTING = `${SERVICES.E_COM_ADMIN}${V1}${THEME_SETTING}${ENABLE_DISABLE}`;
export const GET_THEME_SETTING_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${THEME_SETTING}${DETAILS}`;
export const EDIT_THEME_SETTING = `${SERVICES.E_COM_ADMIN}${V1}${THEME_SETTING}`;
export const DELETE_THEME_SETTING = `${SERVICES.E_COM_ADMIN}${V1}${THEME_SETTING}`;
