import React, { Fragment, useContext, useMemo, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { Upload_Image } from "../../../../Apis/userApi/UserApi";
import { Dialog } from "primereact/dialog";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Submit,
  TYPE_FELDS,
  FileType,
  Company,
  CreateSelectedPost,
  UpdateSelectedPost,
  Post,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createSelectedPostSchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import { get_Company } from "../../../../Apis/companyApi/CompanyApi";
import UserSelectedPostContext from "../../../../context/UserSelectedPostContext/UserSelectedPostContext";
import {
  createSelectedPost,
  putUpdateSelectedPost,
} from "../../../../Apis/userSelectedPostApi/userSelectedPostApi";
import { get_Posts } from "../../../../Apis/postsApi/postsApi";

const UserSelectedPostCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setdialogs,
    formData,
    dialog,
    errors,
    setErrors,
    filterDrop,
    setFilterDrop,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(UserSelectedPostContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);

  /**
   *
   * @param {event } e
   * create selected post & update selected post
   */

  const { mutate: createSelectedPost_, isLoading: createSelectedPostLoader } =
    useMutation((formData) => createSelectedPost(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const { mutate: updateSelectedPost, isLoading: updateSelectedPostLoader } =
    useMutation((formData) => putUpdateSelectedPost(formData, orgId), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const handleSelectedPostCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createSelectedPostSchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
          postId: formData?.postId?.code,
          companyId: formData?.companyId?.code,
        };
        updateSelectedPost(value);
      } else {
        let value = {
          ...formData,
          postId: formData?.postId?.code,
          companyId: formData?.companyId?.code,
        };
        createSelectedPost_(value);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.USER)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeSelectedPost = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const search = (e) => {
    setFilterDrop((prev) => {
      return {
        ...prev,
        companyFilter: e.target.value,
      };
    });
  };

  let paramObj = useMemo(() => {
    return {
      showAll: true,
      search: JSON.stringify({
        name: filterDrop?.companyFilter,
        isActive: true,
      }),
    };
  }, [filterDrop?.companyFilter]);

  let { data: companyDrop } = useQuery(
    ["getCompanyDrop", paramObj],
    () => get_Company(paramObj),
    {
      keepPreviousData: true,
      retry: 3,
    }
  );

  const searchPost = (e) => {
    setFilterDrop((prev) => {
      return {
        ...prev,
        postFilter: e.target.value,
      };
    });
  };
  let paramObjPost = useMemo(() => {
    return {
      showAll: true,
      search: JSON.stringify({ title: filterDrop?.postFilter, isActive: true }),
    };
  }, [filterDrop?.postFilter]);
  let { data: postDrop } = useQuery(
    ["getPostsDrop", paramObjPost],
    () => get_Posts(paramObjPost),
    {
      keepPreviousData: true,
      retry: 3,
    }
  );

  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateSelectedPost : UpdateSelectedPost}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse
          isLoading={createSelectedPostLoader || updateSelectedPostLoader}
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleSelectedPostCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Company}
                        name="companyId"
                        id="companyId"
                        placeholder={Company}
                        value={formData.companyId}
                        handleChange={handleChangeSelectedPost}
                        dropdown={true}
                        option={
                          companyDrop?.payload?.data?.length
                            ? companyDrop?.payload?.data.map((elem) => {
                                return {
                                  code: elem.companyId,
                                  name: elem.name,
                                };
                              })
                            : []
                        }
                        search={search}
                        showClear={true}
                        isShowValidate={errors.companyId}
                      />
                      {errors.companyId && (
                        <span className="error-validation">
                          {errors.companyId}
                        </span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Post}
                        name="postId"
                        id="postId"
                        placeholder={Post}
                        value={formData.postId}
                        handleChange={handleChangeSelectedPost}
                        dropdown={true}
                        option={
                          postDrop?.payload?.data?.length
                            ? postDrop?.payload?.data.map((elem) => {
                                return {
                                  code: elem.postId,
                                  name: elem.title,
                                };
                              })
                            : []
                        }
                        showClear={true}
                        search={searchPost}
                        isShowValidate={errors.postId}
                      />
                      {errors.postId && (
                        <span className="error-validation">
                          {errors.postId}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={
                          createSelectedPostLoader || updateSelectedPostLoader
                        }
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default UserSelectedPostCreateFormPopup;
